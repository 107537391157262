import React, {Component} from 'react'
import {Modal, Input} from 'antd'
import './index.scss'
import giftBtn from '../../image/giftBtn.png'
import giftBG from '../../image/giftBG.png'
import {Toast} from 'antd-mobile'

class giftDialog extends Component {
  state = {
    name: '',
    tel: '',
    telShow: false
  }
  saveInfo() {
    let {name, tel} = this.state
    let obj = {
      name: name,
      tel: tel
    }
    if (tel === '') {
      Toast.info('手机号码不能为空~', 1)
    } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(tel)) {
      Toast.info('手机号码格式不正确~', 1)
    } else {
      this.props.saveInfo(obj)
      this.setState({
        name: '',
        tel: ''
      })
    }
  }

  inputChange(type, e) {
    if (type === 1) {
      this.setState({
        name: e.target.value
      })
    }
    if (type === 2) {
      this.setState({
        tel: e.target.value
      })
    }
  }

  RenderInput() {
    let changeArea = null
    changeArea = (
      <div
        style={{
          width: '8.533333rem',
          textAlign: 'center'
        }}
        className='gift_input_Box'
      >
        <p>
          <Input
            ref={(myInput) => (this.myInput = myInput)}
            className='gift_input'
            placeholder='您的姓名'
            value={this.state.name}
            onChange={this.inputChange.bind(this, 1)}
            type='text'
          />
        </p>
        <p>
          <Input
            ref={(myInput) => (this.myInput = myInput)}
            className='gift_input'
            placeholder='手机号码'
            value={this.state.tel}
            onChange={this.inputChange.bind(this, 2)}
            type='number'
          />
        </p>
        <img
          className='gift_Bottom_btn'
          src={giftBtn}
          onClick={this.saveInfo.bind(this)}
          alt=''
        />
      </div>
    )
    return changeArea
  }

  
  render() {
    const {visible, cancelClick, content} = this.props
    return (
      <div className='gift_container'>
        <Modal
          centered
          visible={visible}
          footer={null}
          onCancel={cancelClick}
          closable={false}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '.48rem'
            }}
          >
            <span>{content}</span>
          </div>
          <p className='gift_close' onClick={cancelClick}>
            X
          </p>
          <div
            className='design_test-title'
            style={{
              display: 'flex',
              left: '50%',
              transform: 'translateX(-50%)',
              position: 'fixed',
              height: '12.973333rem',
              width: '8.253333rem',
              backgroundSize: '100% 100%',
              background: `url('${giftBG}') center center /cover`
            }}
          >
            {this.RenderInput()}
          </div>
        </Modal>
      </div>
    )
  }
}

export default giftDialog
