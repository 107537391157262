import React, {Component} from 'react'
import MyNavBar from '../../../components/myNavBar/index'
import './index.scss'

export default class Install_innings extends Component {
    state = {
        data: []
    }

    componentWillMount() {
        let arr = this.props.location.pathname.split('/')
        this.get(`/api/websiteProduce/getList?categoryId=${arr[arr.length - 1]}`).then((res) => {
            if (res.code === 0) {
                this.setState({
                    data: res.data
                })
            }
        })
    }

    //跳转到产品详情页
    goProduceInfo(id) {
        window.location.href = window.location.origin + '/kuaiwu/' + id
    }

    render() {

        let changeArea = (
            <div className='Install_innings'>
                <div className='Install_innings_header'>
                    <p className='Install_innings_title'>{localStorage.getItem('alabels') || '局装局改'}</p>
                </div>
                <div className='Install_innings_content'>
                    <ul className='Install_innings_list'>
                        {this.state.data &&
                        this.state.data.map((item) => {
                            return (
                                <li className='Install_innings_item' key={item.id}
                                    onClick={this.goProduceInfo.bind(this, item.id)}>
                                    <img className='innings_bg' src={item.coverUrl} alt=''/>
                                    <div className='innings_info'>
                                        <span className='innings_title'>{item.coverTitle}</span>
                                        <span className='innings_desc'>{item.coverSubtitle}</span>
                                        <span
                                            className='innings_detail'
                                        >
                        查看详情 >{' '}
                      </span>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
        return (
            <div className='container'>
                <MyNavBar/>
                {changeArea}
            </div>
        )
    }
}
