import React, { Component } from 'react'
import '../../../css/chooseBuildingMaterials.css'
import MyNavBar from '../../../components/myNavBar/index'
import choose0 from '../../../image/choose0.png'
import choose1 from '../../../image/choose1.png'
import choose2 from '../../../image/choose2.png'
import choose3 from '../../../image/choose3.png'
import phone from '../../../image/phone.png'
import bg_699 from '../../../image/bg_699.png'
import bg_299 from '../../../image/bg_299.png'
import Nature from '../../../image/Nature.png'
import Nobel from '../../../image/Nobel.png'
import Nobel1 from '../../../image/Nobel1.png'
import Nobel2 from '../../../image/Nobel2.png'
import {GetQuery, GetTel, GetAddr, locationHref} from "../../../utils/index"; //路由配置
import { websiteBusinessfindAll } from '../../../request/index'

export default class chooseBuildingMaterials extends Component {
  state={
    List:[],
    List2:[],
    List1:[],
  }
  componentDidMount() {
    this.getwebsiteBusinessfindAll()
    //装修保障tdk内容
    var meta = document.getElementsByTagName('meta')
    document.title = '装修保障_装修施工质量保障_装修材料质量保障-居然装饰'
    addMate('a', 'title', '装修保障_装修施工质量保障_装修材料质量保障-居然装饰')
    addMate(
        'b',
        'keywords',
        '装修保障,装修施工质量保障'
    )
    addMate(
        'c',
        'description',
        '居然装饰装修保障，让装修无后顾之后，装修保障让家装和家居服务快乐简单；装修质量保证措施工程质量管理最终反映工程设计效果和工程质量等级的重要环节，选居然装饰，有保障。'
    )

    function addMate(id, name, content) {
        let metaData = document.getElementsByTagName('head')[0].children
        for (let i = 0; i < metaData.length; i++) {
            if (metaData[i].localName === 'meta' && metaData[i].id === id) {
                const mate = document.getElementById(id)
                document.getElementsByTagName('head')[0].removeChild(mate)
            }
        }
        let meta = document.createElement('meta')
        meta.content = content
        meta.name = name
        meta.id = id
        document.getElementsByTagName('head')[0].appendChild(meta)
    }
}
  getwebsiteBusinessfindAll() {
    websiteBusinessfindAll().then((res) => {
      this.setState({
        List: res.data,
        List1: res.data.filter((i,index)=>index<12),
        List2: res.data.filter((i, index) => index > 11 && index <24),
      })
    })
  }
    //底部栏
  StoreFooter() {
    return (
      <div className='StoreFooter'>
        <div className='consulting'>
          <span>装修咨询</span>
          <a href={`tel:${GetTel()}`} className='phoneNum'>{GetTel()}</a>
          <img className='phone_icon' src={phone} alt='' />
        </div>
        <div className='address'>
          总部地址：{GetAddr()}
        </div>
      </div>
    )
  }
  
   //跳转到产品详情页
   goProduceInfo(id) {
       locationHref('/kuaiwu/'+id)
    }

    render() {
        return (
            <div className="container">
                <MyNavBar />
                <div className="container_title_">
                {/* <img className='container_title_' src={choose1} alt='' /> */}
                <img className='container_title_' src={choose0} alt='' />
                <img className='container_title_' src={choose1} alt='' />
                {/* <img className='container_title_' src={choose2} alt='' /> */}
                <div className='boxs'>
                  {
                  this.state.List1.map(i => <img className='container_title_1' src={i.imgUrl} alt='' />)
                  }
                </div>
                <div className='titles'>
                  <h1 style={{textAlign:'center'}}>一线主材</h1>
                <h3 style={{ textAlign: 'center' }}>每一处细节都是用心甄选</h3>
                </div>
                {/* <img className='container_title_' src={choose3} alt='' /> */}
              <div className='boxs'>
                  {
                  this.state.List2.map(i => <img className='container_title_1' src={i.imgUrl} alt={i.seotitle}/>)
                  }
                </div>
                <img className='container_title_n' src={Nobel} alt=''  style={{marginTop:'0.4rem'}}/>
                <img className='container_title_n' src={Nature} alt='' />
                <img className='container_title_n' src={Nobel1} alt='' />
                <img className='container_title_n' src={Nobel2} alt='' />
                </div>
                
                <div className="container_content_like">猜你喜欢</div>
                <div className="container_content_like_img"  onClick={this.goProduceInfo.bind(this, 8)}> <img className='container_content_like_img' src={bg_699} alt='' /></div>
                <div className="container_content_like_img1"  onClick={this.goProduceInfo.bind(this, 13)}> <img className='container_content_like_img1' src={bg_299} alt='' /></div>
                <div className="StoreFooter">
                        {this.StoreFooter()}
                </div>
            </div>
        )
    }
}
