import React,{ Component }  from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { get, post } from './request/http';
import api from './request/api';
import "lib-flexible"
// import '../public/53kf-sdk'
import { Provider } from 'react-redux'
import store from './store/index'

Component.prototype.get = get;
Component.prototype.post = post;
Component.prototype.api = api;


ReactDOM.render(
	// <React.StrictMode>
	<Provider store={store}>
		<App />,
		</Provider>,
	// </React.StrictMode>,
	document.getElementById("root")
);
