import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import MyNavBar from '../../../components/myNavBar/index'
import liulian from '../../../image/liulan.png'
import icon_more from '../../../image/icon_more.png'
import '../../../iconfont/iconfont.css'
import './index.scss'
import bannerMiddle from '../../../image/banner_middle.png'
import DesignerPopup from '../../../components/designerPopup/index'
import { Toast } from 'antd-mobile'
import share from '../../../image/share.png'
import copy from "copy-to-clipboard";
import {GetQuery, locationHref} from "../../../utils/index"; //路由配置

class decorateDetail extends Component {
  state = {
    websiteDetailInfo: [],
    flag: true,
    websiteDatas: [],
    priceVisible: false,
    labelsList:null
  }
  componentDidMount() {
    let ids = this.props.match.params.id
    this.getwebsiteDetailInfo(ids)
    this.addCount(ids)
  }
  componentWillReceiveProps(newProps) {
    const ids = newProps.match.params.id;
    // this.getwebsiteDetailInfo(ids)
    window.onbeforeunload = function () {
      document.documentElement.scrollTop = 0;  //ie下
      document.body.scrollTop = 0;  //非ie
    }
  }
  // 装修问答增加查阅次数接口
  addCount(ids) {
    this.get(`${this.api.addCount}?id=${ids}`, '').then((res) => {
      if (res.code === 0) {
      }
    })
  }
  goLongTailWord(id) {
    locationHref('/longTailWords/'+id)
  }
  priceSaveInfo(obj) {
    let source = '获取报价'
    let sourceUrl = escape(document.location.href);//'/decorateGan/decorateDetail'
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          priceVisible: false
        })
        Toast.success('已提交信息', 1)
      }
    })
  }

  websiteData() {
    this.get(
      `${this.api.websiteData}?typeId=${1}&labels=${this.state.websiteDetailInfo.labels}&page=${1}&limit=${3}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
            websiteDatas: res.data
          }
        )
      }
    })
  }
  getwebsiteDetailInfo = (id) => {
    this.get(`${this.api.getwebsiteDetailInfo}?id=${id}`, '').then((res) => {
      this.setState({
          websiteDetailInfo: res.data
        }, () => {
          this.websiteData()
           //干货列表seo优化内容
          document.title = this.state.websiteDetailInfo.seotitle
          if (this.state.websiteDetailInfo.keyword) {
            document.getElementsByName('keywords')[0].setAttribute('content', this.state.websiteDetailInfo.keyword);
          } 
          if (this.state.websiteDetailInfo.describes) {
            document.getElementsByName('description')[0].setAttribute('content', this.state.websiteDetailInfo.describes);
          }
        }
      )
    })
  }
  iconclick = (type) => {
    this.setState({
      flag: type
    })
  }

  share() {
    if (copy(document.location.href)) {
      Toast.success('已成功复制到剪贴板', 1)
    } else {
      alert('复制失败')
    }
  }

  goDecorateDetails(id) {
    locationHref('/decorateGan/decorateDetail/' + id)
  }
  rendersList(data) {
    if (data !== undefined) {
      return data&&data.map((item) => {
        return (
          <div
            className='newsLists'
            onClick={this.goDecorateDetails.bind(this, item.id)}
            // key={item.id}
          >
            <div className='newsList_tops'>
              <div className='news_info'>
                <p className='news_title'>{item.title}</p>
                <div className="CaseList_infosI">
                  <div className="infos">{item.intro}</div>
                  <img className='imgUrl' src={item.imgUrl} alt='' />
                </div>
              </div>
            </div>
            <div className='CaseList_info'>
              <span className='CaseList_house'>{item.time}</span>
              <div className='whoSeenIt'>
                <span className='peopleNum'>
                  <img className='liulian' src={liulian} alt='' />
                  {item.counts}
                </span>
              </div>
            </div>
          </div>
        )
      })
    }
  }
  render() {
    let { websiteDetailInfo, priceVisible } = this.state
    let labesList = this.state.websiteDetailInfo.labelsList;
    return (
      <>
        <MyNavBar />
        <div className='decorateDetail' style={{ marginnTop: '-1.2rem' }}>
          <div className='decorateDetailBanner'>
            <div
              className='questionTops'
              onClick={() => {
                this.setState({ priceVisible: true })
              }}
            >
              <img className='bannerTop_img' src={bannerMiddle} alt='' />
              <span className='preciseSolution'>已为1302234人提供精准方案</span>
            </div>
          </div>
          {/* {this.state.flag ? 'btn btn-choose':'btn'} */}
          <div className='decorateDetailContent'>
            {/*  <div className='TitleImg'>
              <img className='TitleImg' src={websiteDetailInfo.imgUrl} alt='' />
            </div>*/}
            <div className='Detailtitle'>{websiteDetailInfo.title}</div>
            <div className='DetailTimes'>
              <div className='DetailTime'>
                发布时间：{websiteDetailInfo.time}
              </div>
              <div className='DetailLiulan'>
                <img className='liulian' src={liulian} alt='' />
                {websiteDetailInfo.counts}
              </div>
              <img
                onClick={this.share.bind(this)}
                src={share}
                className='shareDetail'
                alt=''
              />
            </div>
            <div
              className='DetailContentd'
              dangerouslySetInnerHTML={{ __html: websiteDetailInfo.infos }}
            ></div>
            <div className="DetailContentSeoTitle">
              {
                labesList && labesList.map((item, index) => {
                  return (
                    <div id="seoTitle" key={index} onClick={this.goLongTailWord.bind(this, item.id)}>
                      {item.words}
                    </div>
                  )
                })
              }
            </div>
            <div className='dianzan'>
              <div
                className={this.state.flag ? 'icon_dz' : 'icon_dzz'}
                onClick={() => this.iconclick(false)}
              >
                <div className='dianzan_'>
                  <span className='iconfont icon-chaping'></span>
                  <span>对我没用</span>
                </div>
              </div>
              <div
                className={this.state.flag ? 'icon_dzz' : 'icon_dz'}
                onClick={() => this.iconclick(true)}
              >
                <div className='dianzan_'>
                  <div className='iconfont icon-dianzan'></div>
                  <span>对我有用</span>
                </div>
              </div>
            </div>
            <div className="Detail_all">
              <div className="all_l">大家都在看</div>
              <a className="all_r" href='/decorateGan/index'>
                <p>查看更多</p>
                <img
                  src={icon_more}
                  alt=''
                />
              </a>
            </div>
            <div className='DetailTj'>
              {this.rendersList(this.state.websiteDatas)}
            </div>
          </div>
          <DesignerPopup
            visible={priceVisible}
            title='装修要花多少钱?'
            subTitle='1688'
            cancelClick={() => this.setState({ priceVisible: false })}
            saveInfo={this.priceSaveInfo.bind(this)}
            btnTxt={['获取报价']}
            type='price'
            price={98234}
          />
        </div>
      </>
    )
  }
}
export default withRouter(decorateDetail)
