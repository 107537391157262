import React, { Component } from 'react'
import MyNavBar from '../../../components/myNavBar'
import './index.scss'
import { Toast } from 'antd-mobile'
import seenEye from '../../../image/seenEye.png'
import { webSiteAdvAdmin } from '../../../request/index'
import { locationHref } from "../../../utils/index";

export default class ActivitiesList extends Component {
  state = {
    activitiesList: []
  }
  componentDidMount() {
    this.getWebSiteActivity()
  }
  getWebSiteActivity() {
    webSiteAdvAdmin().then((res) => {
      if (res.data.data && res.data.data.length > 0) {
        this.setState({ activitiesList: res.data.data })
      } else {
        Toast.info('暂无活动', 1)
      }
    })
  }
  goDetail(id) {
    locationHref('/webSiteActivity/' + id)
  }

  componentWillUnmount() {
    this.setState({ activitiesList: [] })
  }

  renderList() {
    let { activitiesList } = this.state
    if (activitiesList && activitiesList.length !== 0) {
      return (
        <div className='actListWrap'>
          {activitiesList.map((item) => {
            return (
              <div
                style={{ height: '5.8rem' }}
                className='actList_item'
                key={item.id}
                onClick={this.goDetail.bind(this, item.id)}
              >
                <img className='item_img' src={item.imgUrl} alt='' />
                <hgroup className='item_title'>
                  <span className='item_name'>活动主题：{item.name}</span>
                  <span className='item_times'>{item.times}</span>
                </hgroup>
                <div className='item_info'>
                  <span className='item_house'>
                    {item.describes}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  }

  render() {
    return (
      <div className='container'>
        <div className='actList'>
          {this.renderList()}
        </div>
      </div>
    )
  }
}
