import React, {Component} from 'react'
import DesignerPopup from '../../../components/designerPopup/index'
import {Carousel, WingBlank} from 'antd-mobile'
import './index.scss'
import MyNavBar from '../../../components/myNavBar/index'
import {
  vip,
  six,
  logo,
  cases,
  fucai,
  banner,
  rating,
  seemore,
  material,
  waterway,
  service1,
  service2,
  service3,
  service4,
  service5,
  service6,
  Learnmore,
  systemone,
  systemtwo,
  keydesign,
  selection,
  lifeStyle1,
  lifeStyle2,
  lifeStyle3,
  lifeStyle4,
  lifeStyle5,
  otherbrand,
  designHome,
  Bookingroom,
  systemthree,
  Eengineering,
  SuperiorDesign,
  completionBond,
  UnlockingDesign,
  materialproducts,
  SuperiorDesignBig,
  subscribeDesigner,
  AuxiliaryMaterials,
  Visitconstructionsite,
  MaterialselectionTitle
} from '../../../image/lw/index'
import heikuang from '../../../image/heikuang.png'
import sanjiao from '../../../image/sanjiao.png'
import {GetQuery, locationHref} from "../../../utils/index";

import {Toast} from 'antd-mobile'

export default class LwuPersonalized extends Component {
  state = {
    data: ['1', '2', '3'],
    visible: false,
    gzVisible: false,
    name: '',
    area: '',
    tel: '',
    address: '',
    caseListData: [],
    notice: '',
    materialType: 1
  }

  goStyleTest() {
    locationHref('/styleTesting')
  }
  componentWillMount() {}
  componentDidMount() {
    this.get(`${this.api.getDesignerCaseList}?page=${1}&limit=${10}&${GetQuery()}&isRecommend=0`, '').then(
      (res) => {
        if (res.data.code === 0) {
          this.setState({
            caseListData: res.data.data
          })
        }
      }
    )
  }

  saveInfo(obj) {
    let source = '了解更多施工详情'
    let sourceUrl = escape(document.location.href);//'page/LwuPersonalized'
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          visible: false
        })
        Toast.success('已提交信息', 1)
      }
    })
  }

  inputChange(type, e) {
    if (type === 'name') {
      this.setState({
        name: e.target.value
      })
    } else if (type === 'area') {
      this.setState({
        area: e.target.value
      })
    } else if (type === 'address') {
      this.setState({
        address: e.target.value
      })
    } else {
      this.setState({
        tel: e.target.value
      })
    }
  }

  gzSaveInfo(obj) {
    let source = '我要参观附近工地'
    let sourceUrl = escape(document.location.href);//'page/LwuPersonalized'
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          gzVisible: false
        })
        Toast.success('已提交信息', 1)
      }
    })
  }
  keySaveInfo() {
    let source = '一键定制我家全案设计'
    let sourceUrl = escape(document.location.href);//'page/LwuPersonalized'
    let {name, tel, area} = this.state
    if (tel === '') {
      Toast.info('未填写手机号码呀~', 1)
    } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(tel)) {
      Toast.info('手机号码格式不正确~', 1)
    } else {
      this.post(
        `${this.api.getCustomerCollect}?username=${name}&phone=${tel}&housingArea=${area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
        ''
      ).then((res) => {
        if ((res.msg = '保存成功')) {
          this.setState({
            name: '',
            tel: '',
            area: ''
          })
          Toast.success('已提交信息', 1)
        }
      })
    }
  }
  goCaseDetail(id) {
    locationHref('/detailsCase/' + id)
  }

  open53() {
    // this.setState({
    var _53 = window.$53.createApi()
    _53.push('cmd', 'mtalk')
    // _53.push('type', 'popup');
    _53.query()
    // })
  }

  roomSaveInfo() {
    let source = '立即预约360°环保量房'
    let sourceUrl = escape(document.location.href);//'page/LwuPersonalized'
    let {name, tel, area, address, notice} = this.state
    if (tel === '') {
      Toast.info('未填写手机号码呀~', 1)
    } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(tel)) {
      Toast.info('手机号码格式不正确~', 1)
    } else if (name === '') {
      Toast.info('请填写您的姓名呦~', 1)
    } else {
      this.post(
        `${this.api.getCustomerCollect}?username=${name}&phone=${tel}&housingArea=${area}&address=${address}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
        ''
      ).then((res) => {
        if ((res.msg = '保存成功')) {
          this.setState({
            name: '',
            tel: '',
            area: '',
            address: ''
          })
          Toast.success('已提交信息', 1)
          let telnumber = tel
          telnumber = '' + telnumber
          var tel1 = telnumber.substr(0, 3) + '****' + telnumber.substr(7)
          this.setState({
            notice: `恭喜  ${name}  ${tel1}预约成功`
          })
        }
      })
    }
  }

  construction() {
    return (
      <WingBlank>
        <Carousel
          autoplay={true}
          infinite
          dotStyle={{width: '4px', height: '4px', background: '#666666'}}
        >
          {this.state.data.map((val) => (
            <img
              className='waterway'
              key={val}
              style={{width: '100%', verticalAlign: 'top'}}
              src={waterway}
            />
          ))}
        </Carousel>
      </WingBlank>
    )
  }

  RenderCase() {
    let {caseListData} = this.state
    if (caseListData !== undefined) {
      return (
        <WingBlank>
          <Carousel
            autoplay={true}
            infinite
            dots={false}
            style={{height: '218px'}}
          >
            {caseListData.map((item) => (
              <div
                className='caseBox'
                style={{height: '218px'}}
                key={item.id}
                onClick={this.goCaseDetail.bind(this, item.id)}
              >
                <img className='casebg' src={item.cover} alt='' />
                <div className='caseInfo'>
                  <div className='simpleInfo'>
                    <div className='infoLeft'>
                      <span className='designidentity'>设计师</span>
                      <span className='designname'>{item.name}</span>
                    </div>
                    <img className='headportrait' src={item.icon} alt='' />
                  </div>
                  <div className='designdesc'>
                    <span>{item.title}</span>
                    <span>本案户型：{item.styleName}</span>
                    <span>
                      户型面积：{item.acreage}m<sup>2</sup>
                    </span>
                    <span>设计风格：现代轻奢</span>
                    <div className='rating'>
                      <span>客户评分：</span>
                      <img src={rating} className='ratingimg' alt='' />
                    </div>
                  </div>
                  <img className='seemore' src={seemore} alt='' />
                </div>
              </div>
            ))}
          </Carousel>
        </WingBlank>
      )
    }
  }

  render() {
    let {
      visible,
      gzVisible,
      name,
      tel,
      area,
      address,
      notice,
      materialType
    } = this.state
    let changeArea
    changeArea = (
      <div className='container'>
        <div className='container_title1'>
          <img className='container_title1' src={banner} alt='' />{' '}
        </div>
        <img className='SuperiorDesign' src={SuperiorDesign} alt='' />
        <img className='SuperiorDesignBig' src={SuperiorDesignBig} alt='' />
        <img className='designHome' src={designHome} alt='' />
        {/*一键定制*/}
        <div className='designInput'>
          <div className='designInput_top'>
            <input
              className='inputName'
              placeholder='您的姓名：'
              type='text'
              onChange={this.inputChange.bind(this, 'name')}
              value={name}
            />
            <input
              className='inputArea'
              placeholder='您的户型面积：'
              type='number'
              onChange={this.inputChange.bind(this, 'area')}
              value={area}
            />
          </div>
          <span className='square'>
            m<sup>2</sup>
          </span>
          <input
            className='inputPhone'
            placeholder='您的联系方式：'
            type='number'
            onChange={this.inputChange.bind(this, 'tel')}
            value={tel}
          />
        </div>
        <img
          className='keydesign'
          src={keydesign}
          alt=''
          onClick={this.keySaveInfo.bind(this)}
        />
        <img className='UnlockingDesign' src={UnlockingDesign} alt='' />
        {/*生活方式*/}
        <div className='lifeStyle'>
          <img className='lifeStyleimg' src={lifeStyle1} alt='' />
          <img className='lifeStyleimg' src={lifeStyle2} alt='' />
          <img
            className='lifeStyleimg'
            src={lifeStyle3}
            alt=''
            onClick={this.goStyleTest.bind(this)}
          />
          <img className='lifeStyleimg' src={lifeStyle4} alt='' />
          <img className='lifeStyle5' src={lifeStyle5} alt='' />
        </div>

        {/*立即预约我的设计师*/}
        <img
          className='subscribeDesigner'
          src={subscribeDesigner}
          alt=''
          onClick={() => this.setState({visible: true})}
        />
        <img
          className='MaterialselectionTitle'
          src={MaterialselectionTitle}
          alt=''
        />

        <img className='selection' src={selection} alt='' />
        <img className='logo' src={logo} alt='' />
        <div className='MaterialScience'>
          <div
            className='materialBox'
            onClick={() => {
              this.setState({materialType: 1})
            }}
          >
            <img className='material' src={materialType == 1 ? sanjiao : heikuang} alt='' />
            <span>优秀大牌-主材</span>
          </div>
          <div
          className='materialBox'
            onClick={() => {
              this.setState({materialType: 2})
            }}
          >
            <img className='material' src={materialType == 2 ? sanjiao : heikuang} alt='' />

            <span>E0级环保-辅材</span>
          </div>
        </div>
        <img
          className='materialproducts'
          src={materialType == 1 ? materialproducts : fucai}
          alt=''
        />
        <img
          className='otherbrand'
          src={otherbrand}
          alt=''
          onClick={() => this.open53()}
        />
        <img className='Eengineering' src={Eengineering} alt='' />
        <div className='system'>
          <img className='systemimg' src={systemone} alt='' />
          <img className='systemimg' src={systemtwo} alt='' />
          <img className='systemimg' src={systemthree} alt='' />
        </div>
        {/*轮播图*/}
        <div className='construction'>{this.construction()}</div>

        <img
          className='Learnmore'
          src={Learnmore}
          alt=''
          onClick={() => this.setState({visible: true})}
        />
        <img className='six' src={six} alt='' />
        <img className='completionBond' src={completionBond} alt='' />
        <img
          className='Visitconstructionsite'
          src={Visitconstructionsite}
          onClick={() => this.setState({gzVisible: true})}
        />
        <img className='vip' src={vip} alt='' />
        <div className='service'>
          <img className='serviceimg' src={service1} alt='' />
          <img className='serviceimg' src={service2} alt='' />
          <img className='serviceimg' src={service3} alt='' />
          <img className='serviceimg' src={service4} alt='' />
          <img className='serviceimg' src={service5} alt='' />
          <img className='serviceimg' src={service6} alt='' />
        </div>
        {/*案例*/}
        <img className='cases' src={cases} alt='' />
        {this.RenderCase()}
        {/*预约量房*/}
        <div className='Bookingroom'>
          <p className='BookingroomTitle'>装修第一步,从360°量房开始</p>
          <p className='appointmentsNumber'>
            -已有<span className='people'>1288</span>人成功预约-
          </p>
          <div className='inputGroup'>
            <input
              type='text'
              placeholder='您的姓名：'
              onChange={this.inputChange.bind(this, 'name')}
              value={name}
            />
            <input
              type='number'
              placeholder='您的房屋面积：'
              onChange={this.inputChange.bind(this, 'area')}
              value={area}
            />
            <input
              type='text'
              placeholder='您的小区名称：'
              onChange={this.inputChange.bind(this, 'address')}
              value={address}
            />
            <input
              type='number'
              placeholder='您的联系方式：'
              onChange={this.inputChange.bind(this, 'tel')}
              value={tel}
            />
          </div>
          <img
            src={Bookingroom}
            className='BookingroomImg'
            alt=''
            onClick={this.roomSaveInfo.bind(this)}
          />
          <p className='notice'>{notice}</p>
        </div>
        <div className='copyrightWrap'>
          <p>
            北京居然数装科技有限公司版权所有 &copy;2000-2021 All Rights
            Reserved. 京ICP备140084
          </p>
        </div>
        <DesignerPopup
          visible={visible}
          title='预约TA'
          type={'ta'}

          subTitle='在线预约 尊享优先排期特权'
          bottomdata={['上门量房', '户型解析', '动线设计', '软装设计']}
          cancelClick={() => this.setState({visible: false})}
          btnTxt={['立即预约']}
          saveInfo={this.saveInfo.bind(this)}
        />
        <DesignerPopup
          visible={gzVisible}
          title='预约工长看工地'
          subTitle=''
          bottomdata={['上门量房', '户型解析', '动线设计', '软装设计']}
          cancelClick={() => this.setState({gzVisible: false})}
          btnTxt={['立即预约']}
          saveInfo={this.gzSaveInfo.bind(this)}
        />
      </div>
    )
    return (
      <div>
        <MyNavBar />
        {changeArea}
      </div>
    )
  }
}
