import React, { Component } from 'react'
import MyNavBar from '../../../components/myNavBar'
import bannerMiddle from '../../../image/banner_middle.png'
import MyStep from '../../../components/myStep'
import './index.scss'
import dizhi from '../../../image/dizhi.png'
import openArrow from '../../../image/openArrow.png'
import closeArrow from '../../../image/closeArrow.png'
import { Toast } from "antd-mobile";
import DesignerPopup from "../../../components/designerPopup/index";
import {GetQuery, GetQueryObj, locationHref} from "../../../utils/index";


export default class SiteList extends Component {
  state = {
    priceVisible: false,
    cityData: [],
    areaData: [
      {
        id: 1,
        min: 0,
        max: 60
      },
      {
        id: 2,
        min: 60,
        max: 80
      },
      {
        id: 3,
        min: 80,
        max: 120
      },
      {
        id: 4,
        min: 120,
        max: 200
      },
      {
        id: 5,
        min: 200,
        max: 400
      },
      {
        id: 6,
        min: 400,
        max: 0
      }
    ],
    cityFilterShow: false,
    areaFilterShow: false,
    progressFilterShow: false,
    cityLabel: '全部区域',
    areaLabel: '面积',
    progressLabel: '进度',
    WorkSiteListData: [],
    filterWorkSiteListData: [],
    workStatusData: [],
    search: {
      countiesId: '',
      status: '',
      floorageStart: '',
      floorageEnd: '',
    },
    page: 1,
    len:10
  }

  componentDidMount() {
    this.getWorkSiteList()
    this.getCitySelect()
    this.getWorkStatusList()
    //线下体验tdk内容
    var meta = document.getElementsByTagName('meta')
    document.title = '装修样板房_家居装修样板房_房屋装修样板间线下体验-居然装饰'
    addMate('a', 'title', '装修样板房_家居装修样板房_房屋装修样板间线下体验-居然装饰')
    addMate(
      'b',
      'keywords',
      '装修样板房,房子装修样板,家居装修样板房,房屋装修样板间,室内装修样板房'
    )
    addMate(
      'c',
      'description',
      '居然装饰线下体验店-居然家居装修样板房，房屋装修样板间，各种主材，辅材，房屋装修效果以及各种装修风格，提供给业主真实感受。'
    )

    function addMate(id, name, content) {
      let metaData = document.getElementsByTagName('head')[0].children
      for (let i = 0; i < metaData.length; i++) {
        if (metaData[i].localName === 'meta' && metaData[i].id === id) {
          const mate = document.getElementById(id)
          document.getElementsByTagName('head')[0].removeChild(mate)
        }
      }
      let meta = document.createElement('meta')
      meta.content = content
      meta.name = name
      meta.id = id
      document.getElementsByTagName('head')[0].appendChild(meta)
    }
  }
  loadPage = () => {
    this.setState({
      page: this.state.page + 1
    }, () => {
      this.getWorkSiteList()
    })
  }
  getWorkSiteList() {
    const { search, page, filterWorkSiteListData } = this.state

    let str = ''
    for (const i in search) {
      if (search[i]) {
        str = str + `&${i}=${search[i]}`
      }
    }
    this.get(`${this.api.getWorkSiteList}?page=${page}&limit=${10}&${str}&${GetQuery()}`, '').then(
      (res) => {
        if (res.code === 0) {
          this.setState({
            len: res.data.data.length,
            WorkSiteListData: page === 1 ? res.data.data : filterWorkSiteListData.concat(res.data.data),
            filterWorkSiteListData: page === 1 ? res.data.data : filterWorkSiteListData.concat(res.data.data)
          })
        }
      }
    )
  }
  getWorkStatusList() {
    this.get(`${this.api.getWorkStatusList}`, '').then((res) => {
      if (res.code === 0) {
        this.setState({
          workStatusData: res.data
        })
      }
    })
  }

  goDetail(id) {
    locationHref('/detailsConstructionSite/' + id)
  }

  getCitySelect() {
    this.get(`${this.api.getCitySelect}?id=${GetQueryObj().cityId}`, '').then((res) => {
      if (res.code === 0) {
        this.setState({
          cityData: res.data
        })
      }
    })
  }
  priceSaveInfo(obj) {
    let source = '获取报价'
    let sourceUrl = escape(document.location.href);//'/decorateGan/decorateDetail'
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          priceVisible: false
        })
        Toast.success('已提交信息', 1)
      }
    })
  }
  openFilter(type) {
    let { cityFilterShow, areaFilterShow, progressFilterShow } = this.state
    if (type === 1) {
      if (cityFilterShow === true) {
        this.setState({
          cityFilterShow: false
        })
      } else {
        this.setState({
          cityFilterShow: true,
          areaFilterShow: false,
          progressFilterShow: false
        })
      }
    } else if (type === 2) {
      if (areaFilterShow === true) {
        this.setState({
          areaFilterShow: false
        })
      } else {
        this.setState({
          areaFilterShow: true,
          cityFilterShow: false,
          progressFilterShow: false
        })
      }
    } else if (type === 3) {
      if (progressFilterShow === true) {
        this.setState({
          progressFilterShow: false
        })
      } else {
        this.setState({
          progressFilterShow: true,
          areaFilterShow: false,
          cityFilterShow: false
        })
      }
    }
  }

  filterValue(label, type) {
    this.openFilter(type)
    const { search } = this.state
    if (type === 1) {
      this.setState({
        cityLabel: label
      })

      if (label === '全部区域') {
        this.getWorkSiteList()
        search['countiesId'] = ''
      } else {
        this.state.cityData.filter(i => {
          if (i.name == label) {
            search['countiesId'] = i.id
          }
        })

        // let list = []
        // this.state.WorkSiteListData.filter((item) => {
        //   if (label === item.countiesName) {
        //     list.push(item)
        //   }
        // })
        // this.setState(
        //   {
        //     filterWorkSiteListData: list
        //   },
        //   function () {
        //   }
        // )
      }
    } else if (type === 2) {
      let _this = this
      if (label === '不限') {
        this.getWorkSiteList()
        this.setState({
          areaLabel: `不限`
        })
        search['floorageStart'] = ''
        search['floorageEnd'] = ''

      } else {
        search['floorageStart'] = label.min > 0 ? label.min : 1
        search['floorageEnd'] = label.max > 0 ? label.max:10000000
        let list = []
        this.state.WorkSiteListData.filter((item) => {
          if (label.min === 0 && item.acreage < label.max) {
            list.push(item)
            _this.setState({
              areaLabel: `${label.max}m²以下`
            })
          } else if (label.max === 0 && item.acreage > label.min) {
            list.push(item)
            _this.setState({
              areaLabel: `${label.min}m²以上`
            })
          } else if (item.acreage < label.max && item.acreage > label.min) {
            list.push(item)
            _this.setState({
              areaLabel: `${label.min}~${label.max}m²`
            })
          } else {
            if (label.min === 0) {
              _this.setState({
                areaLabel: `${label.max}m²以下`
              })
            } else if (label.max === 0) {
              _this.setState({
                areaLabel: `${label.min}m²以上`
              })
            } else {
              _this.setState({
                areaLabel: `${label.min}~${label.max}m²`
              })
            }
          }
        })

      }
    } else {
      this.setState({
        progressLabel: label.name
      })

      if (label === '不限') {
        search['status'] = ''

        this.setState({
          progressLabel: '不限'
        })
        this.getWorkSiteList()
      } else {
        search['status'] = label.id

        // let list = []
        // this.state.WorkSiteListData.filter((item) => {
        //   if (label.id === item.status) {
        //     list.push(item)
        //   }
        // })
        // this.setState(
        //   {
        //     filterWorkSiteListData: list
        //   },
        //   function () {
        //   }
        // )
      }
    }
    this.setState({
        search: search
    }, () => this.getWorkSiteList())
  }

  RenderAreaLabel(item) {
    let changeArea
    if (item.min === 0) {
      changeArea = (
        <span className='filterWrap_list_label'>{item.max}m²以下</span>
      )
    } else if (item.max === 0) {
      changeArea = (
        <span className='filterWrap_list_label'>{item.min}m²以上</span>
      )
    } else {
      changeArea = (
        <span className='filterWrap_list_label'>
          {item.min}~{item.max}m²
        </span>
      )
    }
    return changeArea
  }

  RenderFilter() {
    let {
      cityFilterShow,
      areaFilterShow,
      progressFilterShow,
      cityData,
      areaData,
      workStatusData,
      cityLabel,
      areaLabel,
      progressLabel
    } = this.state
    return (
      <div className='filterWrap'>
        <ul className='filter_header'>
          <li
            className='filterWrap_title'
            onClick={this.openFilter.bind(this, 1)}
          >
            <span
              className='filterWrap_name'
              style={{
                color: cityFilterShow === true ? '#005BAB' : '#000000',
                fontWeight: cityFilterShow === true ? '600' : '400'
              }}
            >
              {cityLabel}
            </span>
            <img
              className='downArrowIcon'
              src={cityFilterShow === true ? openArrow : closeArrow}
              alt=''
            />
          </li>
          <li
            className='filterWrap_title'
            onClick={this.openFilter.bind(this, 2)}
          >
            <span
              className='filterWrap_name'
              style={{
                color: areaFilterShow === true ? '#005BAB' : '#000000',
                fontWeight: areaFilterShow === true ? '600' : '400'
              }}
            >
              {areaLabel}
            </span>
            <img
              className='downArrowIcon'
              src={areaFilterShow === true ? openArrow : closeArrow}
              alt=''
            />
          </li>

          <li
            className='filterWrap_title'
            onClick={this.openFilter.bind(this, 3)}
          >
            <span
              className='filterWrap_name'
              style={{
                color: progressFilterShow === true ? '#005BAB' : '#000000',
                fontWeight: progressFilterShow === true ? '600' : '400'
              }}
            >
              {progressLabel}
            </span>
            <img
              className='downArrowIcon'
              src={progressFilterShow === true ? openArrow : closeArrow}
              alt=''
            />
          </li>
        </ul>
        <ul
          className='filterWrap_list'
          style={{
            display:
              cityFilterShow === false &&
                areaFilterShow === false &&
                progressFilterShow === false
                ? 'none'
                : 'block',
          }}
        >
          <li
            className='filterWrap_list_wrap'
            style={{ opacity: cityFilterShow === true ? '1' : '0', width: '33%', marginLeft: '2%' }}
          >
            <span
              className='default_item'
              onClick={this.filterValue.bind(this, '全部区域', 1)}
            >
              全部区域
            </span>
            {cityData.map((item) => {
              return (
                <div
                  className='filterWrap_list_item'
                  key={item.id}
                  onClick={this.filterValue.bind(this, item.name, 1)}
                >
                  <span className='filterWrap_list_label'>{item.name}</span>
                </div>
              )
            })}
          </li>
          <li
            className='filterWrap_list_wrap'
            style={{ opacity: areaFilterShow === true ? '1' : '0', width: '33%', marginLeft: '9%' }}
          >
            <span
              className='default_item'
              onClick={this.filterValue.bind(this, '不限', 2)}
            >
              不限
            </span>
            {areaData.map((item) => {
              return (
                <div
                  className='filterWrap_list_item'
                  key={item.id}
                  onClick={this.filterValue.bind(this, item, 2)}
                >
                  {this.RenderAreaLabel(item)}
                </div>
              )
            })}
          </li>
          <li
            className='filterWrap_list_wrap'
            style={{
              opacity: progressFilterShow === true ? '1' : '0',
              width: '33%',
              marginLeft: '8%'
            }}
          >
            <span
              className='default_item'
              onClick={this.filterValue.bind(this, '不限', 3)}
            >
              不限
            </span>
            {workStatusData.map((item) => {
              return (
                <div
                  className='filterWrap_list_item'
                  key={item.id}
                  onClick={this.filterValue.bind(this, item, 3)}
                >
                  <span className='filterWrap_list_label'>{item.name}</span>
                </div>
              )
            })}
          </li>
        </ul>
      </div>
    )
  }

  RenderSiteList() {
    let { filterWorkSiteListData, workStatusData } = this.state
    if (filterWorkSiteListData !== undefined && workStatusData != undefined) {
      return (
        <div className='siteListWrap'>
          {filterWorkSiteListData.map((item) => {
            return (
              <div
                className='siteListItem'
                key={item.id}
                onClick={this.goDetail.bind(this, item.id)}
              >
                <img className='site_img' src={item.websiteCover} alt='' />
                <div className='siteListInfo'>
                  <div className='siteListHeader'>
                    <span className='site_name'>{item.websiteTitle}</span>
                    <div className='siteAddress'>
                      <img className='addressIcon' src={dizhi} alt='' />
                      <span className='site_address'>
                        {item.provinceName}·{item.countiesName}
                      </span>
                    </div>
                  </div>
                  <p className='site_houseInfo'>
                    {item.floorage}m²&ensp;|&ensp;{item.htName}&ensp;|&ensp;{item.styleName}
                  </p>
                  <p className='site_designer'>
                    设计师：{item.sjs} &nbsp;&nbsp;&nbsp;&nbsp; 施工队：{item.gz}
                  </p>
                </div>
                <div className='siteList_progress'>
                  <MyStep step={item.status} stepData={workStatusData} />
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  }

  render() {
    let changeArea = (
      <div className='SiteList'>
        <div className='SiteList_banner'>
          <div className='bannerTop'
            onClick={() => {
              this.setState({ priceVisible: true })
            }}>
            <img className='bannerTop_img' src={bannerMiddle} alt='' />
            <span className='preciseSolution'>已为1302234人提供精准方案</span>
          </div>
          {/* <img className='SiteList_bannerImg' src={zs899} alt='' /> */}
          {this.RenderFilter()}

        </div>
        {this.RenderSiteList()}
      </div>
    )
    return (
      <div className='container' style={{ paddingTop: 0 }}>
        <MyNavBar />
        {changeArea}
        {
          this.state.len === 10 ? <div className='loadMore' onClick={this.loadPage}>点击加载更多</div> :
            <div className='loadMore'>无更多内容</div>
        }
        <DesignerPopup
          visible={this.state.priceVisible}
          title='装修要花多少钱?'
          subTitle='1688'
          cancelClick={() => this.setState({ priceVisible: false })}
          saveInfo={this.priceSaveInfo.bind(this)}
          btnTxt={['获取报价']}
          type='price'
          price={98234}
        />
      </div>
    )
  }
}
