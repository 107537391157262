import React, { Component } from 'react'
import { jq } from '../../image/commentsDetail/index'
import { GetQuery } from "../../utils/index"; //路由配置

import './tab.css'
export default class tab1 extends Component {
    state={
        ownersPennantList:[],
		defaultCurrent:1,
        len:10,
		defaultPageSize:10,
    }
    componentDidMount(){
        this.getData()
	}
    getData(){
        this.get(`${this.api.ownersPennant}?page=${this.state.defaultCurrent}&limit=${this.state.defaultPageSize}&${GetQuery()}`, '').then(
            (res) => {
                this.setState({
                    ownersPennantList: this.state.defaultCurrent === 1 ? res.data : this.state.ownersPennantList.concat(res.data),
                    len: res.data.length,
                    ownersPennantListCount: res.data.count,
                }, () => {
                })
            }
        )
    }
    loadPage = () => {
        this.setState({
            defaultCurrent: this.state.defaultCurrent + 1
        }, () => {
            this.getData()
        })
    }
    render() {
        let {ownersPennantList}=this.state
        return (
                <div className="content">
                     {
                            ownersPennantList.map((item, index) => {
                                return (
                                    <div className="content_left">
                                        <div><img className='jq' src={item.img} alt='' /></div>
                                        <div>{item.title}</div>
                                    </div>
                                )
                            })
                        }
                {
                    this.state.len === 10 ? <div className='loadMore' onClick={this.loadPage}>点击加载更多</div> :
                        <div className='loadMore'>无更多内容</div>
                }
                </div>
        )
    } w
}
