import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MyNavBar from "../../../components/myNavBar/index";
import "../../../iconfont/iconfont.css";
import "./index.scss";
import seenEye from "../../../image/seenEye.png";
import { Tabs, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Toast } from "antd-mobile";
import DesignerPopup from "../../../components/designerPopup/index"; //仓储弹框
import {editUrl, GetQuery, locationHref} from "../../../utils/index";

const { TabPane } = Tabs;

class DecorateDaqo extends Component {
  state = {
    websiteDataList: [],
    getwebsiteTypeList: [],
    websiteDataListSeo:[],
    activeId: 9,
    len: 10,
    page: 1,
    ReplayVisible: false,
    filterQuestionList: [],
    questionValue: "",
    seotitle:null,
    keyword:null,
    describes:null
  };
 
  callback(key) {
    if (key === "3") {
      editUrl("/decorateGan/DecorateDaqo/5/tab3")
      this.setState({
        activeId:4,
        page:1
      }, () => this.getWebsiteData(4))
    }
    if (key === "2") {
      editUrl("/decorateGan/DecorateDaqo/5/tab2")

      this.setState({
        activeId: 20,
        page: 1
      }, () => this.getWebsiteData(this.state.activeId))
    }
    if (key === "1") {
      editUrl("/decorateGan/DecorateDaqo/5/tab1")
      this.setState({
        activeId: 9
      }, () => this.getWebsiteData(9))
    }
  }

  chooseTag(e) {}
  ReplaySaveInfo(obj) {
    let { questionId } = this.state;
    this.post(
      `${this.api.addReply}?questionId=${questionId}&reply=${obj.question}`,
      ""
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          ReplayVisible: false,
        });
        Toast.success("已提交信息", 1);
      }
    });
  }

  componentDidMount() {
    this.getWebsiteData(9);
    this.get(`${this.api.getwebsiteType}?id=${2}&${GetQuery()}`, "").then((res) => {
      if (res.code === 0) {
        this.setState(
          {
            getwebsiteTypeList: res.data,
            seotitle:res.data.seotitle,
            keyword:res.data.keyword,
            describes:res.data.describes
          },
          () => {
          }
        );
      }
    });
  }

  getWebsiteData(id) {
    this.get(
      `${this.api.websiteData}?typeId=${id}&page=${this.state.page}&limit=${10}&${GetQuery()}`,
      ""
    ).then((res) => {
      if (res.code === 0) {
        this.setState(
          {
            websiteDataListSeo: this.state.page === 1 ? res.data[0] : this.state.websiteDataList[0],
            websiteDataList: this.state.page === 1 ? res.data : this.state.websiteDataList.concat(res.data),
            filterQuestionList: this.state.page === 1 ? res.data : this.state.filterQuestionList.concat(res.data),

            len: res.data.length
          },
          () => {
            if (!this.state.websiteDataListSeo) return
            document.title = this.state.websiteDataListSeo.seotitle;
            document
              .createElement("title")
              .setAttribute("content", this.state.websiteDataListSeo.seotitle);
            if (this.state.websiteDataListSeo.keyword) {
              document.createElement('meta')
                .setAttribute("content", this.state.websiteDataListSeo.keyword);
            } else {
            }
            if (this.state.websiteDataListSeo.describes) {
              document
                .createElement('meta')
                .setAttribute("content", this.state.websiteDataListSeo.describes);
            } else {
            }
          }
        );
      }
    });
  }

  filterQuestion(type, e) {
    let { websiteDataList } = this.state;
    this.setState({
      questionValue: e.target.value,
    });
    let res = [];
    websiteDataList.map((item) => {
      if (item.title)
        if (item.title.indexOf(e.target.value) !== -1) {
          res.push(item);
        }
    });
    this.setState({
      filterQuestionList: res,
    });
  }

  getwebsiteType() {
    this.get(`${this.api.getwebsiteType}?id=${this.state.id}&${GetQuery()}`, "").then(
      (res) => {
        if (res.code === 0) {
          this.setState({
            getwebsiteTypeList: res.data,
            activeId: res.data.length > 0 ? res.data[0].id : null,
          });
        }
      }
    );
  }

  setFlag = (id) => {
    this.setState(
      {
        activeId: id,
      },
      () => {
        this.getWebsiteData(this.state.activeId);
        editUrl("/decorateGan/DecorateDaqo/"+id)
      }
    );
  };

  goDecorateDetails(id) {
    locationHref("/decorateGan/decorateDetail/" + id)
  }

  goQuestionDetail(id) {
    locationHref("/decorateGan/questionDetail/" + id)
  }
  million(value) {
    //过万处理
    let num;
    if (value > 9999) {
      //大于9999显示x.xx万
      num = Math.floor(value / 1000) / 10 + "W";
    } else if (value < 9999 && value > -9999) {
      num = value;
    } else if (value < -9999) {
      //小于-9999显示-x.xx万
      num = -(Math.floor(Math.abs(value) / 1000) / 10) + "W";
    }
    return num;
  }

  RenderList() {
    let { websiteDataList } = this.state;
    return websiteDataList.map((item) => {
      return (
        <div
          className="moduleListContent"
          key={item.id}
          onClick={this.goDecorateDetails.bind(this, item.id)}
        >
          <div className="moduleListContent_left">
            <span className="moduleListContent_title">{item.title}</span>
            <div className="moduleListContent_desc">
              <span className="moduleListContent_desc_txts">{item.intro}</span>
            </div>
            <div className="moduleListContent_bottom">
              <span className="decoration_time">{item.time}</span>
              <div className="decoration_views">
                <img className="view_number" src={seenEye} alt="" />
                {this.million(item.counts)}
              </div>
            </div>
          </div>
          <div className="moduleListContent_right">
            <img className="moduleListContent_img" src={item.imgUrl} alt="" />
          </div>
        </div>
      );
    });
  }

  RenderEncyclopedias() {
    return <div className="">{this.RenderList()}</div>;
  }

  RenderQuestionList() {
    let { filterQuestionList } = this.state;
    return filterQuestionList.map((item) => {
      return (
        <div className="questionListWrap" key={item.id}>
          <p
            className="questionList_title"
            onClick={this.goQuestionDetail.bind(this, item.id)}
          >
            {item.title}
          </p>
          <div
            className="questionList_desc_wrap"
            onClick={this.goQuestionDetail.bind(this, item.id)}
          >
            <span className="questionList_desc">{item.intro}</span>
          </div>
          <div className="questionList_Bottom">
            <span
              className="question_time"
              onClick={this.goQuestionDetail.bind(this, item.id)}
            >
              {item.time}
            </span>
            <span
              className="question_number"
              onClick={this.goQuestionDetail.bind(this, item.id)}
            >
              已有
              <span
                style={{ color: "#0166B3" }}
                onClick={this.goQuestionDetail.bind(this, item.id)}
              >
                {this.million(item.questionCounts)}
              </span>
              个回答
            </span>
            <div
              className="question_view"
              onClick={this.goQuestionDetail.bind(this, item.id)}
            >
              <img className="view_number" src={seenEye} alt="" />
              {this.million(item.counts)}
            </div>
            <div
              className="want_answer_btn"
              onClick={() => {
                this.setState({ ReplayVisible: true, questionId: item.id });
              }}
            >
              我要解答
            </div>
          </div>
        </div>
      );
    });
  }

  RenderQuestion() {
    return (
      <div className="question_answer">
        <div className="question_answer_header">
          <Input
            className="question_answer_search"
            placeholder="请输入您的装修困惑"
            prefix={<SearchOutlined />}
            onChange={this.filterQuestion.bind(this, 1)}
            value={this.state.questionValue}
          />
        </div>
        <div className="question_answer_tags">
          {this.state.getwebsiteTypeList.map((item, index) => {
            return (
              <div
                id={this.state.activeId === item.id ? "active" : "tabss_t"}
                onClick={() => this.setFlag(item.id)}
                key={index}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        <div className="">{this.RenderQuestionList()}</div>
      </div>
    );
  }

  RenderDiary() {
    return <div className="">{this.RenderList()}</div>;
  }
  loadPage=()=>{
    this.setState({
      page: this.state.page + 1
    }, () => {
      this.getWebsiteData(this.state.activeId);
    })
  }
  render() {
    let { ReplayVisible } = this.state;
    let changeArea;
    changeArea = (
      <div className="container">
        <Tabs defaultActiveKey="1" onChange={(key) => this.callback(key)}>
          <TabPane tab="百科" key="1">
            {this.RenderEncyclopedias()}
          </TabPane>
          <TabPane tab="问答" key="2">
            {this.RenderQuestion()}
          </TabPane>
          <TabPane tab="日记" key="3">
            {this.RenderDiary()}
          </TabPane>
        </Tabs>
        
      </div>
    );
    return (
      <div className="DecorateDaqo_container">
        <MyNavBar />
        {changeArea}
        {
          this.state.len === 10 ? <div className='loadMore' onClick={this.loadPage}>点击加载更多</div> :
            <div className='loadMore'>无更多内容</div>
        }
        <DesignerPopup
          visible={ReplayVisible}
          title="您的回答"
          cancelClick={() => this.setState({ ReplayVisible: false })}
          saveInfo={this.ReplaySaveInfo.bind(this)}
          type="question"
          btnTxt={["提交"]}
        />
      </div>
    );
  }
}
export default withRouter(DecorateDaqo);
