import React, {Component} from 'react'
import queryString from "query-string";
import {withRouter} from 'react-router-dom'
import MyNavBar from '../../../components/myNavBar/index'
import '../../../iconfont/iconfont.css'
import './index.scss'
import seenEye from '../../../image/seenEye.png'
import bannerMiddle from '../../../image/banner_middle.png'
import DesignerPopup from '../../../components/designerPopup/index'
import {Toast} from 'antd-mobile'
import {editUrl, GetQuery, locationHref} from "../../../utils/index";

// const querystring = require("querystring");

class decorationList extends Component {
  state = {
    getwebsiteTypeList: [],
    activeId: 1,
    id: 1,
    len: 10,
    page: 1,
    websiteDataList: [],
    getwebsiteTypeListSeo:[],
    priceVisible: false,
    tabs:null,
    eid:null,
    seotitle:null,
    keyword:null,
    describes:null
  }
  componentWillMount() {
    let id = this.props.match.params.id
    let tab=this.props.match.params.tab
    this.setState({
      id: id
    })
    this.get(
      `${this.api.websiteData}?typeId=${9}&page=${1}&limit=${10}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState(
          {
            websiteDataList: res.data
          })
      }
    })
    this.websiteData()
  }
  loadPage=()=>{
    this.setState({
      page: this.state.page + 1
    }, () => {
      this.websiteData()
    })
  }
  componentWillReceiveProps(nextProps) {
    const tab = nextProps.match.params.tab;
    }
  componentDidMount() {
    this.getwebsiteType()
    var meta = document.getElementsByTagName('meta')
    addMate('a', `${this.state.seotitle}`, this.state.seotitle)
    addMate(
      'b',
      'keywords',
      this.state.keyword
    )
    addMate(
      'c',
      'description',
      this.state.describes
    )

    function addMate(id, name, content) {
      let metaData = document.getElementsByTagName('head')[0].children
      for (let i = 0; i < metaData.length; i++) {
        if (metaData[i].localName === 'meta' && metaData[i].id === id) {
          const mate = document.getElementById(id)
          document.getElementsByTagName('head')[0].removeChild(mate)
        }
      }
      let meta = document.createElement('meta')
      meta.content = content
      meta.name = name
      meta.id = id
      document.getElementsByTagName('head')[0].appendChild(meta)
    }
  }
  setFlag = (id,name,seotitle,keyword,describes) => {
    editUrl("/decorateGan/decorationList/6/tab" + id)
    document.title = seotitle
    this.setState(
      {
        seotitle: seotitle,
        keyword: keyword,
        describes: describes,
        page: 1,
        activeId: id
      },
      () => {
        this.websiteData()
      }
    )
  }
  getwebsiteDetailInfo = (id) => {
    this.get(`${this.api.getwebsiteDetailInfo}?id=${id}`, '').then((res) => {
      this.setState(
        {
          websiteDetailInfo: res.data
        },
        () => {
        }
      )
    })
  }
  iconclick = (type) => {
    this.setState({
      flag: type
    })
  }
  getwebsiteType() {
    this.get(`${this.api.getwebsiteType}?id=${this.state.id}&${GetQuery()}`, '').then(
      (res) => {
        if (res.code === 0) {
          this.setState({
            getwebsiteTypeList: res.data,
            getwebsiteTypeListSeo: res.data[0],
            activeId: res.data.length > 0 ? res.data[0].id : null
          },()=>{
          })
        }
      }
    )
  }
  websiteData() {
    this.get(
      `${this.api.websiteData}?typeId=${
        this.state.activeId
      }&page=${this.state.page}&limit=${10}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          len: res.data.length,
          websiteDataList: this.state.page === 1 ? res.data : this.state.websiteDataList.concat(res.data)
        })
      }
    })
  }
  tab1() {
    return (
      <div className='tabs'>
        {this.state.getwebsiteTypeList.map((item, index) => {
          return (
            <div
              id={this.state.activeId === item.id ? 'activeId' : 'tabsID'}
              onClick={() => this.setFlag(item.id,item.name,item.seotitle,item.keyword,item.describes)}
              key={index}
            >
              {item.name}
            </div>
          )
        })}
      </div>
    )
  }
  goDecorateDetails(id) {
    locationHref('/decorateGan/decorateDetail/' + id)
  }

  priceSaveInfo(obj) {
    let source = '获取报价'
    let sourceUrl = escape(document.location.href);//'/decorateGan/decorationList'
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          priceVisible: false
        })
        Toast.success('已提交信息', 1)
      }
    })
  }

  RenderList() {
    return (
      <div className='moduleListContent_warp'>
        {this.state.websiteDataList.map((item, index) => {
          return (
            <div
              className='moduleListContent'
              onClick={this.goDecorateDetails.bind(this, item.id)}
            >
              <div className='moduleListContent_left'>
                <span className='moduleListContent_title'>{item.title}</span>
                <div className='moduleListContent_desc'>
               <span className='moduleListContent_desc_txts' >{item.intro}</span> 
                </div>
                <div className='moduleListContent_bottom'>
                  <span className='decoration_time'>{item.time}</span>
                  <div className='decoration_views'>
                    <img className='view_number' src={seenEye} alt='' />
                    {item.counts}
                  </div>
                </div>
              </div>
              <div className='moduleListContent_right'>
                <img
                  className='moduleListContent_img'
                  src={item.imgUrl}
                  alt=''
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  RenderDecorationModuleList() {
    return (
      <div className='decorationModuleLists'>
        <div className='moduleListContentWrap'>
          {this.RenderList()}
          {/* {this.RenderList()} */}
          {/* {this.RenderList()} */}
          {
            this.state.len === 10 ? <div className='loadMore' onClick={this.loadPage}>点击加载更多</div> :
              <div className='loadMore'>无更多内容</div>
          }
        </div>
      </div>
    )
  }
  render() {
    let {priceVisible} = this.state
    return (
      <div className='decorationList_container'>
        <MyNavBar type='search' />
        <div
          className='bannerTop'
          onClick={() => {
            this.setState({priceVisible: true})
          }}
        >
          <img className='bannerTop_img' src={bannerMiddle} alt='' />
          <span className='preciseSolution'>已为1302234人提供精准方案</span>
        </div>
        <div className='container_content_'>
          <div className='container_content_left'>{this.tab1()}</div>
          <div className='container_content_right'>
            {this.RenderDecorationModuleList()}
          </div>
        </div>
        <DesignerPopup
          visible={priceVisible}
          title='装修要花多少钱?'
          subTitle='1688'
          cancelClick={() => this.setState({priceVisible: false})}
          saveInfo={this.priceSaveInfo.bind(this)}
          btnTxt={['获取报价']}
          type='price'
          price={98234}
        />
      </div>
    )
  }
}
export default withRouter(decorationList)
