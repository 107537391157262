import React, { Component } from 'react'
import whole from '../../../image/whole.png'
import MyNavBar from '../../../components/myNavBar/index'
import phone from '../../../image/phone.png'
import topsjs from '../../../image/topsjs.png'
import '../../../css/JuranCommitment.css'
import { GetTel, GetAddr } from "../../../utils/index";

export default class JuranCommitment extends Component {
  componentDidMount() {
    //装修保障tdk内容
    var meta = document.getElementsByTagName('meta')
    document.title = '装修保障_装修施工质量保障_装修材料质量保障-居然装饰'
    addMate('a', 'title', '装修保障_装修施工质量保障_装修材料质量保障-居然装饰')
    addMate(
        'b',
        'keywords',
        '装修保障,装修施工质量保障'
    )
    addMate(
        'c',
        'description',
        '居然装饰装修保障，让装修无后顾之后，装修保障让家装和家居服务快乐简单；装修质量保证措施工程质量管理最终反映工程设计效果和工程质量等级的重要环节，选居然装饰，有保障。'
    )

    function addMate(id, name, content) {
        let metaData = document.getElementsByTagName('head')[0].children
        for (let i = 0; i < metaData.length; i++) {
            if (metaData[i].localName === 'meta' && metaData[i].id === id) {
                const mate = document.getElementById(id)
                document.getElementsByTagName('head')[0].removeChild(mate)
            }
        }
        let meta = document.createElement('meta')
        meta.content = content
        meta.name = name
        meta.id = id
        document.getElementsByTagName('head')[0].appendChild(meta)
    }
}

//底部栏
  StoreFooter() {
    return (
      <div className='StoreFooter'>
        <div className='consulting'>
          <span>装修咨询</span>
          <a href={`tel:${GetTel()}`} className='phoneNum'>{GetTel()}</a>
          <img className='phone_icon' src={phone} alt='' />
        </div>
        <div className='address'>
          总部地址：{GetAddr()}
        </div>
      </div>
    )
  }
    render() {
        return (
            <div className="container">
                 <MyNavBar />
                <div className="container_banner">
                <img className="container_banner" src={topsjs} alt="" />
                </div>
                <div className="container_content_">
                    <img className="container_content_" src={whole} alt="" />
                </div>
                <div className="StoreFooter">
                        {this.StoreFooter()}
                </div>
            </div>
        )
    }
}
