import React, { Component } from 'react'
import './index.scss'
import {Carousel, Tabs} from 'antd'
import 'antd/dist/antd.css'
import {
    sxt,
    bg_bottom,
    bg_top,
    blue01,
    blue16,
    orange10,
    gold9,
    smartAPP,
    top_banner,
    xuni,
    figure1_6check,
    figure2_8checklist,
    figure3_6callback
} from '../../../image/engineering/index'

const TabPane = Tabs.TabPane

export default class index extends Component {
    componentDidMount() {
        //装修保障tdk内容
        var meta = document.getElementsByTagName('meta')
        document.title = '装修保障_装修施工质量保障_装修材料质量保障-居然装饰'
        addMate('a', 'title', '装修保障_装修施工质量保障_装修材料质量保障-居然装饰')
        addMate(
            'b',
            'keywords',
            '装修保障,装修施工质量保障'
        )
        addMate(
            'c',
            'description',
            '居然装饰装修保障，让装修无后顾之后，装修保障让家装和家居服务快乐简单；装修质量保证措施工程质量管理最终反映工程设计效果和工程质量等级的重要环节，选居然装饰，有保障。'
        )

        function addMate(id, name, content) {
            let metaData = document.getElementsByTagName('head')[0].children
            for (let i = 0; i < metaData.length; i++) {
                if (metaData[i].localName === 'meta' && metaData[i].id === id) {
                    const mate = document.getElementById(id)
                    document.getElementsByTagName('head')[0].removeChild(mate)
                }
            }
            let meta = document.createElement('meta')
            meta.content = content
            meta.name = name
            meta.id = id
            document.getElementsByTagName('head')[0].appendChild(meta)
        }
    }
    callback = (key) => {
        var id = key
        this.setState(
          {
            id: id
          },
          () => {
          }
        )
      }
    renderTab() {
        return (
            <div>
                <Tabs
                    defaultActiveKey='1'
                    className='tabs_'
                    onChange={(key) => this.callback(key)}
                >
                    <TabPane tab='乐屋装饰' key='1'>
                    <img className='engineering_img' src={blue16} alt='' />
                    </TabPane>
                    <TabPane tab='快屋装饰' key='2'>
                    <img className='engineering_img' src={orange10} alt='' />
                    </TabPane>
                    <TabPane tab='顶层设计' key='3'>
                    <img className='engineering_img' src={gold9} alt='' />
                    </TabPane>
                </Tabs>
            </div>
        )
    }

    renderBanner1() {
        return (
            <div className="container_banner1">
                <Carousel
                >
                        <img
                            src={sxt}
                            alt=''
                        />
                        <img
                            src={xuni}
                            alt=''
                        />
                </Carousel>
            </div>
        )
    }
    renderBanner3() {
        return (
            <div className="container_banner1">
                <Carousel
                >
                        <img
                            src={figure1_6check}
                            alt=''
                        />
                          <img
                            src={figure2_8checklist}
                            alt=''
                        />
                        <img
                            src={figure3_6callback}
                            alt=''
                        />
                </Carousel>
            </div>
        )
    }

    render() {
        return (
            <div className="engineering_container">
                <div className="engineering_img"><img className='engineering_img' src={bg_top} alt='' /></div>
                {this.renderTab()}
                <div><img className='engineering_img' src={smartAPP} alt='' /></div>
                {this.renderBanner1()}
                {this.renderBanner3()}
                <div><img className='engineering_img' src={bg_bottom} alt='' /></div>
                <div></div>
            </div>
        )
    }
}
