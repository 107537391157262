import React, { Component } from 'react'
import MyNavBar from '../../../components/myNavBar/index'
import DesignerPopup from '../../../components/designerPopup/index'
import './index.scss'
import iconanalysis from '../../../image/DesignerDetail/iconanalysis.png'
import iconcolor from '../../../image/DesignerDetail/iconcolor.png'
import iconmeasure from '../../../image/DesignerDetail/iconmeasure.png'
import iconplan from '../../../image/DesignerDetail/iconplan.png'
import iconsoft from '../../../image/DesignerDetail/iconsoft.png'
import iconspace from '../../../image/DesignerDetail/iconspace.png'
import iconmore from '../../../image/DesignerDetail/iconmore.png'
import { Tabs, WhiteSpace } from 'antd-mobile'
import dizhi from '../../../image/dizhi.png'
import MyStep from '../../../components/myStep'
import { Toast } from 'antd-mobile'
import testStyles from '../../../image/testStyles.png'
import {GetQuery, locationHref} from "../../../utils/index";

const tabs = [{ title: '设计师简介' }, { title: '设计案例' }, { title: '在施工地' }]

export default class DesignerDetail extends Component {
    state = {
        WorkSiteListData: [],
        nowVisible: false,
        caseListData: [],
        storeData: [],
        workStatusData: [],
        DesignerData: []
    }

    componentDidMount() {
        let arr = this.props.location.pathname.split("/")
        let id = arr[arr.length - 1]
        this.getDesignerDetail(id || 1)
        // this.getDesignerDetail(1)
        this.getWorkSiteDetailList(id || 1)
        this.getDesignerDetailCaseList(id || 1)
        this.findStoreAll()
        this.getWorkStatusList()
    }

    getWorkSiteDetailList(id) {
        this.get(
            `${this.api.getWorkSiteDetailList}?page=${1}&limit=${10}&sjsId=${id}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    WorkSiteListData: res.data.data
                })
            }
        })
    }

    getDesignerDetail(id) {
        this.get(`${this.api.getDesignerDetail}?id=${id}`, '').then((res) => {
            if (res.code === 0) {
							this.setState({
								DesignerData: res.data
							}, () => {
								// 设计师详情seo优化内容
								document.title = this.state.DesignerData.seotitle
								if (this.state.DesignerData.keyword) {
									document.getElementsByName('keywords')[0].setAttribute('content', this.state.DesignerData.keyword);
								} 
								if (this.state.DesignerData.describes) {
									document.getElementsByName('description')[0].setAttribute('content', this.state.DesignerData.describes);
								}
							})
            }
        })
    }

    goStyleTest() {
        locationHref('/styleTesting')
    }

    getWorkStatusList() {
        this.get(`${this.api.getWorkStatusList}`, '').then((res) => {
            if (res.code === 0) {
                this.setState({
                    workStatusData: res.data
                })
            }
        })
    }

    getDesignerDetailCaseList(id) {
        this.get(
            `${this.api.getDesignerDetailCaseList}?page=${1}&limit=${10}&sjsId=${id}`,
            ''
        ).then((res) => {
            if (res.data.code === 0) {
                this.setState({
                    caseListData: res.data.data ? res.data.data : []
                })
            }
        })
    }

    findStoreAll() {
        this.get(`${this.api.findStoreAll}?page=${1}&limit=${1}`, '').then(
            (res) => {
                if (res.code === 0) {
                    this.setState({
                        storeData: res.data[0]
                    })
                }
            }
        )
    }

    goDetail(id) {
        locationHref('/detailsConstructionSite/' + id)
    }

    goStore() {
        locationHref('/listStore')
    }
    //跳转长尾词列表
    goLongTailWord(id) {
        locationHref('/longTailWords/'+id)
    }
    nowSaveInfo(obj) {
        let source = '立即预约'
        let sourceUrl = escape(document.location.href);//'page/DesignerDetail'
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    nowVisible: false
                })
                Toast.success('已提交信息', 1)
            }
        })
    }

    RenderDesignerHeader() {
        let { DesignerData } = this.state
        if (DesignerData && DesignerData !== undefined) {
            return (
                <div className='DesignerHeader'>
                    <div className='DesignerHeader_top'>
                        <div className='DesignerHeader_top_L'>
                            <img
                                className='designer_avator'
                                src={DesignerData.headImgUrl}
                                alt=''
                            />
                        </div>
                        <div className='DesignerHeader_top_R'>
                            <div className='DesignerHeader_name_identity'>
                                <span className='DesignerHeader_name'>{DesignerData.name}</span>
                                <span
                                    className='DesignerHeader_identity'
                                    style={{ color: '#0166B3' }}
                                >
                                    {DesignerData.leaver}
                                </span>
                            </div>
                            <span className='praiseRate'>好评率：{DesignerData.price}%</span>
                            <div className='experience'>
                                <span style={{ color: '#0166B3' }}> {DesignerData.jobYear}</span>
                                <span style={{ color: '#3E3E3F' }}> 年工作经验</span>  &nbsp;|&nbsp;
                                <span style={{ color: '#0166B3' }}>{DesignerData.caseCount} </span>
                                <span style={{ color: '#3E3E3F' }}> 套案例</span>
                            </div>
                            <div className='designer_spans'>
                                {DesignerData.style &&
                                    DesignerData.style.split('、', 5).map((item, index) => {
                                        return (
                                            <span className='designer_span' key={index}>
                                                {item}
                                            </span>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className='DesignerHeader_bottom'>
                        <span style={{ color: '#3E3E3F' }}>设计理念 ： </span>
                        <span className='DesignerHeader_bottom_desc' dangerouslySetInnerHTML={{
                            __html: DesignerData.designIdea
                        }}>
                        </span>
                        <div
                            className='oneTOoneBtn'
                            onClick={() => this.setState({ nowVisible: true })}
                        >
                            1对1咨询设计方案
                        </div>
                    </div>
                </div>
            )
        }
    }

    RenderBookingService() {
        return (
            <div className='BookingService'>
                <div className='sectionTitle'>可预约的服务</div>
                <ul className='BookingServiceList'>
                    <li className='BookingServiceItem'>
                        <img className='BookingServiceItem_img' src={iconmeasure} alt='' />
                        <span className='BookingServiceItem_span'>上门量房</span>
                        <div
                            className='nowSubscribe'
                            onClick={() => this.setState({ nowVisible: true })}
                        >
                            立即预约
                        </div>
                    </li>
                    <li className='BookingServiceItem'>
                        <img className='BookingServiceItem_img' src={iconanalysis} alt='' />
                        <span className='BookingServiceItem_span'>户型解析</span>
                        <div
                            className='nowSubscribe'
                            onClick={() => this.setState({ nowVisible: true })}
                        >
                            立即预约
                        </div>
                    </li>
                    <li className='BookingServiceItem'>
                        <img className='BookingServiceItem_img' src={iconspace} alt='' />
                        <span className='BookingServiceItem_span'>空间规划</span>
                        <div
                            className='nowSubscribe'
                            onClick={() => this.setState({ nowVisible: true })}
                        >
                            立即预约
                        </div>
                    </li>
                    <li className='BookingServiceItem'>
                        <img className='BookingServiceItem_img' src={iconplan} alt='' />
                        <span className='BookingServiceItem_span'>动线规划</span>
                        <div
                            className='nowSubscribe'
                            onClick={() => this.setState({ nowVisible: true })}
                        >
                            立即预约
                        </div>
                    </li>
                    <li className='BookingServiceItem'>
                        <img className='BookingServiceItem_img' src={iconcolor} alt='' />
                        <span className='BookingServiceItem_span'>动线规划</span>
                        <div
                            className='nowSubscribe'
                            onClick={() => this.setState({ nowVisible: true })}
                        >
                            立即预约
                        </div>
                    </li>
                    <li className='BookingServiceItem'>
                        <img className='BookingServiceItem_img' src={iconsoft} alt='' />
                        <span className='BookingServiceItem_span'>软装设计</span>
                        <div
                            className='nowSubscribe'
                            onClick={() => this.setState({ nowVisible: true })}
                        >
                            立即预约
                        </div>
                    </li>
                </ul>
            </div>
        )
    }

    RenderStore() {
        let { storeData } = this.state
        if (storeData.length !== 0) {
            return (
                <div className='storeWrap'>
                    <div className='sectionTitle'>所在门店</div>
                    <div
                        className='storeList'
                        onClick={() => {
                            this.goStore()
                        }}
                    >
                        {storeData.img ? <img className='storeImg' src={storeData.img.split(',')[0]} alt='' /> : <img className='storeImg' src='' alt='图片不存在' />}
                        <span className='storeAddress'>{storeData.address}</span>
                        <img className='iconmore' src={iconmore} alt='' />
                    </div>
                </div>
            )
        }
    }

    RenderTab() {
        let { DesignerData } = this.state
        if (DesignerData && DesignerData !== undefined) {
            return (
                <div>
                    <Tabs tabs={tabs} initialPage={0} animated={true} useOnPan={false}>
                        <div
                            style={{
                                padding: '20px 12px 42px 12px',
                                backgroundColor: '#fff'
                            }}
                        >
                            <div className='designer_tab_name'>
                                <span>姓名：</span>
                                <span>{DesignerData.name}</span>
                            </div>
                            <div className='designer_tab_info'>
                                <span>简介：</span>
                                <p dangerouslySetInnerHTML={
                                    {
                                        __html: DesignerData.infos
                                    }}>
                                </p>
                            </div>
                            <div className='designer_tab_prize'>
                                <span>荣获奖项：</span>
                                <p dangerouslySetInnerHTML={{
                                    __html: DesignerData.honour
                                }}>
                                </p>
                            </div>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#fff'
                            }}
                        >
                            <ul className='DesignCase_tab'>
                                {this.state.caseListData.map((item, index) => {
                                    return (
                                        <li className='DesignCase_tab_list' key={index}>
                                            <img
                                                className='DesignCase_tab_list_img'
                                                src={item.cover}
                                                alt=''
                                            />
                                            <p>{item.title}</p>
                                            <p>
                                                {item.houseType}&nbsp; | &nbsp;{item.styleName}&nbsp; | &nbsp;{item.acreage}㎡
                                            </p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#fff'
                            }}
                        >
                            {this.RenderSiteList()}
                        </div>
                    </Tabs>
                    <WhiteSpace />
                </div>
            )
        }
    }

    RenderSiteList() {
        let { WorkSiteListData, workStatusData } = this.state
        if (WorkSiteListData !== undefined && workStatusData !== undefined) {
            return (
                <div className='siteListWrap'>
                    {WorkSiteListData.map((item, index) => {
                        return (
                            <div
                                className='siteListItem'
                                key={index}
                                onClick={this.goDetail.bind(this, item.id)}
                            >
                                <img className='site_img' src={item.websiteCover} alt='' />
                                <div className='siteListInfo'>
                                    <div className='siteListHeader'>
                                        <span className='site_name'>{item.websiteTitle}</span>
                                        <div className='siteAddress'>
                                            <img className='addressIcon' src={dizhi} alt='' />
                                            <span className='site_address'>
                                                {item.provinceName}·{item.countiesName}
                                            </span>
                                        </div>
                                    </div>
                                    <p className='site_houseInfo'>
                                        {item.floorage}m²&nbsp; | &nbsp;{item.htName}&nbsp; | &nbsp;{item.styleName}
                                    </p>
                                    <p className='site_designer'>
                                        设计师：{item.sjs} &nbsp;&nbsp;&nbsp;&nbsp; 工长：{item.gz}
                                    </p>
                                </div>
                                <div className='siteList_progress'>
                                    <MyStep step={item.status} stepData={workStatusData} />
                                </div>
                            </div>
                        )
                    })}
                    <p className='nomore'>没有更多内容了</p>
                </div>
            )
        }
    }

    render() {
        let { nowVisible } = this.state
        let labesList = this.state.DesignerData.labelsList;
        let changeArea = (
            <div className='DesignerDetail'>
                {/* <div
                    className='bannerTop'
                    onClick={this.goStyleTest.bind(this)}
                    style={{ marginTop: 0 }}
                >
                    <img className='bannerTop_img' src={testStyles} alt='' />
                </div> */}
                {this.RenderDesignerHeader()}
                {this.RenderBookingService()}
                {this.RenderStore()}
                {this.RenderTab()}
                <div className="DetailContentSeoTitle">
                    {
                        labesList && labesList.map((item, index) => {
                            return (
                                <div id="seoTitle" key={index} onClick={this.goLongTailWord.bind(this, item.id)}>
                                    {item.words}
                                </div>
                            )
                        })
                    }
                </div>
                <DesignerPopup
                    visible={nowVisible}
                    title='预约TA'
                    type={'ta'}
                    subTitle='在线预约 尊享优先排期特权'
                    bottomdata={['上门量房', '户型解析', '动线设计', '软装设计']}
                    cancelClick={() => this.setState({ nowVisible: false })}
                    saveInfo={this.nowSaveInfo.bind(this)}
                    btnTxt={['立即预约']}
                />
            </div>
        )
        return (
            <div className='container'>
                <MyNavBar />
                {changeArea}
            </div>
        )
    }
}
