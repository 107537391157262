import React, {Component} from 'react'
import './animated.css'

class ActionSheet extends Component {
    close() {
        this.props.onClose()
    }

    render() {
        const {visite} = this.props
        return (
            <div
                {...this.props}
                className={visite ? 'animated-fade-in' : 'animated-fade-out'}
                onClick={this.close.bind(this)}
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'RGBA(0, 0, 0, 0.7)',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 1000
                }}
            >
                <div
                    className={
                        visite ? 'animated-slide-in-up' : 'animated-slide-out-down'
                    }
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    style={{
                        width: '100%',
                        bottom: 0,
                        overflowY: 'scroll',
                        minHeight: '100vh'
                    }}
                >
                    {this.props.children}
                </div>
                <div className='FoundPanel_bottom' onClick={this.close.bind(this)}>
                    <div className='footer-href' style={{color: 'rgb(18, 150, 219)'}}>
                        <span className='iconfont icon-tubiaozhizuomobanyihuifu-'
                              style={{color: '#1296DB', marginLeft: '.206667rem'}}/>
                        <span style={{fontSize: '.32rem', marginLeft: '.16333rem', marginTop: '-.16rem'}}>
              发现
            </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default ActionSheet
