import React, { Component } from 'react'
import MyNavBar from '../../../components/myNavBar/index'
import phone from '../../../image/phone.png'
import bg_top from '../../../image/JuranSecurity/bg_top.png'
import top_bottom from '../../../image/JuranSecurity/top_bottom.png'
import bg_3D from '../../../image/JuranSecurity/bg_3D.png'
import bg_AI from '../../../image/JuranSecurity/bg_AI.png'
import bg_design from '../../../image/JuranSecurity/bg_design.png'
import bg_link from '../../../image/JuranSecurity/bg_link.png'
import bg_prewarning from '../../../image/JuranSecurity/bg_prewarning.png'
import bg_money from '../../../image/JuranSecurity/bg_money.png'
import bg_middle from '../../../image/JuranSecurity/bg_middle.png'
import '../../../css/JuranSecurity.css'
import { GetTel, GetAddr } from "../../../utils/index";

import {Carousel} from 'antd'
import 'antd/dist/antd.css'

export default class JuranSecurity extends Component {
    componentDidMount() {
        //装修保障tdk内容
        var meta = document.getElementsByTagName('meta')
        document.title = '装修保障_装修施工质量保障_装修材料质量保障-居然装饰'
        addMate('a', 'title', '装修保障_装修施工质量保障_装修材料质量保障-居然装饰')
        addMate(
            'b',
            'keywords',
            '装修保障,装修施工质量保障'
        )
        addMate(
            'c',
            'description',
            '居然装饰装修保障，让装修无后顾之后，装修保障让家装和家居服务快乐简单；装修质量保证措施工程质量管理最终反映工程设计效果和工程质量等级的重要环节，选居然装饰，有保障。'
        )

        function addMate(id, name, content) {
            let metaData = document.getElementsByTagName('head')[0].children
            for (let i = 0; i < metaData.length; i++) {
                if (metaData[i].localName === 'meta' && metaData[i].id === id) {
                    const mate = document.getElementById(id)
                    document.getElementsByTagName('head')[0].removeChild(mate)
                }
            }
            let meta = document.createElement('meta')
            meta.content = content
            meta.name = name
            meta.id = id
            document.getElementsByTagName('head')[0].appendChild(meta)
        }
    }

       //底部栏
       StoreFooter() {
        return (
            <div className='StoreFooter'>
                <div className='consulting'>
                    <span>装修咨询</span>
                    <a href={`tel:${GetTel()}`} className='phoneNum'>{GetTel()}</a>
                    <img className='phone_icon' src={phone} alt='' />
                </div>
                <div className='address'>
                    总部地址：{GetAddr()}
            </div>
            </div>
        )
    }
    renderBanner1() {
        return (
            <div className="container_banner1">
                <Carousel
                >
                    <img
                        src={bg_3D}
                        alt=''
                    />
                    <img
                        src={bg_AI}
                        alt=''
                    />
                </Carousel>
            </div>
        )
    }
    renderBanner2() {
        return (
            <div className="container_banner1">
                <Carousel
                >
                    <img
                        src={bg_money}
                        alt=''
                    />
                    <img
                        src={bg_design}
                        alt=''
                    />
                    <img
                        src={bg_prewarning}
                        alt=''
                    />
                       <img
                        src={bg_link}
                        alt=''
                    />
                </Carousel>
            </div>
        )
    }
    render() {
        return (
            <div className="JuranSecurity_container">
                 <MyNavBar />
                <div className="JuranSecurity_container_title"><img className='JuranSecurity_container_title' src={bg_top} alt='' /></div>
                {this.renderBanner1()}
                <div className="JuranSecurity_img"><img className='JuranSecurity_img' src={bg_middle} alt='' /></div>
                {this.renderBanner2()}
                <div className="JuranSecurity_container_content"><img className='JuranSecurity_container_content' src={top_bottom} alt='' /></div>
                <div className="StoreFooter">
                    {this.StoreFooter()}
                </div>
            </div>
        )
    }
}
