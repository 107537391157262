import React, {Component} from 'react'

export default class PageComponent extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.state = {
            scrollHeight: 110,
            hasMore: true,// 判断接口是否还有数据，通过接口设置
            dataList: [], // 数据列表
        }
    }

    fetchData(page) {
        this.setState({
            hasMore: false
        })
        this.props.fetch()
        setTimeout({
            hasMore: true
        }, 200)
        // 接口调用数据字段
        //传入的参数包括但不限于：pageIndex， pageSize。。。
        // 获取后更新的数据包括但不限于：dataList，hasMore。。。
    }

    onScroll(page) {

        const {hasMore} = this.state;
        if (!hasMore) {
            return;
        }
        //下面是判断页面滚动到底部的逻辑
        if (this.myRef.current.scrollTop + this.myRef.current.clientHeight >= this.myRef.current.scrollHeight - this.state.scrollHeight) {
            this.fetchData()
        }
    }

    render() {
        return (
            <div>
                <div
                    ref={this.myRef}
                    style={{overflow: 'auto', height: this.props.height + 'rem'}} onScroll={() => this.onScroll()}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
