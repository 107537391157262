export const cityDataPinyin = [{"id": 36, "pid": 2, "name": "北京", "pinyin": "Beijing"},
    {"id": 37, "pid": 3, "name": "天津", "pinyin": "Tianjin"},
    {"id": 38, "pid": 4, "name": "石家庄", "pinyin": "Shijiazhuang"},
    {"id": 39, "pid": 4, "name": "唐山", "pinyin": "Tangshan"},
    {"id": 40, "pid": 4, "name": "秦皇岛", "pinyin": "Qinhuangdao"},
    {"id": 41, "pid": 4, "name": "邯郸", "pinyin": "Handan"},
    {"id": 42, "pid": 4, "name": "邢台", "pinyin": "Xingtai"},
    {"id": 43, "pid": 4, "name": "保定", "pinyin": "Baoding"},
    {"id": 44, "pid": 4, "name": "张家口", "pinyin": "Zhangjiakou"},
    {"id": 45, "pid": 4, "name": "承德", "pinyin": "Chengde"},
    {"id": 46, "pid": 4, "name": "沧州", "pinyin": "Cangzhou"},
    {"id": 47, "pid": 4, "name": "廊坊", "pinyin": "Langfang"},
    {"id": 48, "pid": 4, "name": "衡水", "pinyin": "Hengshui"},
    {"id": 49, "pid": 5, "name": "太原", "pinyin": "Taiyuan"},
    {"id": 50, "pid": 5, "name": "大同", "pinyin": "Datong"},
    {"id": 51, "pid": 5, "name": "阳泉", "pinyin": "Yangquan"},
    {"id": 52, "pid": 5, "name": "长治", "pinyin": "Changzhi"},
    {"id": 53, "pid": 5, "name": "晋城", "pinyin": "Jincheng"},
    {"id": 54, "pid": 5, "name": "朔州", "pinyin": "Shuozhou"},
    {"id": 55, "pid": 5, "name": "晋中", "pinyin": "Jinzhong"},
    {"id": 56, "pid": 5, "name": "运城", "pinyin": "Yuncheng"},
    {"id": 57, "pid": 5, "name": "忻州", "pinyin": "Xinzhou"},
    {"id": 58, "pid": 5, "name": "临汾", "pinyin": "Linfen"},
    {"id": 59, "pid": 5, "name": "吕梁", "pinyin": "Lvliang"},
    {"id": 60, "pid": 6, "name": "呼和浩特", "pinyin": "Huhehaote"},
    {"id": 61, "pid": 6, "name": "包头", "pinyin": "Baotou"},
    {"id": 62, "pid": 6, "name": "乌海", "pinyin": "Wuhai"},
    {"id": 63, "pid": 6, "name": "赤峰", "pinyin": "Chifeng"},
    {"id": 64, "pid": 6, "name": "通辽", "pinyin": "Tongliao"},
    {"id": 65, "pid": 6, "name": "鄂尔多斯", "pinyin": "Eerduosi"},
    {"id": 66, "pid": 6, "name": "呼伦贝尔", "pinyin": "Hulunbeier"},
    {"id": 67, "pid": 6, "name": "巴彦淖尔", "pinyin": "Bayanneer"},
    {"id": 68, "pid": 6, "name": "乌兰察布", "pinyin": "Wulanchabu"},
    {"id": 69, "pid": 6, "name": "兴安盟", "pinyin": "Xinganmeng"},
    {"id": 70, "pid": 6, "name": "锡林郭勒盟", "pinyin": "Xilinguolemeng"},
    {"id": 71, "pid": 6, "name": "阿拉善盟", "pinyin": "Alashanmeng"},
    {"id": 72, "pid": 7, "name": "沈阳", "pinyin": "Shenyang"},
    {"id": 73, "pid": 7, "name": "大连", "pinyin": "Dalian"},
    {"id": 74, "pid": 7, "name": "鞍山", "pinyin": "Anshan"},
    {"id": 75, "pid": 7, "name": "抚顺", "pinyin": "Fushun"},
    {"id": 76, "pid": 7, "name": "本溪", "pinyin": "Benxi"},
    {"id": 77, "pid": 7, "name": "丹东", "pinyin": "Dandong"},
    {"id": 78, "pid": 7, "name": "锦州", "pinyin": "Jinzhou"},
    {"id": 79, "pid": 7, "name": "营口", "pinyin": "Yingkou"},
    {"id": 80, "pid": 7, "name": "阜新", "pinyin": "Fuxin"},
    {"id": 81, "pid": 7, "name": "辽阳", "pinyin": "Liaoyang"},
    {"id": 82, "pid": 7, "name": "盘锦", "pinyin": "Panjin"},
    {"id": 83, "pid": 7, "name": "铁岭", "pinyin": "Tieling"},
    {"id": 84, "pid": 7, "name": "朝阳", "pinyin": "Chaoyang"},
    {"id": 85, "pid": 7, "name": "葫芦岛", "pinyin": "Huludao"},
    {"id": 86, "pid": 8, "name": "长春", "pinyin": "Changchun"},
    {"id": 87, "pid": 8, "name": "吉林", "pinyin": "Jilin"},
    {"id": 88, "pid": 8, "name": "四平", "pinyin": "Siping"},
    {"id": 89, "pid": 8, "name": "辽源", "pinyin": "Liaoyuan"},
    {"id": 90, "pid": 8, "name": "通化", "pinyin": "Tonghua"},
    {"id": 91, "pid": 8, "name": "白山", "pinyin": "Baishan"},
    {"id": 92, "pid": 8, "name": "松原", "pinyin": "Songyuan"},
    {"id": 93, "pid": 8, "name": "白城", "pinyin": "Baicheng"},
    {"id": 94, "pid": 8, "name": "延边朝鲜族", "pinyin": "Yanbianchaoxianzu"},
    {"id": 95, "pid": 9, "name": "哈尔滨", "pinyin": "Haerbin"},
    {"id": 96, "pid": 9, "name": "齐齐哈尔", "pinyin": "Qiqihaer"},
    {"id": 97, "pid": 9, "name": "鸡西", "pinyin": "Jixi"},
    {"id": 98, "pid": 9, "name": "鹤岗", "pinyin": "Hegang"},
    {"id": 99, "pid": 9, "name": "双鸭山", "pinyin": "Shuangyashan"},
    {"id": 100, "pid": 9, "name": "大庆", "pinyin": "Daqing"},
    {"id": 101, "pid": 9, "name": "伊春", "pinyin": "Yichun"},
    {"id": 102, "pid": 9, "name": "佳木斯", "pinyin": "Jiamusi"},
    {"id": 103, "pid": 9, "name": "七台河", "pinyin": "Qitaihe"},
    {"id": 104, "pid": 9, "name": "牡丹江", "pinyin": "Mudanjiang"},
    {"id": 105, "pid": 9, "name": "黑河", "pinyin": "Heihe"},
    {"id": 106, "pid": 9, "name": "绥化", "pinyin": "Suihua"},
    {"id": 107, "pid": 9, "name": "大兴安岭地区", "pinyin": "Daxinganlingdiqu"},
    {"id": 108, "pid": 10, "name": "上海", "pinyin": "Shanghai"},
    {"id": 109, "pid": 11, "name": "南京", "pinyin": "Nanjing"},
    {"id": 110, "pid": 11, "name": "无锡", "pinyin": "Wuxi"},
    {"id": 111, "pid": 11, "name": "徐州", "pinyin": "Xuzhou"},
    {"id": 112, "pid": 11, "name": "常州", "pinyin": "Changzhou"},
    {"id": 113, "pid": 11, "name": "苏州", "pinyin": "Suzhou"},
    {"id": 114, "pid": 11, "name": "南通", "pinyin": "Nantong"},
    {"id": 115, "pid": 11, "name": "连云港", "pinyin": "Lianyungang"},
    {"id": 116, "pid": 11, "name": "淮安", "pinyin": "Huaian"},
    {"id": 117, "pid": 11, "name": "盐城", "pinyin": "Yancheng"},
    {"id": 118, "pid": 11, "name": "扬州", "pinyin": "Yangzhou"},
    {"id": 119, "pid": 11, "name": "镇江", "pinyin": "Zhenjiang"},
    {"id": 120, "pid": 11, "name": "泰州", "pinyin": "Taizhou"},
    {"id": 121, "pid": 11, "name": "宿迁", "pinyin": "Suqian"},
    {"id": 122, "pid": 12, "name": "杭州", "pinyin": "Hangzhou"},
    {"id": 123, "pid": 12, "name": "宁波", "pinyin": "Ningbo"},
    {"id": 124, "pid": 12, "name": "温州", "pinyin": "Wenzhou"},
    {"id": 125, "pid": 12, "name": "嘉兴", "pinyin": "Jiaxing"},
    {"id": 126, "pid": 12, "name": "湖州", "pinyin": "Huzhou"},
    {"id": 127, "pid": 12, "name": "绍兴", "pinyin": "Shaoxing"},
    {"id": 128, "pid": 12, "name": "金华", "pinyin": "Jinhua"},
    {"id": 129, "pid": 12, "name": "衢州", "pinyin": "Quzhou"},
    {"id": 130, "pid": 12, "name": "舟山", "pinyin": "Zhoushan"},
    {"id": 131, "pid": 12, "name": "台州", "pinyin": "Taizhou"},
    {"id": 132, "pid": 12, "name": "丽水", "pinyin": "Lishui"},
    {"id": 133, "pid": 13, "name": "合肥", "pinyin": "Hefei"},
    {"id": 134, "pid": 13, "name": "芜湖", "pinyin": "Wuhu"},
    {"id": 135, "pid": 13, "name": "蚌埠", "pinyin": "Bangbu"},
    {"id": 136, "pid": 13, "name": "淮南", "pinyin": "Huainan"},
    {"id": 137, "pid": 13, "name": "马鞍山", "pinyin": "Maanshan"},
    {"id": 138, "pid": 13, "name": "淮北", "pinyin": "Huaibei"},
    {"id": 139, "pid": 13, "name": "铜陵", "pinyin": "Tongling"},
    {"id": 140, "pid": 13, "name": "安庆", "pinyin": "Anqing"},
    {"id": 141, "pid": 13, "name": "黄山", "pinyin": "Huangshan"},
    {"id": 142, "pid": 13, "name": "滁州", "pinyin": "Chuzhou"},
    {"id": 143, "pid": 13, "name": "阜阳", "pinyin": "Fuyang"},
    {"id": 144, "pid": 13, "name": "宿州", "pinyin": "Suzhou"},
    {"id": 145, "pid": 13, "name": "六安", "pinyin": "Liuan"},
    {"id": 146, "pid": 13, "name": "亳州", "pinyin": "Bozhou"},
    {"id": 147, "pid": 13, "name": "池州", "pinyin": "Chizhou"},
    {"id": 148, "pid": 13, "name": "宣城", "pinyin": "Xuancheng"},
    {"id": 149, "pid": 14, "name": "福州", "pinyin": "Fuzhou"},
    {"id": 150, "pid": 14, "name": "厦门", "pinyin": "Xiamen"},
    {"id": 151, "pid": 14, "name": "莆田", "pinyin": "Putian"},
    {"id": 152, "pid": 14, "name": "三明", "pinyin": "Sanming"},
    {"id": 153, "pid": 14, "name": "泉州", "pinyin": "Quanzhou"},
    {"id": 154, "pid": 14, "name": "漳州", "pinyin": "Zhangzhou"},
    {"id": 155, "pid": 14, "name": "南平", "pinyin": "Nanping"},
    {"id": 156, "pid": 14, "name": "龙岩", "pinyin": "Longyan"},
    {"id": 157, "pid": 14, "name": "宁德", "pinyin": "Ningde"},
    {"id": 158, "pid": 15, "name": "南昌", "pinyin": "Nanchang"},
    {"id": 159, "pid": 15, "name": "景德镇", "pinyin": "Jingdezhen"},
    {"id": 160, "pid": 15, "name": "萍乡", "pinyin": "Pingxiang"},
    {"id": 161, "pid": 15, "name": "九江", "pinyin": "Jiujiang"},
    {"id": 162, "pid": 15, "name": "新余", "pinyin": "Xinyu"},
    {"id": 163, "pid": 15, "name": "鹰潭", "pinyin": "Yingtan"},
    {"id": 164, "pid": 15, "name": "赣州", "pinyin": "Ganzhou"},
    {"id": 165, "pid": 15, "name": "吉安", "pinyin": "Jian"},
    {"id": 166, "pid": 15, "name": "宜春", "pinyin": "Yichun"},
    {"id": 167, "pid": 15, "name": "抚州", "pinyin": "Fuzhou"},
    {"id": 168, "pid": 15, "name": "上饶", "pinyin": "Shangrao"},
    {"id": 169, "pid": 16, "name": "济南", "pinyin": "Jinan"},
    {"id": 170, "pid": 16, "name": "青岛", "pinyin": "Qingdao"},
    {"id": 171, "pid": 16, "name": "淄博", "pinyin": "Zibo"},
    {"id": 172, "pid": 16, "name": "枣庄", "pinyin": "Zaozhuang"},
    {"id": 173, "pid": 16, "name": "东营", "pinyin": "Dongying"},
    {"id": 174, "pid": 16, "name": "烟台", "pinyin": "Yantai"},
    {"id": 175, "pid": 16, "name": "潍坊", "pinyin": "Weifang"},
    {"id": 176, "pid": 16, "name": "济宁", "pinyin": "Jining"},
    {"id": 177, "pid": 16, "name": "泰安", "pinyin": "Taian"},
    {"id": 178, "pid": 16, "name": "威海", "pinyin": "Weihai"},
    {"id": 179, "pid": 16, "name": "日照", "pinyin": "Rizhao"},
    {"id": 180, "pid": 16, "name": "莱芜", "pinyin": "Laiwu"},
    {"id": 181, "pid": 16, "name": "临沂", "pinyin": "Linyi"},
    {"id": 182, "pid": 16, "name": "德州", "pinyin": "Dezhou"},
    {"id": 183, "pid": 16, "name": "聊城", "pinyin": "Liaocheng"},
    {"id": 184, "pid": 16, "name": "滨州", "pinyin": "Binzhou"},
    {"id": 185, "pid": 16, "name": "菏泽", "pinyin": "Heze"},
    {"id": 186, "pid": 17, "name": "郑州", "pinyin": "Zhengzhou"},
    {"id": 187, "pid": 17, "name": "开封", "pinyin": "Kaifeng"},
    {"id": 188, "pid": 17, "name": "洛阳", "pinyin": "Luoyang"},
    {"id": 189, "pid": 17, "name": "平顶山", "pinyin": "Pingdingshan"},
    {"id": 190, "pid": 17, "name": "安阳", "pinyin": "Anyang"},
    {"id": 191, "pid": 17, "name": "鹤壁", "pinyin": "Hebi"},
    {"id": 192, "pid": 17, "name": "新乡", "pinyin": "Xinxiang"},
    {"id": 193, "pid": 17, "name": "焦作", "pinyin": "Jiaozuo"},
    {"id": 194, "pid": 17, "name": "济源", "pinyin": "Jiyuan"},
    {"id": 195, "pid": 17, "name": "濮阳", "pinyin": "Puyang"},
    {"id": 196, "pid": 17, "name": "许昌", "pinyin": "Xuchang"},
    {"id": 197, "pid": 17, "name": "漯河", "pinyin": "Leihe"},
    {"id": 198, "pid": 17, "name": "三门峡", "pinyin": "Sanmenxia"},
    {"id": 199, "pid": 17, "name": "南阳", "pinyin": "Nanyang"},
    {"id": 200, "pid": 17, "name": "商丘", "pinyin": "Shangqiu"},
    {"id": 201, "pid": 17, "name": "信阳", "pinyin": "Xinyang"},
    {"id": 202, "pid": 17, "name": "周口", "pinyin": "Zhoukou"},
    {"id": 203, "pid": 17, "name": "驻马店", "pinyin": "Zhumadian"},
    {"id": 204, "pid": 18, "name": "武汉", "pinyin": "Wuhan"},
    {"id": 205, "pid": 18, "name": "黄石", "pinyin": "Huangshi"},
    {"id": 206, "pid": 18, "name": "十堰", "pinyin": "Shiyan"},
    {"id": 207, "pid": 18, "name": "宜昌", "pinyin": "Yichang"},
    {"id": 208, "pid": 18, "name": "襄阳", "pinyin": "Xiangyang"},
    {"id": 209, "pid": 18, "name": "鄂州", "pinyin": "Ezhou"},
    {"id": 210, "pid": 18, "name": "荆门", "pinyin": "Jingmen"},
    {"id": 211, "pid": 18, "name": "孝感", "pinyin": "Xiaogan"},
    {"id": 212, "pid": 18, "name": "荆州", "pinyin": "Jingzhou"},
    {"id": 213, "pid": 18, "name": "黄冈", "pinyin": "Huanggang"},
    {"id": 214, "pid": 18, "name": "咸宁", "pinyin": "Xianning"},
    {"id": 215, "pid": 18, "name": "随州", "pinyin": "Suizhou"},
    {"id": 216, "pid": 18, "name": "恩施土家族苗族", "pinyin": "Enshitujiazumiaozu"},
    {"id": 217, "pid": 18, "name": "仙桃", "pinyin": "Xiantao"},
    {"id": 218, "pid": 18, "name": "潜江", "pinyin": "Qianjiang"},
    {"id": 219, "pid": 18, "name": "天门", "pinyin": "Tianmen"},
    {"id": 220, "pid": 18, "name": "神农架林区", "pinyin": "Shennongjialinqu"},
    {"id": 221, "pid": 19, "name": "长沙", "pinyin": "Changsha"},
    {"id": 222, "pid": 19, "name": "株洲", "pinyin": "Zhuzhou"},
    {"id": 223, "pid": 19, "name": "湘潭", "pinyin": "Xiangtan"},
    {"id": 224, "pid": 19, "name": "衡阳", "pinyin": "Hengyang"},
    {"id": 225, "pid": 19, "name": "邵阳", "pinyin": "Shaoyang"},
    {"id": 226, "pid": 19, "name": "岳阳", "pinyin": "Yueyang"},
    {"id": 227, "pid": 19, "name": "常德", "pinyin": "Changde"},
    {"id": 228, "pid": 19, "name": "张家界", "pinyin": "Zhangjiajie"},
    {"id": 229, "pid": 19, "name": "益阳", "pinyin": "Yiyang"},
    {"id": 230, "pid": 19, "name": "郴州", "pinyin": "Chenzhou"},
    {"id": 231, "pid": 19, "name": "永州", "pinyin": "Yongzhou"},
    {"id": 232, "pid": 19, "name": "怀化", "pinyin": "Huaihua"},
    {"id": 233, "pid": 19, "name": "娄底", "pinyin": "Loudi"},
    {"id": 234, "pid": 19, "name": "湘西土家族苗族", "pinyin": "Xiangxitujiazumiaozu"},
    {"id": 235, "pid": 20, "name": "广州", "pinyin": "Guangzhou"},
    {"id": 236, "pid": 20, "name": "韶关", "pinyin": "Shaoguan"},
    {"id": 237, "pid": 20, "name": "深圳", "pinyin": "Shenchou"},
    {"id": 238, "pid": 20, "name": "珠海", "pinyin": "Zhuhai"},
    {"id": 239, "pid": 20, "name": "汕头", "pinyin": "Shantou"},
    {"id": 240, "pid": 20, "name": "佛山", "pinyin": "Fushan"},
    {"id": 241, "pid": 20, "name": "江门", "pinyin": "Jiangmen"},
    {"id": 242, "pid": 20, "name": "湛江", "pinyin": "Zhanjiang"},
    {"id": 243, "pid": 20, "name": "茂名", "pinyin": "Maoming"},
    {"id": 244, "pid": 20, "name": "肇庆", "pinyin": "Zhaoqing"},
    {"id": 245, "pid": 20, "name": "惠州", "pinyin": "Huizhou"},
    {"id": 246, "pid": 20, "name": "梅州", "pinyin": "Meizhou"},
    {"id": 247, "pid": 20, "name": "汕尾", "pinyin": "Shanwei"},
    {"id": 248, "pid": 20, "name": "河源", "pinyin": "Heyuan"},
    {"id": 249, "pid": 20, "name": "阳江", "pinyin": "Yangjiang"},
    {"id": 250, "pid": 20, "name": "清远", "pinyin": "Qingyuan"},
    {"id": 251, "pid": 20, "name": "东莞", "pinyin": "Dongguan"},
    {"id": 252, "pid": 20, "name": "中山", "pinyin": "Zhongshan"},
    {"id": 253, "pid": 20, "name": "东沙群岛", "pinyin": "Dongshaqundao"},
    {"id": 254, "pid": 20, "name": "潮州", "pinyin": "Chaozhou"},
    {"id": 255, "pid": 20, "name": "揭阳", "pinyin": "Jieyang"},
    {"id": 256, "pid": 20, "name": "云浮", "pinyin": "Yunfu"},
    {"id": 257, "pid": 21, "name": "南宁", "pinyin": "Nanning"},
    {"id": 258, "pid": 21, "name": "柳州", "pinyin": "Liuzhou"},
    {"id": 259, "pid": 21, "name": "桂林", "pinyin": "Guilin"},
    {"id": 260, "pid": 21, "name": "梧州", "pinyin": "Wuzhou"},
    {"id": 261, "pid": 21, "name": "北海", "pinyin": "Beihai"},
    {"id": 262, "pid": 21, "name": "防城港", "pinyin": "Fangchenggang"},
    {"id": 263, "pid": 21, "name": "钦州", "pinyin": "Qinzhou"},
    {"id": 264, "pid": 21, "name": "贵港", "pinyin": "Guigang"},
    {"id": 265, "pid": 21, "name": "玉林", "pinyin": "Yulin"},
    {"id": 266, "pid": 21, "name": "百色", "pinyin": "Baise"},
    {"id": 267, "pid": 21, "name": "贺州", "pinyin": "Hezhou"},
    {"id": 268, "pid": 21, "name": "河池", "pinyin": "Hechi"},
    {"id": 269, "pid": 21, "name": "来宾", "pinyin": "Laibin"},
    {"id": 270, "pid": 21, "name": "崇左", "pinyin": "Chongzuo"},
    {"id": 271, "pid": 22, "name": "海口", "pinyin": "Haikou"},
    {"id": 272, "pid": 22, "name": "三亚", "pinyin": "Sanya"},
    {"id": 273, "pid": 22, "name": "三沙", "pinyin": "Sansha"},
    {"id": 274, "pid": 22, "name": "五指山", "pinyin": "Wuzhishan"},
    {"id": 275, "pid": 22, "name": "琼海", "pinyin": "Qionghai"},
    {"id": 276, "pid": 22, "name": "儋州", "pinyin": "Danzhou"},
    {"id": 277, "pid": 22, "name": "文昌", "pinyin": "Wenchang"},
    {"id": 278, "pid": 22, "name": "万宁", "pinyin": "Wanning"},
    {"id": 279, "pid": 22, "name": "东方", "pinyin": "Dongfang"},
    {"id": 280, "pid": 22, "name": "定安", "pinyin": "Dingan"},
    {"id": 281, "pid": 22, "name": "屯昌", "pinyin": "Tunchang"},
    {"id": 282, "pid": 22, "name": "澄迈", "pinyin": "Chengmai"},
    {"id": 283, "pid": 22, "name": "临高", "pinyin": "Lingao"},
    {"id": 284, "pid": 22, "name": "白沙黎族", "pinyin": "Baishalizu"},
    {"id": 285, "pid": 22, "name": "昌江黎族", "pinyin": "Changjianglizu"},
    {"id": 286, "pid": 22, "name": "乐东黎族", "pinyin": "Ledonglizu"},
    {"id": 287, "pid": 22, "name": "陵水黎族", "pinyin": "Lingshuilizu"},
    {"id": 288, "pid": 22, "name": "保亭黎族苗族", "pinyin": "Baotinglizumiaozu"},
    {"id": 289, "pid": 22, "name": "琼中黎族苗族", "pinyin": "Qiongzhonglizumiaozu"},
    {"id": 290, "pid": 23, "name": "重庆", "pinyin": "Zhongqing"},
    {"id": 291, "pid": 24, "name": "成都", "pinyin": "Chengdu"},
    {"id": 292, "pid": 24, "name": "自贡", "pinyin": "Zigong"},
    {"id": 293, "pid": 24, "name": "攀枝花", "pinyin": "Panzhihua"},
    {"id": 294, "pid": 24, "name": "泸州", "pinyin": "Luzhou"},
    {"id": 295, "pid": 24, "name": "德阳", "pinyin": "Deyang"},
    {"id": 296, "pid": 24, "name": "绵阳", "pinyin": "Mianyang"},
    {"id": 297, "pid": 24, "name": "广元", "pinyin": "Guangyuan"},
    {"id": 298, "pid": 24, "name": "遂宁", "pinyin": "Suining"},
    {"id": 299, "pid": 24, "name": "内江", "pinyin": "Najiang"},
    {"id": 300, "pid": 24, "name": "乐山", "pinyin": "Leshan"},
    {"id": 301, "pid": 24, "name": "南充", "pinyin": "Nanchong"},
    {"id": 302, "pid": 24, "name": "眉山", "pinyin": "Meishan"},
    {"id": 303, "pid": 24, "name": "宜宾", "pinyin": "Yibin"},
    {"id": 304, "pid": 24, "name": "广安", "pinyin": "Guangan"},
    {"id": 305, "pid": 24, "name": "达州", "pinyin": "Dazhou"},
    {"id": 306, "pid": 24, "name": "雅安", "pinyin": "Yaan"},
    {"id": 307, "pid": 24, "name": "巴中", "pinyin": "Bazhong"},
    {"id": 308, "pid": 24, "name": "资阳", "pinyin": "Ziyang"},
    {"id": 309, "pid": 24, "name": "阿坝藏族羌族", "pinyin": "Abacangzuqiangzu"},
    {"id": 310, "pid": 24, "name": "甘孜藏族", "pinyin": "Ganzicangzu"},
    {"id": 311, "pid": 24, "name": "凉山彝族", "pinyin": "Liangshanyizu"},
    {"id": 312, "pid": 25, "name": "贵阳", "pinyin": "Guiyang"},
    {"id": 313, "pid": 25, "name": "六盘水", "pinyin": "Liupanshui"},
    {"id": 314, "pid": 25, "name": "遵义", "pinyin": "Zunyi"},
    {"id": 315, "pid": 25, "name": "安顺", "pinyin": "Anshun"},
    {"id": 316, "pid": 25, "name": "铜仁", "pinyin": "Tongren"},
    {"id": 317, "pid": 25, "name": "黔西南布依族苗族", "pinyin": "Qianxinanbuyizumiaozu"},
    {"id": 318, "pid": 25, "name": "毕节", "pinyin": "Bijie"},
    {"id": 319, "pid": 25, "name": "黔东南苗族侗族", "pinyin": "Qiandongnanmiaozudongzu"},
    {"id": 320, "pid": 25, "name": "黔南布依族苗族", "pinyin": "Qiannanbuyizumiaozu"},
    {"id": 321, "pid": 26, "name": "昆明", "pinyin": "Kunming"},
    {"id": 322, "pid": 26, "name": "曲靖", "pinyin": "Qujing"},
    {"id": 323, "pid": 26, "name": "玉溪", "pinyin": "Yuxi"},
    {"id": 324, "pid": 26, "name": "保山", "pinyin": "Baoshan"},
    {"id": 325, "pid": 26, "name": "昭通", "pinyin": "Zhaotong"},
    {"id": 326, "pid": 26, "name": "丽江", "pinyin": "Lijiang"},
    {"id": 327, "pid": 26, "name": "普洱", "pinyin": "Puer"},
    {"id": 328, "pid": 26, "name": "临沧", "pinyin": "Lincang"},
    {"id": 329, "pid": 26, "name": "楚雄彝族", "pinyin": "Chuxiongyizu"},
    {"id": 330, "pid": 26, "name": "红河哈尼族彝族", "pinyin": "Honghehanizuyizu"},
    {"id": 331, "pid": 26, "name": "文山壮族苗族", "pinyin": "Wenshanzhuangzumiaozu"},
    {"id": 332, "pid": 26, "name": "西双版纳傣族", "pinyin": "Xishuangbannadaizu"},
    {"id": 333, "pid": 26, "name": "大理白族", "pinyin": "Dalibaizu"},
    {"id": 334, "pid": 26, "name": "德宏傣族景颇族", "pinyin": "Dehongdaizujingpozu"},
    {"id": 335, "pid": 26, "name": "怒江傈僳族", "pinyin": "Nujianglisuzu"},
    {"id": 336, "pid": 26, "name": "迪庆藏族", "pinyin": "Diqingcangzu"},
    {"id": 337, "pid": 27, "name": "拉萨", "pinyin": "Lasa"},
    {"id": 338, "pid": 27, "name": "昌都地区", "pinyin": "Changdudiqu"},
    {"id": 339, "pid": 27, "name": "山南地区", "pinyin": "Shannandiqu"},
    {"id": 340, "pid": 27, "name": "日喀则地区", "pinyin": "Rikazediqu"},
    {"id": 341, "pid": 27, "name": "那曲地区", "pinyin": "Naqudiqu"},
    {"id": 342, "pid": 27, "name": "阿里地区", "pinyin": "Alidiqu"},
    {"id": 343, "pid": 27, "name": "林芝地区", "pinyin": "Linzhidiqu"},
    {"id": 344, "pid": 28, "name": "西安", "pinyin": "Xian"},
    {"id": 345, "pid": 28, "name": "铜川", "pinyin": "Tongchuan"},
    {"id": 346, "pid": 28, "name": "宝鸡", "pinyin": "Baoji"},
    {"id": 347, "pid": 28, "name": "咸阳", "pinyin": "Xianyang"},
    {"id": 348, "pid": 28, "name": "渭南", "pinyin": "Weinan"},
    {"id": 349, "pid": 28, "name": "延安", "pinyin": "Yanan"},
    {"id": 350, "pid": 28, "name": "汉中", "pinyin": "Hanzhong"},
    {"id": 351, "pid": 28, "name": "榆林", "pinyin": "Yulin"},
    {"id": 352, "pid": 28, "name": "安康", "pinyin": "Ankang"},
    {"id": 353, "pid": 28, "name": "商洛", "pinyin": "Shangluo"},
    {"id": 354, "pid": 29, "name": "兰州", "pinyin": "Lanzhou"},
    {"id": 355, "pid": 29, "name": "嘉峪关", "pinyin": "Jiayuguan"},
    {"id": 356, "pid": 29, "name": "金昌", "pinyin": "Jinchang"},
    {"id": 357, "pid": 29, "name": "白银", "pinyin": "Baiyin"},
    {"id": 358, "pid": 29, "name": "天水", "pinyin": "Tianshui"},
    {"id": 359, "pid": 29, "name": "武威", "pinyin": "Wuwei"},
    {"id": 360, "pid": 29, "name": "张掖", "pinyin": "Zhangye"},
    {"id": 361, "pid": 29, "name": "平凉", "pinyin": "Pingliang"},
    {"id": 362, "pid": 29, "name": "酒泉", "pinyin": "Jiuquan"},
    {"id": 363, "pid": 29, "name": "庆阳", "pinyin": "Qingyang"},
    {"id": 364, "pid": 29, "name": "定西", "pinyin": "Dingxi"},
    {"id": 365, "pid": 29, "name": "陇南", "pinyin": "Longnan"},
    {"id": 366, "pid": 29, "name": "临夏回族", "pinyin": "Linxiahuizu"},
    {"id": 367, "pid": 29, "name": "甘南藏族", "pinyin": "Gannancangzu"},
    {"id": 368, "pid": 30, "name": "西宁", "pinyin": "Xining"},
    {"id": 369, "pid": 30, "name": "海东", "pinyin": "Haidong"},
    {"id": 370, "pid": 30, "name": "海北藏族", "pinyin": "Haibeicangzu"},
    {"id": 371, "pid": 30, "name": "黄南藏族", "pinyin": "Huangnancangzu"},
    {"id": 372, "pid": 30, "name": "海南藏族", "pinyin": "Hainancangzu"},
    {"id": 373, "pid": 30, "name": "果洛藏族", "pinyin": "Guoluocangzu"},
    {"id": 374, "pid": 30, "name": "玉树藏族", "pinyin": "Yushucangzu"},
    {"id": 375, "pid": 30, "name": "海西蒙古族藏族", "pinyin": "Haiximengguzucangzu"},
    {"id": 376, "pid": 31, "name": "银川", "pinyin": "Yinchuan"},
    {"id": 377, "pid": 31, "name": "石嘴山", "pinyin": "Shizuishan"},
    {"id": 378, "pid": 31, "name": "吴忠", "pinyin": "Wuzhong"},
    {"id": 379, "pid": 31, "name": "固原", "pinyin": "Guyuan"},
    {"id": 380, "pid": 31, "name": "中卫", "pinyin": "Zhongwei"},
    {"id": 381, "pid": 32, "name": "乌鲁木齐", "pinyin": "Wulumuqi"},
    {"id": 382, "pid": 32, "name": "克拉玛依", "pinyin": "Kelamayi"},
    {"id": 383, "pid": 32, "name": "吐鲁番地区", "pinyin": "Tulufandiqu"},
    {"id": 384, "pid": 32, "name": "哈密地区", "pinyin": "Hamidiqu"},
    {"id": 385, "pid": 32, "name": "昌吉回族", "pinyin": "Changjihuizu"},
    {"id": 386, "pid": 32, "name": "博尔塔拉蒙古", "pinyin": "Boertalamenggu"},
    {"id": 387, "pid": 32, "name": "巴音郭楞蒙古", "pinyin": "Bayinguolengmenggu"},
    {"id": 388, "pid": 32, "name": "阿克苏地区", "pinyin": "Akesudiqu"},
    {"id": 389, "pid": 32, "name": "克孜勒苏柯尔克孜", "pinyin": "Kezilesukeerkezi"},
    {"id": 390, "pid": 32, "name": "喀什地区", "pinyin": "Kashidiqu"},
    {"id": 391, "pid": 32, "name": "和田地区", "pinyin": "Hetiandiqu"},
    {"id": 392, "pid": 32, "name": "伊犁哈萨克", "pinyin": "Yilihasake"},
    {"id": 393, "pid": 32, "name": "塔城地区", "pinyin": "Tachengdiqu"},
    {"id": 394, "pid": 32, "name": "阿勒泰地区", "pinyin": "Aletaidiqu"},
    {"id": 395, "pid": 32, "name": "石河子", "pinyin": "Shihezi"},
    {"id": 396, "pid": 32, "name": "阿拉尔", "pinyin": "Alaer"},
    {"id": 397, "pid": 32, "name": "图木舒克", "pinyin": "Tumushuke"},
    {"id": 398, "pid": 32, "name": "五家渠", "pinyin": "Wujiaqu"},
    {"id": 399, "pid": 33, "name": "台北", "pinyin": "Taibei"},
    {"id": 400, "pid": 33, "name": "高雄", "pinyin": "Gaoxiong"},
    {"id": 401, "pid": 33, "name": "台南", "pinyin": "Tainan"},
    {"id": 402, "pid": 33, "name": "台中", "pinyin": "Taizhong"},
    {"id": 403, "pid": 33, "name": "金门", "pinyin": "Jinmen"},
    {"id": 404, "pid": 33, "name": "南投", "pinyin": "Nantou"},
    {"id": 405, "pid": 33, "name": "基隆", "pinyin": "Jilong"},
    {"id": 406, "pid": 33, "name": "新竹", "pinyin": "Xinzhu"},
    {"id": 407, "pid": 33, "name": "嘉义", "pinyin": "Jiayi"},
    {"id": 408, "pid": 33, "name": "新北", "pinyin": "Xinbei"},
    {"id": 409, "pid": 33, "name": "宜兰", "pinyin": "Yilan"},
    {"id": 410, "pid": 33, "name": "新竹", "pinyin": "Xinzhu"},
    {"id": 411, "pid": 33, "name": "桃园", "pinyin": "Taoyuan"},
    {"id": 412, "pid": 33, "name": "苗栗", "pinyin": "Miaoli"},
    {"id": 413, "pid": 33, "name": "彰化", "pinyin": "Zhanghua"},
    {"id": 414, "pid": 33, "name": "嘉义", "pinyin": "Jiayi"},
    {"id": 415, "pid": 33, "name": "云林", "pinyin": "Yunlin"},
    {"id": 416, "pid": 33, "name": "屏东", "pinyin": "Pingdong"},
    {"id": 417, "pid": 33, "name": "台东", "pinyin": "Taidong"},
    {"id": 418, "pid": 33, "name": "花莲", "pinyin": "Hualian"},
    {"id": 419, "pid": 33, "name": "澎湖", "pinyin": "Penghu"},
    {"id": 420, "pid": 33, "name": "连江", "pinyin": "Lianjiang"},
    {"id": 421, "pid": 34, "name": "香港岛", "pinyin": "Xianggangdao"},
    {"id": 422, "pid": 34, "name": "九龙", "pinyin": "Jiulong"},
    {"id": 423, "pid": 34, "name": "新界", "pinyin": "Xinjie"},
    {"id": 424, "pid": 35, "name": "澳门半岛", "pinyin": "Aomenbandao"},
    {"id": 425, "pid": 35, "name": "离岛", "pinyin": "Lidao"}];