import React, { Component } from 'react'
import MyNavBar from '../../../components/myNavBar/index'
import topsjs from '../../../image/topsjs.png'
import bg_engineer from '../../../image/bg_engineer.png'
import liuc from '../../../image/liuc.png'
import bg_manager from '../../../image/bg_manager.png'
import bg_CS from '../../../image/bg_CS.png'
import bg_desinger from '../../../image/bg_desinger.png'
import phone from '../../../image/phone.png'
import '../../../css/projectTeam.css'
import DesignerPopup from '../../../components/designerPopup/index'
import { Toast } from 'antd-mobile';
import { GetQuery, GetTel, GetAddr } from "../../../utils/index";

export default class projectTeam extends Component {
    state = {
        designVisible: false,
        page: 1,
        len: 10,
        data: []
    }
    componentDidMount() {
        this.getData()
        //装修保障tdk内容
        var meta = document.getElementsByTagName('meta')
        document.title = '装修保障_装修施工质量保障_装修材料质量保障-居然装饰'
        addMate('a', 'title', '装修保障_装修施工质量保障_装修材料质量保障-居然装饰')
        addMate(
            'b',
            'keywords',
            '装修保障,装修施工质量保障'
        )
        addMate(
            'c',
            'description',
            '居然装饰装修保障，让装修无后顾之后，装修保障让家装和家居服务快乐简单；装修质量保证措施工程质量管理最终反映工程设计效果和工程质量等级的重要环节，选居然装饰，有保障。'
        )

        function addMate(id, name, content) {
            let metaData = document.getElementsByTagName('head')[0].children
            for (let i = 0; i < metaData.length; i++) {
                if (metaData[i].localName === 'meta' && metaData[i].id === id) {
                    const mate = document.getElementById(id)
                    document.getElementsByTagName('head')[0].removeChild(mate)
                }
            }
            let meta = document.createElement('meta')
            meta.content = content
            meta.name = name
            meta.id = id
            document.getElementsByTagName('head')[0].appendChild(meta)
        }
    }
    getData() {
        const { data, page } = this.state
        this.get(`${this.api.getDesigner}?page=${this.state.page}&limit=${10}&${GetQuery()}`, {}).then((res) => {
            if (res.code === 0) {
                this.setState({
                    len: res.data.length,
                    data: page === 1 ? res.data : data.concat(res.data),
                });
            }
        });
    }
    saveInfo(obj) {
        let source = '预约我的私人设计师'
        let sourceUrl = escape(document.location.href);//'page/projectTeam'
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    designVisible: false
                })
                Toast.success('已提交信息', 1);
            }
        })
    }
    //底部栏
    StoreFooter() {
        return (
            <div className='StoreFooter'>
                <div className='consulting'>
                    <span>装修咨询</span>
                    <a href={`tel:${GetTel()}`} className='phoneNum'>{GetTel()}</a>
                    <img className='phone_icon' src={phone} alt='' />
                </div>
                <div className='address'>
                    总部地址：{GetAddr()}
                </div>
            </div>
        )
    }
    render() {
        let { designVisible } = this.state
        return (
            <div className="container">
                <MyNavBar />
                <div className="container_titles">
                    <img className='container_titles' src={topsjs} alt='' />
                </div>
                <div className="TeamRoles">
                    <div className="TeamRoles_text">团队角色</div>
                    <div className="TeamRoles_roles"> <img className='TeamRoles_roles' src={bg_engineer} alt='' /></div>
                </div>
                <div className="container_content_">
                    <div className="customer_content">
                        <div className="customer">
                            <div className="customer_top">
                                <div className="customer_top_img">
                                    <img className='customer_top_img' src={bg_manager} alt='' />
                                </div>
                                <div className="customer_top_name">客户经理</div>
                            </div>
                            <div className="customer_text">
                                1对1的客户经理咨询，深入了解客户的需求没客户精准匹配设计师，并对客户装修需求做细化分解，客户对装修的一切疑问由商务经理一一答疑。
                            </div>
                        </div>
                        <div className="customer">
                            <div className="customer_top">
                                <div className="customer_top_img">
                                    <img className='customer_top_img' src={bg_desinger} alt='' />
                                </div>
                                <div className="customer_top_name">主设计师</div>
                            </div>
                            <div className="customer_text">
                                1对1的客户经理咨询，深入了解客户的需求没客户精准匹配设计师，并对客户装修需求做细化分解，客户对装修的一切疑问由商务经理一一答疑。
                            </div>
                        </div>
                        <div className="customer">
                            <div className="customer_top">
                                <div className="customer_top_img">
                                    <img className='customer_top_img' src={bg_desinger} alt='' />
                                </div>
                                <div className="customer_top_name">主创设计师</div>
                            </div>
                            <div className="customer_text">
                                专业设计师为客户提供完整的家庭装修设计方案，对设计方案在施现场实时进行指导，确保工程施工能够更好的呈现设计效果。
                            </div>
                        </div>
                        <div className="customer">
                            <div className="customer_top">
                                <div className="customer_top_img">
                                    <img className='customer_top_img' src={bg_CS} alt='' />
                                </div>
                                <div className="customer_top_name">主创设计师</div>
                            </div>
                            <div className="customer_text">
                                专业设计师为客户提供完整的家庭装修设计方案，对设计方案在施现场实时进行指导，确保工程施工能够更好的呈现设计效果。
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ServiceProcess"><img className='ServiceProcess' src={liuc} alt='' /></div>
                <div className="AppointmentDesigner">
                    <div className="AppointmentDesigner_s">预约设计师</div>
                    <div className="AppointmentDesigner_content">
                        { 
                            this.state.data.map(i => <a href={"/designerInfo/" + i.id}>
                                 <div className="designer">
                                <img className='designer' src={i.headImgUrl} alt='' />
                                <div className="designer_">
                                    <div className="designer_name">{i.name}</div>
                                    <div className="designer_jy">
                                        <div className="designer_jy_">
                                            <div>从业经验:</div>
                                            <div>{i.jobYear}年</div>
                                        </div>
                                        <div className="designer_jy_sj">{i.leaver}</div>
                                    </div>
                                </div>
                            </div>
                            </a>
                            )
                        }
                 
                    </div>
                    <div className="Mydesigner" onClick={() => { this.setState({ designVisible: true }) }}>
                        预约我的私人设计师
                    </div>
                </div>
                <div className="StoreFooter">
                    {this.StoreFooter()}
                </div>
                <DesignerPopup
                    visible={designVisible}
                    title='定制私人装修方案'
                    subTitle='在线预约 尊享优先排期特权'
                    bottomdata={['免费量房', '户型解析', '软装咨询']}
                    cancelClick={() => this.setState({ designVisible: false })}
                    saveInfo={this.saveInfo.bind(this)}
                    btnTxt={['立即预约']}
                />
            </div>
        )
    }
}
