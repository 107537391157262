import React, {Component} from 'react'
import '../../css/found.css'
import '../../css/found.scss'
import StorageDialog from '../../components/storageDialog/index' //仓储弹框
import DesignerPopup from '../../components/designerPopup/index' //仓储弹框
import pinpai from '../../image/pinpai.png'
import shouyexiugai from '../../image/shouyexiugai.png'
import xianhangpeifu from '../../image/xianhangpeifu.png'
import rating from '../../image/lw/rating.png'
import xin from '../../image/xin.png'
import naozhong from '../../image/naozhong.png'
import miaosha_one from '../../image/miaosha_one.png'
import miaosha_two from '../../image/miaosha_two.png'
import jr_bg from '../../image/jr_bg.png'
import ms from '../../image/ms.png'
import zxlc from '../../image/zxlc.png'
import advantage from '../../image/advantage.png'
import znapp from '../../image/znapp.png'
import zx from '../../image/zx.png'
import jzz from '../../image/jzz.png'
import yxdp from '../../image/yxdp.png'
import vip from '../../image/vip.png'
import peifu from '../../image/peifu.png'
import jr_logo from '../../image/jr_logo.png'
import dizhi from '../../image/dizhi.png'
import phone from '../../image/phone.png'
import liulian from '../../image/liulan.png'
import seenEye from '../../image/seenEye.png'
import {SearchOutlined} from '@ant-design/icons'
import {Carousel, Input, Tabs} from 'antd'
import 'antd/dist/antd.css'
import moment from 'moment'
import Hb from '../../image/video/Hb.png'
import xiaolaba from '../../image/xiaolaba.png'
import {
    decorationProcess,
    getSwiperList,
    tabfindAll,
    websiteOwer,
    websitePosterChoose,
    websiteProduceCategory
} from '../../request/index'
import {Toast} from 'antd-mobile'
import TextLoop from 'react-text-loop'
import Countdown from 'react-countdown'
import {editUrl, GetQuery, GetTel, locationHref} from "../../utils/index"; //路由配置

const renderer2 = ({hours, minutes, seconds}) => {
    return (
        <span className='_time'>
      <span>{hours}</span>
      <p>:</p>
      <span>{minutes}</span>
      <p>:</p>
      <span>{seconds}</span>
    </span>
    )
}

const TabPane = Tabs.TabPane

export default class Found extends Component {
    state = {
        id: 1,
        tabList: [],
        arr: [],
        guanggao_img: [],
        caseListData: [],
        dotPosition: 'top',
        ownersVoiceList: [],
        imgAll: '',
        imgList: [],
        poster: [],
        poster1: {},
        poster2: {},
        poster3: [],
        poster1Me: '',
        poster2Me: '',
        poster3Me: '',
        poster3Me1: '',
        roomVisible: false,
        storageVisible: false,
        designVisible: false,
        imgn1: '',
        imgn2: '',
        imgn3: '',
        imgn4: '',
        imgn5: '',
        imgn6: '',
        tab1: 1,
        flag: false,
        currentIndex: 0,
        WorkSiteListData: [],
        ownersPennantList: [],
        getwebsiteTypeList: [],
        activeId: [],
        websiteDataList: [],
        info: '',
        filterQuestionList: [],
        cateList: [
            {label: '乐屋个性化', link: '/lewu', dis: true},
            {label: '顶层别墅', link: '/designTop', dis: true},
            {label: '快屋套餐', link: '/kuaiwu/1', dis: true},
            {label: '局装局改', link: '/remodelPartial', dis: true},
            {label: '章鱼买手', link: '/packageMainMaterial', dis: true}
        ],
        websitePosterChooseList: [],
        questionValue: '',
        questionVisible: false,
        priceVisible: false,
        name: '',
        area: '',
        tel: '',
        telShow: false
    }

    //走马灯工地直播
    renderTop() {
        return (
            <div className='renderTops'>
                <div className='renderTops_'>
                    <img className='laba' src={xiaolaba} alt=''/>
                </div>
                <TextLoop
                    springConfig={{stiffness: 340, damping: 30}}
                    mask={true}
                    fade={true}
                    adjustingSpeed={150}
                    delay={2000}
                    interval={[2000, 2000, 2000]}
                >
                    {
                        this.state.ownersPennantList.map(i => <span>{i.title} 送来了锦旗</span>)
                    }

                </TextLoop>
            </div>
        )
    }

    componentWillMount() {
        this._init2()
    }

    ownersPennant() {
        this.get(`${this.api.ownersPennant}?page=${1}&limit=${10}&${GetQuery()}`, '').then(
            (res) => {
                this.setState({
                    ownersPennantList: res.data,
                }, () => {
                })
            }
        )
    }

    //获取产品列表
    getwebsiteProduceCategory() {
        websiteProduceCategory().then((res) => {
            let arr = res.data.data ? res.data.data.map(i => {
                i.label = i.titleOne

                //判断是否跳转地址
                if (i.jumpType == 1){
                    i.link = i.jumpUrl
                    i.dis = false
                }else {
                    if (i.produceIds) {
                        i.produceIds = i.produceIds.split(',')
                        if (i.produceIds.length === 1) {
                            i.link = `/kuaiwu/${i.produceIds[0]}`
                            i.dis = false

                        } else if (i.produceIds.length > 1) {
                            i.link = `/remodelPartial/${i.id}`
                            i.dis = false
                        }
                    } else {
                        i.link = ''
                        i.dis = true
                    }
                }
                return i
            }) : []
            this.setState({
                cateList: arr
            })
        })
    }

    gettabfindAll() {
        tabfindAll().then((res) => {
            if (res.data.length > 4) {
                res.data.length = 4
            }
            this.setState({
                tabList: res.data
            })
        })
    }

    getwebsitePosterChoose() {
        websitePosterChoose().then((res) => {
            let poster3Message, poster3Message1
            if (res.data.data.length === 0) return;
            let data = res.data.data[0]
            let data1 = res.data.data.length > 1 ? res.data.data[1] : false
            if (data.imgType === 1) {
                poster3Message = (
                    <img className='zs899' src={data.imgUrl} alt={data.seotitle}
                         onClick={this.goOpenUrl.bind(this, data.imgUrl)}/>
                )
            } else if (data.imgType === 2) {
                poster3Message = (
                    <img
                        onClick={this.goOpenUrl.bind(this, data.openUrl)}
                        className='show_box'
                        src={data.imgUrl}
                        alt={data.seotitle}
                        onClick={this.goOpenUrl.bind(this, data.openUrl)}
                    />
                )
            } else if (data.imgType === 3) {
                poster3Message = (
                    <img
                        className='zs899'
                        src={data.imgUrl}
                        onClick={() => {
                            this.setState({designVisible: true})
                        }}
                        alt={data.seotitle}
                    />
                )
            }

            if (data1) {

                if (data1.imgType === 1) {
                    poster3Message1 = (
                        <img className='zs899' src={data1.imgUrl} alt={data1.seotitle}
                             onClick={this.goOpenUrl.bind(this, data1.openUrl)}/>
                    )
                } else if (data1.imgType === 2) {
                    poster3Message1 = (
                        <img
                            className='show_box'
                            src={data1.imgUrl}
                            alt={data1.seotitle}
                            onClick={this.goOpenUrl.bind(this, data1.openUrl)}
                        />
                    )
                } else if (data1.imgType === 3) {
                    poster3Message1 = (
                        <img
                            className='zs899'
                            src={data1.imgUrl}
                            onClick={() => {
                                this.setState({designVisible: true})
                            }}
                            alt={data1.seotitle}
                        />
                    )
                }
            }

            this.setState({
                poster3Me: poster3Message,
                poster3Me1: poster3Message1,
            })
        })
    }

    getwebsiteOwer() {
        websiteOwer().then((res) => {
            localStorage.setItem("addr", JSON.stringify(res.data.data))
        })
    }

    getwebsiteType(id) {
        this.get(`${this.api.getwebsiteType}?id=${id}&${GetQuery()}`, '').then((res) => {
            if (res.code === 0) {
                this.setState(
                    {
                        getwebsiteTypeList: res.data,
                        activeId: res.data.length > 0 ? res.data[0].id : null
                    },
                    () => {
                    }
                )
            }
        })
    }

    getwebsiteData(id) {
        this.get(
            `${this.api.websiteData}?typeId=${id}&page=${1}&limit=${2}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0 && res.data) {
                this.setState(
                    {
                        websiteDataList: res.data,
                        filterQuestionList: res.data,
                        infos: res.data.infos
                    },
                    () => {
                    }
                )
            }
        })
    }

    getdecorationProcess() {
        decorationProcess().then((res) => {
            this.setState({
                telList: res.data.data
            })
        })
    }

    setFlag = (id) => {
        this.setState(
            {
                activeId: id
            },
            () => {
                this.getwebsiteData(id)
                editUrl("/tab" + id)
            }
        )
    }

    callback = (key) => {
        this.setState(
            {
                id: key
            },
            () => {
                this.getwebsiteData(key)
                this.getwebsiteType(key)
                editUrl("/tab" + key)
            }
        )
    }

    componentDidMount() {
        this.state.timer = setInterval(() => {
            this.setState({
                price: Math.floor(Math.random() * (100000 - 10000)) + 10000
            })
        }, 100)

        this._init()
    }

    _init2() {
        this.getwebsiteType(1)
        this.getwebsiteData(1)
        this.gettabfindAll()
        this.getwebsiteOwer()
        this.getwebsiteProduceCategory()
        this.ownersPennant()
        this.getwebsitePosterChoose()
    }

    _init() {
        getSwiperList().then((res) => {
            res.data.data.sort((a, b) => {
                return a.carouselOrder - b.carouselOrder
            })
            this.setState({
                arr: res.data.data
            })
        })

        this.getPostImg()
        this.get(`${this.api.getDesignerCaseList}?page=${1}&limit=${3}&${GetQuery()}&isRecommend=1`, '').then(
            (res) => {
                if (res.data.code === 0) {
                    this.setState({
                        caseListData: res.data.data
                    })
                }
            }
        )
        this.get(`${this.api.getownersVoice}?page=${1}&limit=${2}&${GetQuery()}`, '').then(
            (res) => {
                this.setState({
                    ownersVoiceList: res.data
                })
                if (this.state.ownersVoiceList) {
                    this.state.ownersVoiceList.map((item, key) => {
                        this.setState(
                            {
                                imgList: item.imgUrl.split(',')
                            },
                            () => {
                            }
                        )
                    })
                }

            }
        )
        this.getWorkSiteList()
    }

    getPostImg() {
        this.get(`${this.api.getwebsitePoster}?type=0&${GetQuery()}`, '').then(
            (res) => {
                this.setState({
                    poster1: res.data[0],
                    poster2: res.data[1]
                })
            }
        )
    }

    getWorkSiteList() {
        this.get(`${this.api.getWorkSiteList}?page=${1}&limit=${10}&${GetQuery()}`, '').then(
            (res) => {
                if (res.code === 0) {
                    this.setState({
                        WorkSiteListData: res.data.data
                    })
                }
            }
        )
    }

    goQuestionDetail(id) {
        locationHref('/decorateGan/questionDetail/' + id)
    }

    video() {
        let videoplay = this.refs.videoPalse //视频
        let videoPalse = this.refs.videoplay //video播放标签
        if (videoplay.paused) {
            //点击视频的时候如果暂停状态，则让其播放
            videoplay.play()
            videoPalse.style.display = 'none'
        } else {
            videoplay.pause()
            videoPalse.style.display = 'block'
        }
    }

    questionSaveInfo(obj) {
        let {questionId} = this.state
        this.post(
            `${this.api.addReply}?questionId=${questionId}&reply=${obj.questin}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    questionVisible: false
                })
                Toast.success('已提交信息', 1)
            }
        })
    }

    designSaveInfo(obj) {
        let source = '免费定制设计方案'
        let sourceUrl = escape(window.parent.document.location);//'/home'
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    designVisible: false
                })
                Toast.success('已提交信息', 1)
            }
        })
    }

    roomSaveInfo(obj) {
        let source = '免费量房'
        let sourceUrl = escape(window.parent.document.location);//'/home'
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    roomVisible: false
                })
                Toast.success('已提交信息', 1)
            }
        })
    }

    storageSaveInfo(obj) {
        let source = '仓储服务'
        let sourceUrl = escape(window.parent.document.location);//'/found'
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    storageVisible: false
                })
                Toast.success('已提交信息', 1)
            }
        })
    }

    goConstructionSiteLive(id) {
        locationHref('/detailsConstructionSite/' + id)
    }

    goCaseDetail(id) {
        locationHref('/detailsCase/' + id)
    }

    //业主详情跳转
    goDetails(id) {
        locationHref('/detailsOwnersVoice/' + id)
    }

    goOpenUrl(link, e) {
        window.location.href = link
    }

    // 装修干货详情跳转
    goDecorateDetails(id) {
        locationHref('/decorateGan/decorateDetail/' + id)
    }

    //卸载倒计时
    componentWillUnmount() {
    }

    // 广告位
    // 针对不同的广告类型 跳转不同界面  1 静态图片 2 连接图片 3 打开弹框
    ggwFunction() {
        let {poster1, poster2} = this.state
        let poster1Message
        let poster2Message
        if (poster1 && Object.keys(poster1).length > 0) {
            if (poster1.imgType === 1) {
                poster1Message = (
                    <img
                        className='show_box'
                        src={poster1.imgUrl}
                        alt={poster1.seotitle}
                        onClick={this.goOpenUrl.bind(this, poster1.imgUrl)}
                    />
                )
            } else if (poster1.imgType === 2) {
                poster1Message = (
                    <div onClick={this.goOpenUrl.bind(this, poster1.openUrl)}>
                        <img
                            className='show_box'
                            src={poster1.imgUrl}
                            alt={poster1.seotitle}
                        />
                    </div>
                )
            } else if (poster1.imgType === 3) {
                poster1Message = (
                    <img
                        className='show_box'
                        src={poster1.imgUrl}
                        onClick={() => {
                            this.setState({designVisible: true})
                        }}
                        alt={poster1.seotitle}
                    />
                )
            }
        }
        if (poster2 && Object.keys(poster2).length > 0) {
            if (poster2.imgType === 1) {
                poster2Message = (
                    <img className='show_box'
                         onClick={this.goOpenUrl.bind(this, poster2.imgUrl)} src={poster2.imgUrl}
                         alt={poster2.seotitle}/>
                )
            } else if (poster2.imgType === 2) {
                poster2Message = (
                    <div onClick={this.goOpenUrl.bind(this, poster2.openUrl)}>
                        <img
                            className='show_box'
                            src={poster2.imgUrl}
                            alt={poster2.seotitle}
                            // onClick={this.goOpenUrl.bind(this, poster2.openUrl)}
                        />
                    </div>
                )
            } else if (poster2.imgType === 3) {
                poster2Message = (
                    <img
                        className='beijing2'
                        src={poster2.imgUrl}
                        onClick={() => {
                            this.setState({designVisible: true})
                        }}
                        alt={poster2.seotitle}
                    />
                )
            }
        }

        this.state.poster1Me = poster1Message
        this.state.poster2Me = poster2Message
    }

    tab1() {
        return (
            <div className='tabss1'>
                {this.state.getwebsiteTypeList.map((item, index) => {
                    return (
                        <div
                            id={this.state.activeId === item.id ? 'active' : 'tabss_t'}
                            onClick={() => this.setFlag(item.id)}
                            key={index}
                        >
                            {item.name}
                        </div>
                    )
                })}
            </div>
        )
    }

    tab1_() {
        return (
            <div className='DecorateContent_Wrap'>
                {this.state.websiteDataList.map((item, index) => {

                    return (
                        <div
                            className='DecorateContent_'
                            onClick={this.goDecorateDetails.bind(this, item.id)}
                            key={item.id}
                        >
                            <div className='DecorateContent'>
                                <div className='DecorateTitele'>{item.title}</div>
                                <div className='DecorateDates'>
                                    <div className='DecorateDatesTime'>{item.time}</div>
                                    <div className='DecorateDatesDate'>
                                        <img className='liulian' src={liulian} alt=''/>
                                        {this.million(item.counts)}
                                    </div>
                                </div>
                                <div className='DecorateTitele_'>{item.intro}</div>

                                {/* <div className="content" dangerouslySetInnerHTML={{__html:item.infos}}>
</div> */}
                            </div>
                            <div className='DecorateImg'>
                                <img
                                    className='DecorateImgs'
                                    src={item.imgUrl}
                                    key={index}
                                    alt=''
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    filterQuestion(type, e) {
        let {websiteDataList} = this.state
        this.setState({
            questionValue: e.target.value
        })
        let res = []
        websiteDataList.map((item) => {
            if (item.title)
                if (item.title.indexOf(e.target.value) !== -1) {
                    res.push(item)
                }
        })
        this.setState({
            filterQuestionList: res
        })
    }

    million(value) {
        //过万处理
        let num
        if (value > 9999) {
            //大于9999显示x.xx万
            num = Math.floor(value / 1000) / 10 + 'W'
        } else if (value < 9999 && value > -9999) {
            num = value
        } else if (value < -9999) {
            //小于-9999显示-x.xx万
            num = -(Math.floor(Math.abs(value) / 1000) / 10) + 'W'
        }
        return num
    }

    OpenUrls(openUrl) {
        window.location.href = `${openUrl}`
    }

    RenderQuestionList() {
        let {filterQuestionList} = this.state
        return filterQuestionList.map((item) => {
            return (
                <div className='questionListWrap' key={item.id}>
                    <p
                        className='questionList_title'
                        onClick={this.goQuestionDetail.bind(this, item.id)}
                    >
                        {item.title}
                    </p>
                    <div
                        className='questionList_desc_wrap'
                        onClick={this.goQuestionDetail.bind(this, item.id)}
                    >
                        {/* <span className='questionList_desc'>
              这里是问题描述这里是问题描述这里是问题描述这里是问题描述这里是问题描述这里是问题描述问题描述这里是问题描述这里是问问题描述这里是问题描述这里是问这里是问题描述…{' '}
            </span> <span className='questionList_desc_more'> 更多</span>*/}
                    </div>
                    <div className='questionList_Bottom'>
            <span
                className='question_time'
                onClick={this.goQuestionDetail.bind(this, item.id)}
            >
              {item.time}
            </span>
                        <span
                            className='question_number'
                            onClick={this.goQuestionDetail.bind(this, item.id)}
                        >
              已有
              <span style={{color: '#0166B3'}}>
                {this.million(item.questionCounts)}
              </span>
              个回答
            </span>
                        <div
                            className='question_view'
                            onClick={this.goQuestionDetail.bind(this, item.id)}
                        >
                            <img className='view_number' src={seenEye} alt=''/>
                            {this.million(item.counts)}
                        </div>
                        <div
                            className='want_answer_btn'
                            onClick={() => {
                                this.setState({questionVisible: true, questionId: item.id})
                            }}
                        >
                            我要解答
                        </div>
                    </div>
                </div>
            )
        })
    }

    RenderQuestion() {
        return (
            <div className='question_answer'>
                <div className='question_answer_header'>
                    <Input
                        className='question_answer_search'
                        placeholder='请输入您的装修困惑'
                        prefix={<SearchOutlined/>}
                        style={{margin: '0 auto'}}
                        onChange={this.filterQuestion.bind(this, 1)}
                        value={this.state.questionValue}
                    />
                </div>
                <div className='question_answer_tags'>
                    {this.state.getwebsiteTypeList.map((item, index) => {
                        return (
                            <div
                                id={this.state.activeId === item.id ? 'active' : 'tabss_t'}
                                onClick={() => this.setFlag(item.id)}
                                key={index}
                            >
                                {item.name}
                            </div>
                        )
                    })}
                </div>
                <div className=''>{this.RenderQuestionList()}</div>
            </div>
        )
    }

    priceSaveInfo() {
        let {name, area, tel} = this.state
        if (name === '') {
            Toast.info('姓名不能为空', 1)
        } else if (area === '') {
            Toast.info('房屋面积不能为空', 1)
        } else if (tel === '') {
            Toast.info('手机号码不能为空', 1)
        } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(tel)) {
            Toast.info('手机号码格式不正确~', 1)
        } else {
            let source = '获取报价'
            let sourceUrl = '/kuaiwu'
            this.post(
                `${this.api.getCustomerCollect}?username=${name}&phone=${tel}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
                ''
            ).then((res) => {
                if (res.code === 0) {
                    this.setState({
                        name: '',
                        area: '',
                        tel: '',
                    })
                    Toast.success('已提交信息', 1)
                }
            })
        }
    }

    //装修干货
    renderDecorate() {
        // let {getwebsiteTypeList}=this.state
        return (
            <div className='DecorateDrycargo_tab'>
                <Tabs
                    defaultActiveKey='1'
                    className='tabs_'
                    onChange={(key) => this.callback(key)}
                >
                    <TabPane tab='装修攻略' key='1'>
                        {this.tab1()}
                        {this.tab1_()}
                    </TabPane>
                    <TabPane tab='装修问答' key='2'>
                        {this.RenderQuestion()}
                    </TabPane>
                    <TabPane tab='装修百科' key='3'>
                        {this.tab1()}
                        {this.tab1_()}
                    </TabPane>
                    <TabPane tab='装修日记' key='4'>
                        {this.tab1()}
                        {this.tab1_()}
                    </TabPane>
                </Tabs>
            </div>
        )
    }

    inputChange(type, e) {
      if (type === 1) {
        this.setState({
          name: e.target.value
        })
      } else if (type === 2) {
        this.setState({
          area: e.target.value
        })
      } else if (type === 3) {
        this.setState({
          tel: e.target.value
        })
      }
    }
    
    renderQuotation() {
        let {price}=this.state
        return (
            <div className='Quotation_form'>
                <h2 className="price">
                    <span className="price_num">{price}</span>
                </h2>
                <div className='Quotation_form_row'>
                    <Input
                        ref={(myInput) => (this.myInput = myInput)}
                        placeholder='请输入您的姓名'
                        onChange={this.inputChange.bind(this, 1)}
                        value={this.state.name}
                    />
                    <Input
                        ref={(myInput) => (this.myInput = myInput)}
                        placeholder='请输入房屋面积'
                        value={this.state.area}
                        type='number'
                        onChange={this.inputChange.bind(this, 2)}
                    />
                </div>
                <Input
                    ref={(myInput) => (this.myInput = myInput)}
                    className='Quotation_form_input_phone'
                    placeholder='请输入您的手机号'
                    value={this.state.tel}
                    type='number'
                    onChange={this.inputChange.bind(this, 3)}
                />
            </div>
        )
    }

    RenderCase() {
        let {caseListData} = this.state
        if (caseListData !== undefined) {
            return (
                <Carousel
                    // autoplay
                    dontAnimate={true}
                    style={{margin: '0 .32rem'}}
                    easing={false}
                    speed={500}
                    className='caseCarousel'
                    dots={false}
                >
                    {caseListData.map((item, key) => {
                        return (
                            <div className='caseBox' key={key}>
                                <img
                                    className='casebg'
                                    src={item.cover}
                                    alt={item.seotitle}
                                    onClick={this.goCaseDetail.bind(this, item.id)}
                                />
                                <div
                                    className='caseInfo'
                                    onClick={this.goCaseDetail.bind(this, item.id)}
                                >
                                    <div className='simpleInfo'>
                                        <div className='infoLeft'>
                                            <span className='designidentity'>设计师</span>
                                            <span className='designname'>{item.name}</span>
                                        </div>
                                        <img className='headportrait' src={item.icon} alt=''/>
                                    </div>
                                    <div className='designdesc'>
                                        <span>{item.title}</span>
                                        <span>本案户型：{item.houseType}</span>
                                        <span>
                      户型面积：{item.acreage}m<sup>²</sup>
                    </span>
                                        <span>设计风格：{item.styleName}</span>
                                        <div className='rating'>
                                            <span>客户评分：</span>
                                            <img src={rating} className='ratingimg' alt=''/>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='FreeCustomizedDesign'
                                    onClick={() => {
                                        this.setState({designVisible: true})
                                    }}
                                >
                                    免费定制设计方案
                                </div>
                            </div>
                        )
                    })}
                </Carousel>
            )
        }
    }

    goWhere = (link, e) => {
        if (e.dis) {
            Toast.info('暂未开放')
            return
        }
        localStorage.setItem('alabels', e.label);

        // locationHref(link)
        window.location.href = link
        this.setState({sheetVisible: false})
    }

    render() {
        let {
            arr,
            roomVisible,
            storageVisible,
            ownersVoiceList,
            designVisible,
            poster1Me,
            poster2Me,
            poster3Me1,
            poster3Me,
            questionVisible,
            priceVisible,
            cateList
        } = this.state
        this.ggwFunction()
        return (
            <div className='found'>
                <div></div>
                {(arr && arr.length > 0) &&

                <div className='found_swiper'>
                    <Carousel dontAnimate={true} easing={false} speed={500}>
                        {
                            arr.map((item, key) => {
                                return (
                                    <div key={key} className='swper'>
                                        <div onClick={this.OpenUrls.bind(this, item.openUrl)} style={{
                                            backgroundImage: `url(${item.imgUrl})`,
                                            width: '100%',
                                            height: '5.5rem'
                                        }}>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </div>
                }

                {
                    this.state.tabList.length > 0 && [
                        <div className='found_showbox'>
              <span>
                <img className='pinpai' src={pinpai} alt=''/>
                <span>居然之家自营家装</span>
              </span>
                            <span>
                <img className='shouyexiugai' src={shouyexiugai} alt=''/>
                <span>装修一口价0增项</span>
              </span>
                            <span>
                <img className='xianhangpeifu' src={xianhangpeifu} alt=''/>
                <span>居然承诺先行赔付</span>
              </span>
                        </div>,


                        <div className='found_showFour'>

                            {
                                this.state.tabList.map(i => {
                                    if (i.openType === 1) {

                                        return <div
                                            onClick={this.goOpenUrl.bind(this, i.openUrl)}
                                            className='main_fourBox'
                                            // onClick={() => this.setState({ priceVisible: true })}
                                        >
                                            <img className='fourbox_img' src={i.imgUrl} alt={i.seotitle}/>
                                            <span>{i.name}</span>
                                        </div>
                                    }
                                    if (i.openType === 2) {
                                        return <div
                                            className='main_fourBox'
                                            onClick={() => this.setState({roomVisible: true})}
                                        >
                                            <img className='fourbox_img' src={i.imgUrl} alt={i.seotitle}/>
                                            <span type='primary'>{i.name}</span>
                                        </div>
                                    }
                                    if (i.openType === 3) {
                                        return <div
                                            className='main_fourBox'
                                            onClick={() => this.setState({priceVisible: true})}
                                        >
                                            <img className='fourbox_img' src={i.imgUrl} alt={i.seotitle}/>
                                            <span>{i.name}</span>
                                        </div>
                                    }
                                })
                            }
                        </div>
                    ]
                }
                
                <div className='Special_show'>{poster1Me}</div>
                <div className='Quotation'>
                    <h1 className='title'>10秒获取我家装修报价</h1>
                    <span className='sub_title'>已经有
                        <span className='sub_title_num'>
                            {5368}
                        </span>人选择了居然装饰
                    </span>
                    {this.renderQuotation()}
                    <div
                        className='Quotation_form_button'
                        id='button_'
                        onClick={this.priceSaveInfo.bind(this)}
                    >
                    一键获取免费报价
                    </div>
                    <span className='Quotation_footer'>*您的信息将被严格保密，请放心准确填写</span>
                    <hr/>
                </div>
                {/* <div className='Seconds_killToday'>
                    <div className='Seconds_killToday_mao'>
                        <span className='_miaosha'>
                            <img className='miaosha' src={ms} alt=''/>
                        </span>
                        <span className='Seconds_killToday_mao_time'>
                            <img className='naozhong' src={naozhong} alt=''/>
                            <span className='over'>距离结束</span>
                            <Countdown date={Date.now() + 200000000} renderer={renderer2}/>
                        </span>
                    </div>
                    <div className='Seconds_killToday_kk'>
                        <div
                            className='Seconds_killToday_kk_one'
                            type='primary'
                            //   onClick={() => this.setState({ visible: true })}
                            onClick={() => this.setState({roomVisible: true})}
                        >
                            <img className='miaosha_one' src={miaosha_one} alt=''/>
                        </div>
                        <div
                            className='Seconds_killToday_kk_two'
                            type='primary'
                            //   onClick={() => this.setState({ visible: true })}
                            onClick={() => this.setState({storageVisible: true})}
                        >
                            <img className='miaosha_two' src={miaosha_two} alt=''/>
                        </div>
                    </div>
                </div> */}
                {
                    cateList.length !== 0 && <div className='Our_Services'>
                        <div className='Our_Services-title'>
                            <span className='title_one'>我们的服务</span>
                            <span className='title_two'>选择适合您的家装产品</span>
                        </div>
                        <div className='Our_Services_content'>
                            <div className='Our_Services_content_top'>
                                {
                                    cateList.map((i, index) => index < 2 &&
                                        <div onClick={() => this.goWhere(i.link, i)}>
                                            <div className={`zx${index + 1}`}
                                                 style={{backgroundImage: `url(${i.imgUrl})`}}>
                                                <h3>{i.titleOne}</h3>
                                                <h5>{i.titleTwo}</h5>
                                                <div>{i.titleThree}</div>
                                            </div>
                                        </div>)
                                }

                            </div>
                            <div className='Our_Services_content_bto'>
                                {
                                    cateList.map((i, index) => index > 1 &&
                                        <div onClick={() => this.goWhere(i.link, i)}>
                                            <div className={`zx${index + 1}`}
                                                 style={{backgroundImage: `url(${i.imgUrl})`}}>
                                                <h3>{i.titleOne}</h3>
                                                <h5>{i.titleTwo}</h5>
                                                <div>{i.titleThree}</div>
                                            </div>
                                        </div>)
                                }

                            </div>
                        </div>
                    </div>
                }

                <div className='Special_show'>{poster2Me}</div>
                <div id='content_video'>
                    <video
                        poster={Hb}
                        ref='videoPalse'
                        className='video'
                        controls='controls'
                    >
                        <source id='video'
                                src={'http://shigongapp.oss-cn-beijing.aliyuncs.com/appPack/202107071418.mp4'}></source>
                    </video>
                    <div className='Heart_selectedList_content'>{poster3Me1}</div>
                    <div className='video-img' onClick={() => {
                        this.video()
                    }}>
                        {/* <img
              id='play'
              ref='videoplay'
              onClick={() => {
                this.video()
              }}
              src={bofang}
              alt=''
            /> */}
                    </div>
                </div>

                {/* <div className='Actually_show'>
                    <img className='Actually_show_beijing1' src={jr_bg}/>
                    <div className='Actually_show_title'>为什么大家都选居然装饰</div>
                    <div className='Actually_show_top'>
                        <div className='Actually_show_top_left' style={{borderTop: 0}}>
                            <a href='/aboutJuran' className='jr_log'>
                                <img className='jr_log' src={jr_logo} alt=''/>
                            </a>
                            <span className='jrjzz'>居然之家自营家装</span>
                            <span className='Actually_show_text'>A股上市公司</span>
                        </div>
                        <div className='Actually_show_top_right' style={{borderTop: 0}}>
                            <a href='/aboutJuran' className='Actually_show_top_right_top'>
                                <div className='Actually_show_top_right_top_left'>
                                    <span className='jzjy'>16年家装经验</span>
                                    <span className='jzjyu'>老工长,更靠谱 </span>
                                </div>
                                <div className='Actually_show_top_right_top_right'>
                                    <img className='jzz' src={jzz} alt=''/>
                                </div>
                            </a>
                            <a
                                href='/promiseJuran'
                                className='Actually_show_top_right_top'
                                style={{borderBottom: 0}}
                            >
                                <div className='Actually_show_top_right_top_left'>
                                    <span className='jzjy'>一口价零增项</span>
                                    <span className='jzjyu'>预交底=签约价=结算价</span>
                                </div>
                                <div className='Actually_show_top_right_top_right'>
                                    <img className='jzz' src={zx} alt=''/>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='Actually_show_content'>
                        <a
                            href='/buildingMaterialsSelecting'
                            className='Actually_show_content_left'
                        >
                            <div className='Actually_show_content_right_top_left'>
                                <span className='jzjy'>一线大牌建材</span>
                                <span className='jzjyu'>来自居然之家卖场热销款</span>
                            </div>
                            <div className='Actually_show_content_right_top_right'>
                                <img className='jzz' src={yxdp} alt=''/>
                            </div>
                        </a>
                        <a
                            href='/promiseJuran'
                            className='Actually_show_content_left'
                            style={{borderLeft: 0}}
                        >
                            <div className='Actually_show_content_right_top_left'>
                                <span className='jzjy'>居然先行赔付</span>
                                <span className='jzjyu'>率先倡导者 坚实践行者</span>
                            </div>
                            <div className='Actually_show_content_right_top_right'>
                                <img className='jzz' src={peifu} alt=''/>
                            </div>
                        </a>
                        <a href='/projectTeam' className='Actually_show_content_left'>
                            <div className='Actually_show_content_right_top_left'>
                                <span className='jzjy'>多对一 VIP服务</span>
                                <span className='jzjyu'>客服+设计师+工长+管家</span>
                            </div>
                            <div className='Actually_show_content_right_top_right'>
                                <img className='jzz' src={vip} alt=''/>
                            </div>
                        </a>
                        <a
                            href='/protectionJuran'
                            className='Actually_show_content_left'
                            style={{borderLeft: 0}}
                        >
                            <div className='Actually_show_content_right_top_left'>
                                <span className='jzjy'>工程智能APP </span>
                                <span className='jzjyu'>一部手机,装修全程掌握</span>
                            </div>
                            <div className='Actually_show_content_right_top_right'>
                                <img className='jzz' src={znapp} alt=''/>
                            </div>
                        </a>
                    </div>
                </div> */}
                <div className='Actually_show'>
                    <img className='advantage' src={advantage} alt=''/>
                </div>
                <div className='Decorate_process'>
                    <div className='Our_Services-title'>
                        <span className='title_one'>装修流程</span>
                        <span className='title_two'>心中有数,装修不走弯路</span>
                    </div>
                    <div className='Decorate_process_content'>
                        <img className='zxlc' src={zxlc} alt=''/>
                    </div>
                </div>
                {
                    poster3Me && <div className='Heart_selectedList'>
                        <div className='Our_Services-title'>
                            <span className='title_one'>心选榜单</span>
                            <span className='title_two'>大家都在选</span>
                        </div>
                        <div className='Heart_selectedList_content'>{poster3Me}</div>
                    </div>
                }


                {
                    this.state.WorkSiteListData.length > 0 && [
                        <div className='Placeholder_box'></div>,
                        <div className='Site_live'>
                            <div className='Our_Services-title'>
                                <span className='title_one'>工地直播</span>
                                <span className='title_two'>每日更新，360°看Ta家装修全过程</span>
                                <a href='/listConstruction' className='title_three'>
                                    查看更多<span>>></span>
                                </a>
                            </div>
                            <div className='Site_live_content_zb'>
                <span className='dizhi'>
                  <img className='dizhi' src={dizhi} alt=''/>
                </span>
                                {
                                    <TextLoop
                                        className='Site_live_content_zb_city'
                                        springConfig={{stiffness: 340, damping: 30}}
                                        mask={true}
                                        fade={true}
                                        adjustingSpeed={150}
                                        delay={2000}
                                        interval={[2000, 2000, 2000]}
                                    >
                                        {
                                            this.state.WorkSiteListData.map(k => <div className='textCity'
                                                                                      key={k.wevsuteTitle}>
                                                <span>{k.cityName}</span>
                                                <span>{k.websiteTitle}</span>
                                                <span>{k.htName}</span>
                                                <span>{k.styleName}</span>
                                                <span>{k.floorage}㎡ </span>
                                                <span>开工了!!</span>
                                            </div>)
                                        }


                                    </TextLoop>
                                }

                            </div>
                            <div className='Site_live_content_hg'>
                                <div className='Site_live_content'>
                                    {this.state.WorkSiteListData.map((item) => {
                                        return (
                                            <div
                                                className='Site_live_content_hg_img'
                                                onClick={this.goConstructionSiteLive.bind(this, item.id)}
                                                key={item.id}
                                            >
                                                <img className='gdhg' src={item.websiteCover} alt=''/>
                                                <div className='Site_live_content_mark'>
                          <span className='Site_live_content_address'>
                            {item.cityName}·{item.websiteTitle} 79m²
                          </span>
                                                    <div className='Site_live_content_see'>
                                                        <img className='liulian' src={liulian} alt=''/>
                                                        <span>130</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    ]

                }
                {
                    this.state.ownersPennantList.length > 0 && [
                        <div className='Placeholder_box'></div>,
                        <div className='Our_Servicesr_case'>
                            <div className='Our_Services-title' style={{marginLeft: '12px'}}>
                                <span className='title_one'>邻家案例</span>
                                <span className='title_two'>看看邻居家装成什么样？</span>
                                <a href='/listCase' className='Our_Services-title_title_three'>
                                    查看更多<span>>></span>
                                </a>
                            </div>
                            {this.RenderCase()}
                            {this.renderTop()}
                        </div>
                    ]
                }

                {
                    ownersVoiceList.length > 0 && [
                        <div className='Placeholder_box'></div>,
                        <div className='neighbor_case'>
                            <div className='Our_Services-title'>
                                <span className='title_one'>业主之声</span>
                                <span className='title_two'>看看装过的人怎么说？</span>
                                <a
                                    href='/listOwnersVoice'
                                    className='Our_Services-title_title_three'
                                >
                                    查看更多<span>>></span>
                                </a>
                            </div>
                            {ownersVoiceList.map((item, key) => {
                                let i = item.logTime
                                let statusPassTime = moment(i).format('YYYY年MM月DD日')
                                return (
                                    <div
                                        className='neighbor_case_content'
                                        key={item.id}
                                        onClick={this.goDetails.bind(this, item.id)}
                                    >
                                        <div className='neighbor_case_content_left'>
                                            <img className='yzzss' src={item.icon} alt=''/>
                                        </div>
                                        <div className='neighbor_case_content_right'>
                                            <div className='neighbor_case_content_right_a'>
                                                {item.username}
                                            </div>
                                            <div className='neighbor_case_content_right_b'>
                                                <span className='b_l'>{statusPassTime}</span>
                                                <span className='b_r'>发布评价</span>
                                            </div>
                                            <div className='neighbor_case_content_right_c'>
                        <span className='pingfen'>
                          <span className='pingfen_fen'>评分：</span>
                          <span className='pingfen_xin'>
                            {Array.from({length: item.score}, (_, i) => i).map(
                                (a, index) => {
                                    return <img src={xin} alt='' key={index}/>
                                }
                            )}
                          </span>
                        </span>
                                                <span className='xaingjie'>
                          <span>设计:{item.designScore}.0</span>
                          <span>服务:{item.serviceScore}.0</span>
                          <span>施工:{item.buildScore}.0</span>
                        </span>
                                            </div>
                                            <div className='neighbor_case_content_right_d'>
                                                {item.content}
                                            </div>
                                            {
                                                item.imgUrl && <div className='neighbor_case_content_right_e'>
                          <span className='tupian_'>
                            {item.imgUrl.split(',').map((item, index) => {
                                return (
                                    <img
                                        className='tupian'
                                        src={item}
                                        key={index}
                                        alt=''
                                    />
                                )
                            })}
                          </span>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ]
                }
                <div className='Placeholder_box'></div>
                <div className='DecorateDrycargo'>
                    <div className='Our_Services-title'>
                        <span className='title_one'>装修干货</span>
                        <span className='title_two'>进入美好生活研究所</span>
                        <a
                            href='/decorateGan/index'
                            className='Our_Services-title_title_three'
                        >
                            查看更多<span>>></span>
                        </a>
                    </div>
                    {this.renderDecorate()}
                </div>
                <div className='show_Footer'>
                    <div className='show_Footer_'>
                        <a href='/aboutJuran'>关于我们</a>
                        <span>|</span>
                        <a href='/listNews'> 公司动态</a>
                        <span>|</span>
                        <span
                            onClick={() => {
                                this.setState({designVisible: true})
                            }}
                        >
              装修咨询
            </span>
                    </div>
                    <div>
            <span>
              <img className='phone' src={phone} alt=''/>
            </span>
                        <a href={`tel:${GetTel()}`} className='phone_tel'>
                            {GetTel()}
                        </a>
                    </div>
                    <div>
                        <span>北京居然数装科技有限公司版权所有</span>
                    </div>
                    <div>© 2003-2022 All Rights Reserved. <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备14008453号-4</a></div>
                </div>

                {/* <div className="decorate">
					<div className="Our_Services-title">
							<span className="title_one">装修干货</span>
							<span className="title_two">看看装过的人怎么说？</span>
						</div>
					</div>
        <div className="introduction"></div> */}

                <DesignerPopup
                    visible={roomVisible}
                    title='立即申请免费量房'
                    subTitle='2'
                    cancelClick={() => this.setState({roomVisible: false})}
                    saveInfo={this.roomSaveInfo.bind(this)}
                    btnTxt={['免费量房']}
                    type='room'
                />
                <StorageDialog
                    visible={storageVisible}
                    title='恭喜您免费获得仓储服务'
                    subTitle='2'
                    bottomTxt={[0, 23, 23]}
                    cancelClick={() => this.setState({storageVisible: false})}
                    saveInfo={this.storageSaveInfo.bind(this)}
                    btnTxt={['立即抢占名额']}
                    type='storage'
                />
                <DesignerPopup
                    visible={designVisible}
                    // title='三步轻松获取全屋设计方案'
                    // subTitle='3956'
                    // bottomdata={['在线预约', '免费量房', '全屋方案']}
                    cancelClick={() => this.setState({designVisible: false})}
                    saveInfo={this.designSaveInfo.bind(this)}
                    type='design'
                    btnTxt={['一键领取']}
                />
                <DesignerPopup
                    visible={questionVisible}
                    title='您的回答'
                    cancelClick={() => this.setState({questionVisible: false})}
                    saveInfo={this.questionSaveInfo.bind(this)}
                    type='question'
                    btnTxt={['提交']}
                />
                <DesignerPopup
                    visible={priceVisible}
                    title='装修要花多少钱?'
                    subTitle='1688'
                    cancelClick={() => this.setState({priceVisible: false})}
                    saveInfo={this.priceSaveInfo.bind(this)}
                    btnTxt={['获取报价']}
                    type='price'
                />
            </div>
        )
    }
}
