import React, { Component } from 'react'
import MyNavBar from '../../../components/myNavBar/index'
import phone from '../../../image/phone.png'
import news from '../../../image/news.png'
import '../../../css/to_news.scss'
import bannerMiddle from '../../../image/banner_middle.png'
import { GetQuery, GetTel, GetAddr } from "../../../utils/index";

class To_news extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    newsData: []
  }

  componentWillMount() { }

  componentDidMount() {
    //获取新闻详情
    this.getNewsInfo()
  }

  //获取新闻详情
  getNewsInfo() {
    let id = this.props.location.id
      ? this.props.location.id
      : this.props.match.params.id
    this.get(`${this.api.findNews}?id=${id}&limit=10&page=1`).then((res) => {
      if (res.data && res.data[0]) {
        this.setState({
          newsData: res.data[0]
        })
      }
    })
  }

  componentWillReceiveProps() { }

  componentWillUnmount() { }

  componentDidShow() { }

  componentDidHide() { }

  // 新闻列表区域
  newOne(data) {
    if (data !== undefined) {
      return (
        <div>
          <h2 className='titleCo'>{this.state.newsData.title}</h2>
          <div
            className='richTextWrap'
            dangerouslySetInnerHTML={{
              __html: this.state.newsData.infos
            }}
          ></div>
        </div>
      )
    }
  }
  //底部栏
  Footer() {
    return (
      <div className='Footer'>
        <div className='consulting'>
          <span>装修咨询</span>
          <a href={`tel:${GetTel()}`} className='phoneNum'>{GetTel()}</a>
          <img className='phone_icon' src={phone} alt='' />
        </div>
        <div className='address'>
          总部地址：{GetAddr()}
        </div>
      </div>
    )
  }
  render() {
    let changeArea = (
      <div className='To_store_experience'>
        <div className='bannerTop'
          onClick={() => {
            this.setState({ priceVisible: true })
          }}>
          <img className='bannerTop_img' src={bannerMiddle} alt='' />
          <span className='preciseSolution'>已为1302234人提供精准方案</span>
        </div>
        {this.newOne(this.state.newsData)}
        {this.Footer()}
      </div>
    )

    return (
      <div className='container'>
        <MyNavBar />
        {changeArea}
      </div>
    )
  }
}
export default To_news
