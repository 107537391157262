import {createStore,combineReducers} from 'redux' // 引入redux 
import {counterReducer} from './counterReducer' // 这个是引入新建的reducer文件

//创建store函数
//store包含2部分：所有reducer  所有组件的state
//参数1:合并后的reducer
//参数2:state

// 所有的reducer
const rootReducer = combineReducers({
    counterReducer
})
//所有的state状态
const iniitalState = {

} 

const store = createStore(rootReducer,iniitalState);//  创建store

export default store;// 抛出store
