import React, { Component } from 'react'
import { NavBar, Icon } from 'antd-mobile'
import './index.css'
import 'antd-mobile/dist/antd-mobile.css'
import logoTop from '../../image/jrzs.png'
import logoBottom from '../../image/jrjz.png'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

class MyNavBar extends Component {
  // goSearch(){
  //   this.props.history.push({
  //     pathname: '/index/Found/decorateGan/search'
  //   })
  // }
  render() {
  const {type} = this.props
    
    return (
      <div className='myNavBar'>
        <NavBar
          mode='light'
          icon={<Icon type='left' />}
          onLeftClick={() => {
              window.history.go(-1)
          }}
          // rightContent={[
          //   <Icon key="0" type="search" style={{display:type=='search'?'block':"none",color:'#0166B3'}} onClick={this.goSearch.bind(this)} />
          // ]}
        >
          <div className='myNavBar_logo'>
            <img className='logoTop' src={logoTop} alt='' />
            <img className='logoBottom' src={logoBottom} alt='' />
          </div>
        </NavBar>
      </div>
    )
  }
}

export default MyNavBar
