import page from '../views/index'//根
import home from '../views/home/index'//首页
import remodelPartial from '../views/home/remodelPartial' //局装局改
import packageMainMaterial from '../views/home/packageMainMaterial/index' //装修主材包
import listStore from '../views/home/listStore' //门店列表
import styleTesting from '../views/home/styleTesting' //风格测试
import aboutJuran from '../views/home/aboutJuran/index' //关于居然
import projectTeam from '../views/home/projectTeam' //项目团队
import buildingMaterialsSelecting from '../views/home/buildingMaterialsSelecting' //甄选建材
import promiseJuran from '../views/home/promiseJuran' //居然承诺
import protectionJuran from '../views/home/protectionJuran' //居然保障
import listConstruction from '../views/home/listConstruction' //工地列表
import listCase from '../views/home/listCase' //邻家案例列表页
import listOwnersVoice from '../views/home/listOwnersVoice' //业主之声列表页
import listNews from '../views/home/listNews' //新闻动态列表
import designTop from '../views/home/designTop' //顶层设计
import lewu from '../views/home/lewu' //乐屋
import eProject from '../views/home/eProject' //e+工程
import detailsNews from '../views/home/detailsNews/index' //新闻动态详情
import resultSyleTest from '../views/home/resultSyleTest' //风格测试结果
import kuaiwu from '../views/home/kuaiwu' //快屋
import detailsConstructionSite from '../views/home/detailsConstructionSite' //工地详情
import detailsCase from '../views/home/detailsCase' //邻家案例详情页
import detailsOwnersVoice from '../views/home/detailsOwnersVoice' //业主之声详情页
import designerInfo from '../views/home/designerInfo' //设计师详情
import designer from '../views/home/designer' //设计师
import longTailWords from '../views/home/longTailWords' //设计师

import decorateGan from '../views/home/decorateGan/index' //装修干货列表页decorationList
import questionDetail from '../views/home/decorateGan/questionDetail' //装修问答详情页
import decorateDetail from '../views/home/decorateGan/decorateDetail' //装修干货详情页
import decorationList from '../views/home/decorateGan/decorationList' //装修前中后DecorateDaqo
import DecorateDaqo from '../views/home/decorateGan/DecorateDaqo' //装修前中后DecorateDaqo

// import defaultPage from '../views/index'
import webSiteActivity from '../views/home/webSiteActivity' //风格测试

const routes = [
    {
        path: '/styleTesting',
        component: styleTesting
    },
    {
        path: '/webSiteActivity',
        component: webSiteActivity
    },
    {
        path: '/resultSyleTest',
        component: resultSyleTest
    },
    //重定向
    {
        path: '/',
        component: page,
        children: [
            {
                path: '/remodelPartial',
                component: remodelPartial
            },
            {
                path: '/home',
                component: home
            },
            {
                path: '/remodelPartial',
                component: remodelPartial
            },
            {
                path: '/packageMainMaterial',
                component: packageMainMaterial
            },
            {
                path: '/listStore',
                component: listStore
            },
          
            {
                path: '/aboutJuran',
                component: aboutJuran
            },
            {
                path: '/projectTeam',
                component: projectTeam
            },
            {
                path: '/buildingMaterialsSelecting',
                component: buildingMaterialsSelecting
            },
            {
                path: '/promiseJuran',
                component: promiseJuran
            },
            {
                path: '/protectionJuran',
                component: protectionJuran
            },
            {
                path: '/listConstruction',
                component: listConstruction
            },
            {
                path: '/listCase',
                component: listCase
            },
            {
                path: '/listNews',
                component: listNews
            },
            {
                path: "/designTop",
                component: designTop,
            },
            {
                path: "/lewu",
                component: lewu,
            },
            {
                path: "/eProject",
                component: eProject,
            },
            {
                path: '/detailsNews/:id',
                component: detailsNews
            },
          
            {
                path: '/designer',
                component: designer
            },
            {
                path: '/detailsOwnersVoice/:id',
                component: detailsOwnersVoice
            },
            {
                path: '/detailsConstructionSite/:id',
                component: detailsConstructionSite
            },
            {
                path: '/detailsCase',
                component: detailsCase,
            },
            {
                path: '/designerInfo',
                component: designerInfo,
            },
            {
                path: '/listOwnersVoice',
                component: listOwnersVoice
            },
            {
                path: '/decorateGan/index',
                component: decorateGan,
            },
            {
                path: '/decorateGan/decorateDetail/:id',
                component: decorateDetail
            },
            {
                path: '/decorateGan/questionDetail/:id',
                component: questionDetail
            },
            {
                path: '/decorateGan/decorationList/:id/:tab',
                component: decorationList
            },
            {
                path: '/decorateGan/DecorateDaqo/:id',
                component: DecorateDaqo
            },
            {
              path: '/longTailWords/:id',
              component: longTailWords
            },
            {
                path: '/kuaiwu/:id',
                component: kuaiwu
            },
            {
                path: '/',
                component: home
            }
        ]
    }

]

export default routes
