const iniitalState = {
  // 定义state的状态
  num: Math.ceil(Math.random() * 9 + 1),
  giftShow: true,
  time:null
}

//  抛出counterReducer这个方法
export function counterReducer(state = iniitalState, action) {
  switch (action.type) {
    case 'minus':
      state.num === 1 ? (state.num = 1) : state.num--
      return {
        num: state.num
      } 
    case 'giftDialog':
      state.giftShow = false
      return {
        giftShow: state.giftShow,
        num: state.num
      }
    default:
      return state
  }
}
