import React, { Component, useState, useEffect, useRef } from 'react'
import { Route, withRouter, RouteComponentProps } from 'react-router-dom'
import RouterView from '../router/routerView'
import DesignerPopup from '../components/designerPopup/index'
import StorageDialog from '../components/storageDialog/index'
import '../App.css'
import '../css/active.css'
import './index.less'
import '../../src/iconfont/iconfont.css'
import liwu from '../image/liwu.png'
import bider from '../image/bider.png'
import jrzs from '../image/jrzs.png'
import jrjz from '../image/jrjz.png'
import yjzx from '../image/yjzx.png'
import { Toast } from 'antd-mobile'
import gift from '../image/gift.gif'
import ActionSheet from '../components/ActionSheet/index.js'
import GiftDialog from '../components/giftDialog/index'
import { websiteOwer } from '../request/index'
import { Cascader } from 'antd'
import {GetQueryObj, GetQuery, locationHref} from "../utils/index";
import { webSiteAdvAdmin, websiteProduceCategory, websiteIndex } from '../request/index'
import {
  DownOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux'
import { giftAction } from '../store/actions'
import {
  home,
  activity,
  test,
  floor1,
  floor2,
  floor3,
  floor4,
  floor5,
  floor6,
  floor7
} from '../image/panel/index'
import '../css/panel.scss'
import {cityDataPinyin} from "../utils/cityData";

const listData = [
  {
    id: 1,
    title: '装修产品',
    list: [
      { label: '乐屋个性化', link: '/lewu', dis: true },
      { label: '顶层别墅', link: '/designTop', dis: true },
      { label: '快屋套餐', link: '/kuaiwu/1', dis: true },
      { label: '局装局改', link: '/remodelPartial', dis: true },
      { label: '章鱼买手', link: '/packageMainMaterial', dis: true }
    ],
    img: floor1
  },
  {
    id: 2,
    title: '寻找灵感',
    list: [
      { label: '装修案例', link: '/listCase' },
      { label: '设计师', link: '/designer' },
      { label: '风格测试', link: '/styleTesting' }
    ],
    img: floor2
  },
  {
    id: 3,
    title: '装修保障',
    list: [
      { label: '项目团队', link: '/projectTeam' },
      { label: '甄选主材', link: '/buildingMaterialsSelecting' },
      { label: 'e+工程体系', link: '/eProject' },
      // { label: '居然承诺', link: '/promiseJuran' },
      { label: '数字化服务', link: '/protectionJuran' }
    ],
    img: floor3
  },
  {
    id: 4,
    title: '业主之声',
    list: [
      { label: '业主评价', link: '/listOwnersVoice' },
      { label: '业主锦旗', link: '/listOwnersVoice' }
    ],
    img: floor4
  },
  {
    id: 5,
    title: '线下体验',
    list: [
      { label: '线下门店', link: '/listStore' },
      { label: '参观工地', link: '/listConstruction' }
    ],
    img: floor5
  },
  {
    id: 6,
    title: '装修攻略',
    list: [
      { label: '装修前', link: '/decorateGan/decorationList/6/tab9' },
      { label: '装修中', link: '/decorateGan/decorationList/7/tab10' },
      { label: '装修后', link: '/decorateGan/decorationList/8/tab13' },
      { label: '装修问答', link: '/decorateGan/DecorateDaqo/tab2' },
      { label: '装修百科', link: '/decorateGan/DecorateDaqo/tab1' },
      { label: '装修日记', link: '/decorateGan/DecorateDaqo/tab3' }
    ],
    img: floor6
  },
  {
    id: 7,
    title: '关于居然',
    list: [
      { label: '公司简介', link: '/aboutJuran' },
      { label: '公司动态', link: '/listNews' },
      { label: '联系我们', link: '' }
    ],
    img: floor7
  }
]
const AMap = window.AMap;
class App extends Component {

  state = {
    visible: false,
    giftVisible: false,
    priceVisible: false,
    api: '',
    active: 0,
    sheetVisible: false,
    giftDialogShow: false,
    city: '',
    telList: [],
    cateList: [],
    options: [],
    listData: listData,
    list: {}
  }
  componentWillMount() {
    let LocalCity = localStorage.getItem("LocalCity") ? JSON.parse(localStorage.getItem("LocalCity")) : {}
    if (!LocalCity.provinceId) {
      this.getCity()
    } else {
      this.websiteSystem({
        province: LocalCity.province,
        city: LocalCity.city
      })

      this.setState({
        visible: false,
        giftVisible: false,
        priceVisible: false,
        active: 0,
        sheetVisible: false,
        giftDialogShow: false,
        telList: [],
        listData: listData
      }, () => {
        this.getTel()
        this.websiteIndex()
      })

    }
    this.getgetCitySelect()
  }
  websiteIndex() {
    websiteIndex().then((res) => {
      let data = res.data.data
      if (!data) return
      document.title = data.seotitle
      var meta = document.getElementsByTagName('meta')
      addMate('a', 'baidu-site-verification', 'code-OxHdxbFHzJ')
      addMate(
        'b',
        'keywords',
        data.keyword
      )
      addMate(
        'c',
        'description',
        data.describes
      )

      function addMate(id, name, content) {
        let metaData = document.getElementsByTagName('head')[0].children

        for (let i = 0; i < metaData.length; i++) {
          if (metaData[i].localName === 'meta' && metaData[i].id === id) {
            const mate = document.getElementById(id)
            document.getElementsByTagName('head')[0].removeChild(mate)
          }
        }
        let meta = document.createElement('meta')
        meta.content = content
        meta.name = name
        meta.id = id
        document.getElementsByTagName('head')[0].appendChild(meta)
      }
    })
  }
  reset() {
    this.setState({
      visible: false,
      giftVisible: false,
      priceVisible: false,
      api: '',
      active: 0,
      sheetVisible: false,
      giftDialogShow: false,
      city: '',
      telList: [],
      cateList: [],
      listData: listData
    })
  }
  getgetCitySelect() {
    this.get(`${this.api.selectAllCity}`, '').then((res) => {
      let arr = []
      res.forEach(element => {
        if (element.type === 1) {
          arr.push({
            label: element.cityname,
            value: element.cityname,
            children: [],
            ...element,
          })
        } else {
          arr = arr.map(i => {
            element.label = element.cityname
            element.value = element.cityname
            if (i.id === element.pid) {
              i.children.push(element)
            }
            return i
          })
        }
      });
      this.setState({
        options: arr
      })

    })
  }
  getwebsiteProduceCategory() {

    websiteProduceCategory().then((res) => {
      let { listData } = this.state
      listData = JSON.parse(JSON.stringify(listData))
      let arr = res.data.data.map(i => {
        i.label = i.titleOne
        if (i.jumpType == 1){
          i.dis = false
          i.link = i.jumpUrl
        }else {
          if (i.produceIds) {
            i.produceIds = i.produceIds.split(',')
            if (i.produceIds.length === 1) {
              i.link = `/kuaiwu/${i.produceIds[0]}`
              i.dis = false
            } else if (i.produceIds.length > 1) {
              i.link = `/remodelPartial/${i.id}`
              i.dis = false
            }
          } else {
            i.dis = true
            i.link = ''
          }
        }
        return i
      })
      if (arr.length > 0) {
        listData[0].list = arr
      }

      this.setState({
        listData: listData
      })

    })
  }
  goWebSiteActivity() {
    webSiteAdvAdmin().then((res) => {
      if (res.data.data && res.data.data.length > 0) {
        locationHref('/webSiteActivity')
      } else {
        Toast.info('暂无活动', 1)
      }
    })
  }
  websiteSystem = (result) => {
    this.get(`${this.api.websiteSystem}?provinceName=${result.province}&&cityName=${result.city}`).then(
      (res) => {
        let st = res.data[0].infos
        st = st.replaceAll('<script>', '')
        st = st.replaceAll('</script>', '')
        let bdtj =  document.getElementById('bdtj') ? document.getElementById('bdtj') : document.createElement("script")
        bdtj.setAttribute("id",'bdtj')
        bdtj.innerHTML = st
        document.head.appendChild(bdtj)
      }
    )
  }
  //城市定位
  getCity = () => {
    AMap.plugin('AMap.CitySearch', () => {
      const citySearch = new AMap.CitySearch()
      citySearch.getLocalCity((status, result) => {
        let path = window.location.pathname
        let split = path.split('/');
        let filterRes = cityDataPinyin.filter(function (c) {
          return c.pinyin.indexOf(split[1]) !== -1;
        })
        if (filterRes.length < 1){
          this.getAddrId(result)
        }
        this.websiteSystem(result)
      })
    })
  }
  getAddrId(result, flag) {
    this.get(`${this.api.findUserCity}?provinceName=${result.province}&&cityName=${result.city}`).then(
      (res) => {
        res.data.province = result.province
        res.data.city = result.city
        localStorage.setItem('LocalCity', JSON.stringify(res.data));

        //修改url城市参数
        let filterRes = cityDataPinyin.filter(function (c) {
          return result.city.indexOf(c.name) !== -1;
        })

        let path = window.location.pathname
        let split = path.split('/');
        split[1] = filterRes[0] ? filterRes[0].pinyin : 'beijing';
        window.location.href = window.location.origin + split.join("/")
      }
    )
    this.reset()
    this.getTel()
    this.websiteIndex()
    this.setState({
      city: result.city
    })
  }
  getTel() {

    websiteOwer().then((res) => {
      let arr = []
      if (res.data.code === 0 && res.data.data){
        res.data && res.data.data.forEach(i => {
          arr.push(i.phone)
        })
        localStorage.setItem('tel', JSON.stringify(arr));
        this.setState({
          telList: arr
        })
      }
    })
  }
  saveInfo(obj) {
    let sourceUrl = escape(window.parent.document.location);
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          visible: false
        })
        Toast.success('已提交信息', 1)
      }
    })
  }

  
  priceSaveInfo(obj) {
    let source = '获取报价'
    let sourceUrl = '/kuaiwu'
    this.post(
        `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
        ''
    ).then((res) => {
        if (res.code === 0) {
            this.setState({
                priceVisible: false
            })
            Toast.success('已提交信息', 1)
        }
    })
}

  giftDialogSaveInfo(obj) {
    let source = '领取设计红包'
    let sourceUrl = escape(window.parent.document.location);//'page'
    let area = ''
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          giftDialogShow: false
        })
        Toast.success('已提交信息', 1)
        this.props.giftDialog()
      }
    })
  }

  open53() {
    var _53 = window.$53.createApi()
    _53.push('cmd', 'mtalk')
    _53.query()
  }

  giftSaveInfo(obj) {
    let source = '领取礼物'
    let sourceUrl = escape(window.parent.document.location);//'page'
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          priceVisible: false // giftVisible: false
        })
        Toast.success('已提交信息', 1)
      }
    })
  }

  chooseMenu(type) {
    this.setState({
      active: type
    })
    if (type === 1) {
      this.getwebsiteProduceCategory()
      this.setState({ sheetVisible: true })

    }
    if (type === 4) {
      locationHref('/styleTesting')
      this.setState({ sheetVisible: false })
    }
  }

  //产品跳转逻辑
  goWhere = (link, e) => {
    if (e.dis) {
      Toast.info('暂未开放')
      return
    }
    localStorage.setItem('alabels', e.label);

    window.location.href = link
    this.setState({ sheetVisible: false })
  }

  RenderTop() {
    return (
      <ul className='FoundPanel_top'>
        <li className='FoundPanel_top_list'>
          <div className='FoundPanel_top_link' onClick={this.goWhere.bind(this, '/')}>
            <img className='FoundPanel_top_img1' src={home} alt='' />
            <span className='FoundPanel_top_txt'>返回首页</span>
          </div>
        </li>
        <li className='FoundPanel_top_list'>
          <div className='FoundPanel_top_link' onClick={this.goWebSiteActivity}>
            <img className='FoundPanel_top_img2' src={activity} alt='' />
            <span className='FoundPanel_top_txt'>活动专区</span>
          </div>
        </li>
        <li className='FoundPanel_top_list'>
          <div
            className='FoundPanel_top_link'
            onClick={this.goWhere.bind(this, '/styleTesting')}
          >
            <img className='FoundPanel_top_img3' src={test} alt='' />
            <span className='FoundPanel_top_txt'>风格测试</span>
          </div>
        </li>
      </ul>
    )
  }

  RenderGiftDialog() {
    // let {gifShow} = this.state
    let show = this.props.giftShow
    return (
      <div style={{ display: show ? 'block' : 'none' }}>
        <div className='giftMask'></div>
        <img
          className='giftDialog'
          onClick={() => {
            this.setState({ show: false, giftDialogShow: true })
            this.props.giftDialog()
          }}
          src={gift}
          alt=''
        />
      </div>
    )
  }
  RenderPannelLink(data) {
    let changeArea = null
    if (data.label === '联系我们') {
      changeArea = (
        <a
          className='FoundPanel_middle_link'
          key={data.label}
          href={`tel:${this.state.telList[0]}`}
        >
          联系我们
        </a>
      )
    } else {
      changeArea = (
        <div
          onClick={this.goWhere.bind(this, data.link, data)}
          className='FoundPanel_middle_link'
        >
          {data.label}
        </div>
      )
    }
    return changeArea
  }

  RenderMiddle() {
    return (
      <ul className='FoundPanel_middle'>
        {this.state.listData.map((item) => {
          return (
            <li className='FoundPanel_middle_list' key={item.id}>
              <div className='FoundPanel_middle_list_header'>
                <img className='FoundPanel_middle_img' src={item.img} alt='' />
                <span className='FoundPanel_middle_tit'>{item.title}</span>
              </div>
              <ul className='FoundPanel_middle_links'>
                {item.list.map((listData, index) => {
                  return <div key={index} className=""> {this.RenderPannelLink(listData)}</div>
                })}
              </ul>
              <div className='transitionStrip'></div>
            </li>
          )
        })}
      </ul>
    )
  }

  RenderActionSheet() {
    let changeArea = (
      <div className='transparentBackground'>
        <div className='FoundPanel'>
          {this.RenderTop()}
          <div className='transitionStrip'></div>
          {this.RenderMiddle()}
        </div>
      </div>
    )

    return (
      <ActionSheet
        visite={this.state.sheetVisible ? 1 : 0}
        onClose={() => {
          this.setState({ sheetVisible: false })
        }}
      >
        {changeArea}
      </ActionSheet>
    )
  }
  onChange = (value, selectedOptions) => {
    this.websiteSystem({
      province: selectedOptions[0].label,
      city: selectedOptions[1].label,
    })

    this.getAddrId({
      province: selectedOptions[0].label,
      city: selectedOptions[1].label,
    }, true)
  };

  render() {

    ; <meta
      content='width=device-width,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no'
      name='viewport'
    />
    let { visible, active, priceVisible, giftVisible, giftDialogShow } = this.state
    return (
      <div className='app'>
        <div className='app_header'>
          <div className='app_header_content' id='app_header_content'>
            <span
              className='bider'
              onClick={() => this.setState({ priceVisible: true })}
              // onClick={() => this.setState({ giftVisible: true })}
            >
              <span className='bider_text'>报价器</span>
              <img className='bider_img' src={bider} alt='' />
            </span>
            <span className='logo'>
              <span className='jrzs'>
                <img className='jrzs' src={jrzs} alt='' />
              </span>
              <span className='jrjz'>
                <img className='jrjz' src={jrjz} alt='' />
              </span>
            </span>
            <span className='header_city'>
              <Cascader options={this.state.options.length > 0 ? this.state.options : []} onChange={this.onChange} >
                <div> {GetQueryObj().city} <DownOutlined /></div>
              </Cascader>
            </span>
          </div>
        </div>
        {/*  {this.RenderGiftDialog()}*/}

        <div className='app_main' id='app_main'>
          <RouterView routes={this.props.routes}></RouterView>
        </div>
        {this.RenderActionSheet()}
        <div className='app_footer'>
          <div className='footer_opcity'>
            <div className='app_footer_content'>
              <div
                className='app_footer_content_found'
                style={{ color: active === 1 ? '#1296DB' : '#666666' }}
                onClick={this.chooseMenu.bind(this, 1)}
              >
                <span className='footer_img'>
                  <span className='iconfont iconweibiaoti2fuzhi15'></span>
                </span>
                <span className='footer-href'>　发现　</span>
              </div>
              <div
                className='app_footer_content_found'
                style={{ color: active === 5 ? '#1296DB' : '#666666' }}
                onClick={this.chooseMenu.bind(this, 5)}
              >
                <span className='footer_img'>
                  <span className='iconfont iconshejishijianzhushi'></span>
                </span>
                <span
                  className='footer-href'
                  onClick={() => this.setState({ visible: true })}
                >
                  预约设计
                </span>
              </div>
              <div
                className='app_footer_content_found'
                style={{ color: active === 3 ? '#1296DB' : '#666666' }}
                onClick={this.chooseMenu.bind(this, 3)}
              >
                <a className='footer_img' href={`tel:${this.state.telList[0]}`}>
                  <img className='yjzx' src={yjzx} alt='' />
                </a>
                <a
                  href={`tel:${this.state.telList[0]}`}
                  className='footer-href one_key_ask_footer'
                >
                  一键咨询
                </a>
              </div>
              <div
                className={
                  active == 4
                    ? 'app_footer_content_found style_active'
                    : 'app_footer_content_found'
                }
                onClick={this.chooseMenu.bind(this, 4)}
                style={{ color: active == 4 ? '#1296DB !important' : '#666' }}
              >
                <span className='footer_img'>
                  <span className='iconfont iconceshi'></span>
                </span>
                <span className='footer-href'>风格测试</span>
              </div>
              <div
                className='app_footer_content_found'
                style={{ color: active === 2 ? '#1296DB' : '#666666' }}
                onClick={this.chooseMenu.bind(this, 2)}
              >
                <span onClick={() => this.open53()} className='footer_img'>
                  <span className='iconfont iconzixun'></span>
                </span>
                <span onClick={() => this.open53()} className='footer-href'>
                  装修咨询
                </span>
              </div>
            </div>
          </div>
        </div>
        <DesignerPopup
          visible={visible}
          title='预约我的专属设计师'
          subTitle='在线预约 尊享优先排期特权'
          bottomdata={['免费量房', '户型解析', '软装咨询']}
          cancelClick={() => this.setState({ visible: false })}
          saveInfo={this.saveInfo.bind(this)}
          btnTxt={['立即预约']}
        />

        <StorageDialog
          visible={giftVisible}
          title='恭喜您获得居然之家提供精美礼品'
          subTitles='请尽快到店领取'
          bottomTxt={[0, 27, 53]}
          cancelClick={() => this.setState({ giftVisible: false })}
          saveInfo={this.giftSaveInfo.bind(this)}
          btnTxt={['立即获取']}
        />
        <GiftDialog
          visible={giftDialogShow}
          cancelClick={() => {
            this.setState({ giftDialogShow: false })
            this.props.giftDialog()
          }}
          saveInfo={this.giftDialogSaveInfo.bind(this)}
          btnTxt={['立即获取']}
        />
        <DesignerPopup
            visible={priceVisible}
            title='装修要花多少钱?'
            subTitle='1688'
            cancelClick={() => this.setState({priceVisible: false})}
            saveInfo={this.priceSaveInfo.bind(this)}
            btnTxt={['获取报价']}
            type='price'
        />
        {/* <div style={{display:'none'}} dangerouslySetInnerHTML={{
          __html: this.state.list
        }}></div> */}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    giftShow: state.counterReducer.giftShow
  }
}

function mapDispatchToProps(dispatch) {
  return {
    giftDialog: () => {
      dispatch(giftAction())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
