import React, { Component } from 'react'
import TextLoop from 'react-text-loop'
import './index.scss'
import { Carousel } from 'antd-mobile'
import DesignerPopup from '../../../components/designerPopup/index'
import moment from 'moment'
import { withRouter, Link } from 'react-router-dom'
import { Toast } from 'antd-mobile'
import {GetQuery, locationHref} from '../../../utils/index'
import MyNavBar from '../../../components/myNavBar/index'
import {
    noticeIcon,
    share,
    xing,
    promise,
    house,
    xiangmu,
    yuyue,
    right,
    juranlOGO,
    shouyexiugai,
    pinpai,
    xianhangpeifu,
    dianhua,
    kefu,
    shouye,
    fuwu
} from '../../../image/kuaiwo/index'

class House_quickly extends Component {
    state = {
        data: ['1', '2', '3'],
        askVisible: false,
        priceVisible: false,
        activitieVisible: false,
        roomVisible: false,
        nowVisible: false,
        onLineroomVisible: false,
        ownersVoice: [],
        designerCase: [],
        produceInfo: [],
        detailInfo: [],
        name: '',
        area: '',
        tel: '',
        address: '',
        imgList: ''
    }

    componentDidMount() {
        document.getElementById('start').scrollIntoView(true)
        //获取业主评价列表
        this.getOwnersVoiceData()
        //获取案例列表
        this.getDesignerCaseList()
        window.onbeforeunload = () => {
            document.documentElement.scrollTop = 0 //ie下
            document.body.scrollTop = 0 //非ie
            document.getElementsByClassName('.House_quickly').scrollIntoView(true)
        }
        //tdk添加内容
        var meta = document.getElementsByTagName('meta')
        document.title = '快屋装饰_乐居整装享品质生活_899套餐-居然装饰'
        addMate('a', 'title', '快屋装饰_乐居整装享品质生活_899套餐-居然装饰')
        addMate(
            'b',
            'keywords',
            '快屋装饰,居然快屋装饰怎么样,居然快屋装饰评价好不好'
        )
        addMate(
            'c',
            'description',
            '居然快屋装饰怎么样？居然快屋装饰，899套餐-臻选套餐-一站搞定，省心省力，标准化就找居然快屋装饰，看得见的是大牌，看不见的也是大牌，隐蔽工程不做表面功夫，品质生活从居然快屋开始。'
        )

        function addMate(id, name, content) {
            let metaData = document.getElementsByTagName('head')[0].children
            for (let i = 0; i < metaData.length; i++) {
                if (metaData[i].localName === 'meta' && metaData[i].id === id) {
                    const mate = document.getElementById(id)
                    document.getElementsByTagName('head')[0].removeChild(mate)
                }
            }
            let meta = document.createElement('meta')
            meta.content = content
            meta.name = name
            meta.id = id
            document.getElementsByTagName('head')[0].appendChild(meta)
        }
        window.scroll(0,0)
    }

    componentWillMount() {
        this.getProduceInfo()
        // document.getElementById('House_quickly').scrollIntoView()
        //获取产品详情
        // // 产品详情seo优化内容
        // document.createElement('title').setAttribute('content', this.state.produceInfo.seoTitle)
        // if (this.state.produceInfo.keyword) {
        //     document.createElement('meta')
        //         .setAttribute('content', this.state.produceInfo.keyword);
        // } else {
        // }
        // if (this.state.produceInfo.describes) {
        //     document.createElement('meta')
        //         .setAttribute('content', this.state.produceInfo.describes);
        // } else {
        // }
    }
    // componentWillReceiveProps(newProps) {
    //     const id = newProps.match.params.id;
    //     this.get(`${this.api.getProduceInfo}?id=${id}`).then((res) => {
    //         if (res.code === 0) {
    //             this.setState(
    //                 {
    //                     produceInfo: res.data,
    //                     detailInfo: res.data.detail
    //                 },
    //                 () => {
    //                 }
    //             )
    //         }
    //     })
    // }
    //获取产品详情
    getProduceInfo() {
        let id = this.props.match.params.id
        this.get(`${this.api.getProduceInfo}?id=${id}`).then((res) => {
            if (res.code === 0) {
                this.setState(
                    {
                        produceInfo: res.data,
                        detailInfo: res.data.detail
                    },
                    () => {
                    }
                )
            }
        })
    }

    // //获取业主评价列表
    getOwnersVoiceData() {
        let url = '/api/ownersVoice/getList'
        this.get(`${url}?page=1&limit=2&${GetQuery()}`).then((res) => {
            if (res.code === 0) {
                this.setState({
                    ownersVoice: res.data
                })
            }
        })
    }

    //获取案例列表
    getDesignerCaseList() {
        let url = '/api/website/getDesignerCaseList'
        this.get(`${url}?page=1&limit=4&${GetQuery()}`).then((res) => {
            if (res.code === 0) {
                this.setState({
                    designerCase: res.data.data
                })
            }
        })
    }

    //获取服务详情
    getGaphicDetails() { }

    goHome() {
        locationHref('/home')
    }

    goStore() {
        locationHref('/listStore')
    }

    goCaseDetail(id) {
        locationHref('/detailsCase/' + id)
    }

    open53() {
        // this.setState({
        var _53 = window.$53.createApi()
        _53.push('cmd', 'mtalk')
        // _53.push('type', 'popup');
        _53.query()
        // })
    }

    inputChange(type, e) {
        if (type === 'name') {
            this.setState({
                name: e.target.value
            })
        } else if (type === 'area') {
            this.setState({
                area: e.target.value
            })
        } else if (type === 'address') {
            this.setState({
                address: e.target.value
            })
        } else {
            this.setState({
                tel: e.target.value
            })
        }
    }

    askSaveInfo(obj) {
        let source = '立即咨询'
        let sourceUrl = 'page/House_quickly'
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    askVisible: false
                })
                Toast.success('已提交信息', 1)
            }
        })
    }

    priceSaveInfo(obj) {
        let source = '获取报价'
        let sourceUrl = 'page/House_quickly'
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    priceVisible: false
                })
                Toast.success('已提交信息', 1)
            }
        })
    }

    activitieSaveInfo(obj) {
        let source = '活动通知'
        let sourceUrl = 'page/House_quickly'
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    activitieVisible: false
                })
                Toast.success('已提交信息', 1)
            }
        })
    }

    roomSaveInfo() {
        let source = '立即预约360°环保量房'
        let sourceUrl = 'page/House_quickly'
        let { name, area, tel, address } = this.state
        if (tel === '') {
            Toast.info('未填写手机号码呀~', 1)
        } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(tel)) {
            Toast.info('手机号码格式不正确~', 1)
        } else {
            this.post(
                `${this.api.getCustomerCollect}?username=${name}&phone=${tel}&housingArea=${area}&address=${address}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
                ''
            ).then((res) => {
                if (res.code === 0) {
                    this.setState({
                        name: '',
                        area: '',
                        tel: '',
                        address: ''
                    })
                    Toast.success('已提交信息', 1)
                }
            })
        }
    }

    CilckdetailInfo(buttonType, buttonUrl, buttonPhone) {
        if (buttonType == 1) {
            this.setState({ askVisible: true })
        } else if (buttonType == 2) {
            this.open53()
        } else if (buttonType == 3 && buttonUrl !== '') {
            window.location.href = `${buttonUrl}`
        } else if (buttonType == 4) {
            window.location.href = `tel:${buttonPhone}`
        }
    }

    nowSaveInfo(obj) {
        let source = '立即预约'
        let sourceUrl = 'page/House_quickly'
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    nowVisible: false
                })

                Toast.success('已提交信息', 1)
            }
        })
    }

    onLineroomSaveInfo(obj) {
        let source = '免费量房'
        let sourceUrl = 'page/House_quickly'
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    onLineroomVisible: false
                })
                Toast.success('已提交信息', 1)
            }
        })
    }

    //   轮播图
    RenderBanner() {
        let data = this.state.produceInfo.imgUrl || ''
        if (data.length !== 0) {
            return (
                <div className='Carousels'>
                    <Carousel
                        dotStyle={{ width: '4px', height: '4px', background: '#666666' }}
                        className='house_quickly_carousel'
                    >
                        {data && data.split(',').map((item, index) => {
                            return (
                                <img
                                    className='bannerr'
                                    style={{
                                        width: '100%',
                                        verticalAlign: 'top',
                                        height: '100%',
                                        marginTop: '0'
                                    }}
                                    src={item}
                                    key={index}
                                    alt=''
                                />
                            )
                        })}
                    </Carousel>
                </div>
            )
        }
    }

    renderTitle() {
        let title1, title2
        if (
            this.state.produceInfo.construction &&
            this.state.produceInfo.construction != ''
        ) {
            title1 = (
                <li className='setMealItem'>
                    <img className='listIcon' src={xiangmu} alt='' />
                    <span>{this.state.produceInfo.construction}</span>
                </li>
            )
        }
        if (
            this.state.produceInfo.material &&
            this.state.produceInfo.material != ''
        ) {
            title2 = (
                <li className='setMealItem'>
                    <img className='listIcon' src={xiangmu} alt='' />
                    <span>{this.state.produceInfo.material}</span>
                </li>
            )
        }
        return (
            <div>
                {title1}
                {title2}
            </div>
        )
    }

    renderTitle1() {
        let title3
        if (
            this.state.produceInfo.digitalServices &&
            this.state.produceInfo.digitalServices != ''
        ) {
            title3 = (
                <li className='setMealItem'>
                    <img className='listIcon' src={fuwu} alt='' />
                    <span>数字化服务: {this.state.produceInfo.digitalServices}</span>
                </li>
            )
        }
        return <div>{title3}</div>
    }

    //   套餐
    RenderSetMeal() {
        let company
        if (this.state.produceInfo.priceType == 1) {
            company = '元/㎡'
        } else if (this.state.produceInfo.priceType == 2) {
            company = '元/套'
        }

        let yyCount = parseInt(Date.parse(new Date())/2000/2/5400)
        return (
            <div className='SetMeal'>
                <div className='SetMealInfo'>
                    <div className='SetMealInfo_top'>
                        <div className='SetMealPirce'>
                            <span className='price'>{this.state.produceInfo.price}</span>
                            <span className='Company'>
                                {company}
                            </span>
                        </div>
                        <div className='remarks'>
                            <p>在线咨询客户经理 享受额外惊喜</p>
                        </div>
                        <div className='SetMealBtns'>
                            <div
                                className='EventNotice'
                                onClick={() => {
                                    this.setState({ activitieVisible: true })
                                }}
                            >
                                <img className='noticeIcon' src={noticeIcon} alt='' />
                                <span>活动通知</span>
                            </div>
                            {/* <div className='share'>
                                <img className='share' src={share} alt='' />
                                <span>分享</span>
                            </div> */}
                        </div>
                    </div>
                    <div className='SetMealInfo_bottom'>
                        <span className='SetMealInfo_title'>
                            {this.state.produceInfo.title}
                        </span>
                        <div className='appointment_popularity'>
                            <div className='appointmentsNumber'>
                                预约次数: <span>{yyCount}</span>
                            </div>
                            <div className='popularity'>
                                <span> 人气: </span>
                                {this.state.data.map((item, index) => {
                                    return <img src={xing} key={index} alt='' />
                                })}
                            </div>
                        </div>
                        <div
                            className='ask'
                            onClick={() => {
                                this.setState({ askVisible: true })
                            }}
                        >
                            立即咨询
                        </div>
                    </div>
                    <ul className='setMealList'>
                        <li
                            className='setMealItem'
                            onClick={() => {
                                this.setState({ onLineroomVisible: true })
                            }}
                        >
                            <img className='listIcon' src={yuyue} alt='' />
                            <span>在线预约享免费环保量房</span>
                            <img className='rightIcon' src={right} alt='' />
                        </li>
                        <li className='setMealItem'>
                            <img className='listIcon' src={xiangmu} alt='' />
                            <span>套餐包含内容：{this.state.produceInfo.setMeal}</span>
                        </li>
                        {this.renderTitle()}
                        <li className='setMealItem'>
                            <img className='listIcon' src={promise} alt='' />
                            <span>服务承诺：{this.state.produceInfo.promise}</span>
                        </li>
                        {this.renderTitle1()}
                        <li className='setMealItem' onClick={this.goStore.bind(this)}>
                            <img className='listIcon' src={house} alt='' />
                            <span>附近门店：居然装饰</span>
                            <img className='rightIcon' src={right} alt='' />
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    //    {/*预约量房*/}
    RenderSubscribe() {
        let { name, area, address, tel } = this.state
        return (
            <div
                className='RenderBox'
                style={{ background: '#fff', paddingBottom: '24px' }}
            >
                <div className='Bookingroom'>
                    <p className='BookingroomTitle'>线上招募99人免费定制刷新方案</p>
                    <p className='appointmentsNumber'>
                        -仅剩<span className='people'>3</span>人名额-
                    </p>
                    <div className='inputGroup'>
                        <input
                            type='text'
                            placeholder='您的姓名：'
                            onChange={this.inputChange.bind(this, 'name')}
                            value={name}
                        />
                        <input
                            type='number'
                            placeholder='您的房屋面积：'
                            onChange={this.inputChange.bind(this, 'area')}
                            value={area}
                        />

                        <input
                            type='text'
                            placeholder='您的小区名称：'
                            onChange={this.inputChange.bind(this, 'address')}
                            value={address}
                        />

                        <input
                            type='number'
                            placeholder='您的联系方式：'
                            onChange={this.inputChange.bind(this, 'tel')}
                            value={tel}
                        />
                    </div>

                    <div
                        className='BookingRoomBtn'
                        onClick={this.roomSaveInfo.bind(this)}
                    >
                        立即预约360°环保量房
                    </div>
                    <TextLoop
                        springConfig={{ stiffness: 340, damping: 30 }}
                        mask={true}
                        fade={true}
                        adjustingSpeed={150}
                        delay={2000}
                        interval={[2000, 2000, 2000]}
                        className='notice'
                    >
                        <p>恭喜 李先生 131****0285 预约成功</p>
                        <p>恭喜 张先生 186****1885 预约成功</p>
                        <p>恭喜 王先生 152****8854 预约成功</p>
                        <p>恭喜 杨先生 133****0842 预约成功</p>
                        <p>恭喜 刘女士 137****5587 预约成功</p>
                        <p>恭喜 程先生 131****7980 预约成功</p>
                        <p>恭喜 马先生 156****0118 预约成功</p>
                        <p>恭喜 侯先生 186****6846 预约成功</p>
                        <p>恭喜 周先生 185****8474 预约成功</p>
                        <p>恭喜 陈先生 186****6520 预约成功</p>
                    </TextLoop>
                </div>
                <div className='copyrightWrap' style={{ margin: '0', padding: '0' }}>
                    <p>
                        北京居然数装科技有限公司版权所有 &copy;2000-2021 All Rights
                        Reserved. 京ICP备140084
                    </p>
                </div>
            </div>
        )
    }

    //   业主评价
    RenderEvaluate() {
        return (
            <div className='evaluateWrap'>
                <div className='evaluate_title'>
                    <span>业主评价</span>
                    <span className='lookAll'>
                        <a href='/listOwnersVoice'>查看全部  <img className='rightIcon' src={right} alt='' /></a>
                    </span>
                </div>
                {this.state.ownersVoice.map((item, index) => {
                    return (
                        <div className='evaluateList' key={index}>
                            <div className='evaluateList_header'>
                                <img src={item.icon} className='avator' alt='' />
                                <div className='evaluateList_Info'>
                                    <span className='evaluate_name'>{item.username}</span>
                                    <div className='evaluate_time'>
                                        <span className='evaluate_time_txt'>
                                            {moment(item.logTime).format('YYYY年MM月DD日')}
                                        </span>
                                        <span className='releaseEvaluation_txt'>发布评价</span>
                                    </div>
                                </div>
                            </div>
                            <div className='evaluateList_content'>{item.content}</div>
                            <div className='evaluateList_footer'>计价单位：1㎡</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    //   店铺介绍 案例
    RenderShopIntroduction() {
        return (
            <div className='shopIntroduction'>
                <div className='shopIntroduction_header'>
                    <div className='shopIntroduction_Info'>
                        <img src={juranlOGO} alt='' className='shopLogo' />
                        <span className='shopDesc'>让家装和家居服务快乐简单</span>
                    </div>
                    <div className='shopIntroduction_btns'>
                        <a href='/protectionJuran' className='allServices'>
                            全部服务
                        </a>
                        <a href='/aboutJuran' className='aboutUs'>
                            关于我们
                        </a>
                    </div>
                </div>
                <div className='shopIntroduction_desc'>
                    <div className='shopIntroduction_desc_list'>
                        <img className='pinpai' src={pinpai} alt='' />
                        <span className='shopIntroduction_desc_txt'>居然之家自营家装</span>
                    </div>
                    <div className='shopIntroduction_desc_list'>
                        <img className='shouyexiugai' src={shouyexiugai} alt='' />
                        <span className='shopIntroduction_desc_txt'>
                            装修一口价<span className='red'>0增项</span>
                        </span>
                    </div>
                    <div className='shopIntroduction_desc_list'>
                        <img className='xianhangpeifu' src={xianhangpeifu} alt='' />
                        <span className='shopIntroduction_desc_txt'>
                            居然承诺<span className='red'>先行赔付</span>
                        </span>
                    </div>
                </div>
                <div className='border_solid'></div>
                <div className='shop_classic_cases'>
                    <div className='classic_cases_header'>
                        <span className='classic_cases_title'>经典案例</span>
                        <a href='/listCase'>查看全部  <img className='rightIcon' src={right} alt='' /></a>
                    </div>
                    <ul className='classic_cases_content'>
                        {this.state.designerCase &&
                            this.state.designerCase.map((item, index) => {
                                return (
                                    <li
                                        className='classic_cases_content_list'
                                        key={index}
                                    // onClick={this.goCaseDetail.bind(this, item.id)}
                                    >
                                        <a href={'/detailsCase/' + item.id}>
                                            <img
                                                className='classic_cases_img'
                                                src={item.cover}
                                                alt=''
                                            />
                                            <div className='classic_cases_txt'>
                                                {item.title}&nbsp; | &nbsp;{item.styleName}&nbsp; |
                                                &nbsp;{item.acreage}㎡
                                            </div>
                                        </a>
                                    </li>
                                )
                            })}
                    </ul>
                </div>
                <p className='slide_see_details'>———— 继续滑动，查看图文详情 ————</p>
            </div>
        )
    }
    clickBtn = (item, index) => {
        let type = index === 1 ? item.buttonType : item.buttonType2
        if (type == 1) {
            this.setState({
                activitieVisible: true
            })
        } else if (type == 2) {
            this.open53()
        } else if (type == 3) {
            window.location.href = index === 1 ? item.buttonUrl : item.buttonUrl2
        } else if (type == 4) {
            window.location.href = `tel:${index === 1 ? item.buttonPhone : item.buttonPhone2}`;
        }
    }
    //产品详情
    RenderrGaphicDetails() {
        return (
            <div className='gaphicDetails'>
                {this.state.detailInfo &&
                    this.state.detailInfo.map((item, index) => {
                        return (
                            <div key={index}>
                                {item.buttonName}
                                <img
                                    // className='classic_cases_img'
                                    src={item.titleUrl}
                                    // src={juranlOGO}
                                    alt=''
                                    style={{ width: '100%' }}
                                    title={item.titleAlt}
                                />
                                <div className='detailBa'>
                                    {item.imgUrl !== '' && (
                                        <Carousel
                                            // autoplay
                                            dotStyle={{
                                                width: '4px',
                                                height: '4px',
                                                background: '#666666'
                                            }}
                                        >
                                            {item.imgUrl.split(',').map((val, index) => (
                                                <img
                                                    className='banner'
                                                    key={index}
                                                    style={{
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        height: 'auto'
                                                    }}
                                                    src={val}
                                                    alt=''
                                                />
                                            ))}
                                        </Carousel>
                                    )}
                                </div>
                                <div
                                    className='DetailContentd'
                                    dangerouslySetInnerHTML={{ __html: item.content }}
                                ></div>
                                

                                <div
                                    className='ClickBanner'
                                    onClick={this.CilckdetailInfo.bind(
                                        this,
                                        item.buttonType,
                                        item.buttonUrl,
                                        item.buttonPhone
                                    )}
                                    key={index}
                                >
                                    {
                                        item.buttonImg && <img src={item.buttonImg} alt={item.buttonAlt} onClick={() => this.clickBtn(item, 1)} />
                                    }
                                </div>
                                {
                                    item.buttonImg2 && <img src={item.buttonImg2} alt={item.buttonAlt2} onClick={() => this.clickBtn(item, 2)} />
                                }
                            </div>
                        )
                    })}
            </div>
        )
    }

    RenderBottomBtns() {
        return (
            <div className='bottomBtns'>
                <div className='bottom_Btn'>
                    <img
                        className='bottom_Btn_icon1'
                        src={shouye}
                        alt=''
                        onClick={this.goHome.bind(this)}
                    />
                    <span>首页</span>
                </div>
                <div className='bottom_Btn' onClick={() => this.open53()}>
                    <img className='bottom_Btn_icon2' src={kefu} alt='' />
                    <span>客服</span>
                </div>
                <div className='bottom_Btn' onClick={() => this.open53()}>
                    <img className='bottom_Btn_icon3' src={dianhua} alt='' />
                    <span>热线</span>
                </div>
                <div
                    className='getQuote'
                    onClick={() => {
                        this.setState({ priceVisible: true })
                    }}
                >
                    获取报价
                </div>
                <div
                    className='nowSubscribe'
                    onClick={() => {
                        this.setState({ nowVisible: true })
                    }}
                >
                    立即预约
                </div>
            </div>
        )
    }

    render() {
        let {
            askVisible,
            priceVisible,
            activitieVisible,
            nowVisible,
            onLineroomVisible
        } = this.state
        let changeArea = (
            <div id='House_quickly' className='House_quickly'>
                <MyNavBar />
                {this.RenderBanner()}
                {this.RenderSetMeal()}
                <div className='DividingLine'></div>
                {this.RenderEvaluate()}
                <div className='DividingLine'></div>
                {this.RenderShopIntroduction()}
                {/* {this.RenderBannerBottom()} */}
                {this.RenderrGaphicDetails()}
                {/* <p className='dividing'></p> */}
                {this.RenderSubscribe()}
                {this.RenderBottomBtns()}
                <DesignerPopup
                    visible={askVisible}
                    title='在线预约 到店有惊喜'
                    subTitle='2'
                    cancelClick={() => this.setState({ askVisible: false })}
                    saveInfo={this.askSaveInfo.bind(this)}
                    btnTxt={['立即抢占名额']}
                    type='ask'
                />
                <DesignerPopup
                    visible={onLineroomVisible}
                    title='立即申请免费量房'
                    subTitle='2'
                    cancelClick={() => this.setState({ onLineroomVisible: false })}
                    saveInfo={this.onLineroomSaveInfo.bind(this)}
                    btnTxt={['确定']}
                    type='room'
                />

                <DesignerPopup
                    visible={priceVisible}
                    title='装修要花多少钱?'
                    subTitle='1688'
                    cancelClick={() => this.setState({ priceVisible: false })}
                    saveInfo={this.priceSaveInfo.bind(this)}
                    btnTxt={['获取报价']}
                    type='price'
                    price={98234}
                />
                <DesignerPopup
                    visible={activitieVisible}
                    title='请留下您的联系方式'
                    subTitle='30'
                    cancelClick={() => this.setState({ activitieVisible: false })}
                    saveInfo={this.activitieSaveInfo.bind(this)}
                    btnTxt={['提交']}
                    bottomdata={['居然自营', '到店礼品', '品牌联盟活动']}
                    type='activitie'
                />
                <DesignerPopup
                    visible={nowVisible}
                    title='预约TA'
                    subTitle='在线预约 尊享优先排期特权'
                    bottomdata={['上门量房', '户型解析', '动线设计', '软装设计']}
                    cancelClick={() => this.setState({ nowVisible: false })}
                    saveInfo={this.nowSaveInfo.bind(this)}
                    btnTxt={['立即预约']}
                />
            </div>
        )
        return (
            <div id='start' className='container'>
                {changeArea}
            </div>
        )
    }
}

export default withRouter(House_quickly)
