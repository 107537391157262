import React, { Component } from 'react'
import MyNavBar from '../../../components/myNavBar/index'
import phone from '../../../image/phone.png'
import dizhi from '../../../image/dizhi.png'
import '../../../css/To_store_experience.scss'
import DesignerPopup from '../../../components/designerPopup/index'
import { GetQuery, GetTel, GetAddr } from "../../../utils/index";

class To_store_experience extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    storeData: [],
    StoreImg: '',
    storeBannerUrl: '',
    roomVisible: false,
    visible1: false,
    visible: false,
    len: 10,
    page: 1,
  }

  componentWillMount() { }

  componentDidMount() {
    //线下体验tdk内容
    var meta = document.getElementsByTagName('meta')
    document.title = '装修样板房_家居装修样板房_房屋装修样板间线下体验-居然装饰'
    addMate('a', 'title', '装修样板房_家居装修样板房_房屋装修样板间线下体验-居然装饰')
    addMate(
      'b',
      'keywords',
      '装修样板房,房子装修样板,家居装修样板房,房屋装修样板间,室内装修样板房'
    )
    addMate(
      'c',
      'description',
      '居然装饰线下体验店-居然家居装修样板房，房屋装修样板间，各种主材，辅材，房屋装修效果以及各种装修风格，提供给业主真实感受。'
    )

    function addMate(id, name, content) {
      let metaData = document.getElementsByTagName('head')[0].children
      for (let i = 0; i < metaData.length; i++) {
        if (metaData[i].localName === 'meta' && metaData[i].id === id) {
          const mate = document.getElementById(id)
          document.getElementsByTagName('head')[0].removeChild(mate)
        }
      }
      let meta = document.createElement('meta')
      meta.content = content
      meta.name = name
      meta.id = id
      document.getElementsByTagName('head')[0].appendChild(meta)
    }
    this.getData()
    this.get(this.api.getStoreListImg, '').then((res) => {
      if (res.code === 0) {
        this.setState({
          StoreImg: res.data[0].imgUrl,
          storeBannerUrl: res.data[0].openUrl,
        })
      }
    })
  }
  loadPage() {
    this.setState({
      page: this.state.page + 1
    }, () => {
      this.getData()
    })
  }
  getData() {
    const { page, storeData } = this.state
    this.get(`${this.api.findStoreAll}?page=${page}&limit=${10}&${GetQuery()}`, '').then(
      (res) => {
        if (res.code === 0) {
          this.setState({
            len: res.data.length,
            storeData: page === 1 ? res.data : storeData.concat(res.data)
          })
        }
      }
    )
  }
  roomSaveInfo(obj) {
    let source = '免费量房'
    let sourceUrl = escape(window.parent.document.location);// 'page/To_store_experience'
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          roomVisible: false
        })
      }
    })
  }


  saveInfo(obj) {
    let source = '预约设计'
    //alert("---" + document.location.href)
    let sourceUrl = escape(window.parent.document.location);//'page/To_store_experience'
    this.post(`${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`, '').then(
      (res) => {
        if (res.code === 0) {
          this.setState({
            visible: false
          })
        }
      }
    )
  }

  componentWillReceiveProps() { }

  componentWillUnmount() { }

  componentDidShow() { }

  componentDidHide() { }

  // 门店列表区域
  storeList(data) {
    return Array.isArray(data) && data.length > 0 && data.map((item) => {
      return (
        <div className='storeList' key={item.id}>
          <div className='storeList_top'>
            <img className='store_img' src={item.img.split(',')[0]} alt='' />
            <div className='store_info'>
              <p>{item.name}</p>
              <a href={`tel:${item.tel}`}>
                <div className='store_phone' >
                  <img className='phone_icon' src={phone} alt='' />
                  <span className='phone' style={{ width: '100%' }} >{item.tel}</span>
                </div>
              </a>
              <div className='store_btns'>
                <span
                  className='lookHouse'
                  onClick={() => this.setState({ roomVisible: true })}
                >
                  免费量房
                </span>
                <span className='subscribeDesign' onClick={() => this.setState({ visible1: true })}>预约设计</span>
              </div>
            </div>
          </div>
          <div className='store_addresss'>
            <img src={dizhi} alt='' />
            <span alt='{item.address}'> {item.address}</span>
          </div>
        </div>
      )
    })
  }
  //底部栏
  StoreFooter() {
    return (
      <div className='StoreFooter'>
        <div className='consulting'   >
          <span>装修咨询</span>
          <a className='phoneNum' href={`tel:${GetTel()}`} >{GetTel()}</a>
          <img className='phone_icon' src={phone} alt='' />
        </div>
        <div className='address'>
          总部地址：{GetAddr()}
        </div>
      </div>
    )
  }
  render() {
    let { StoreImg, visible1, roomVisible, visible } = this.state
    let changeArea = (
      <div className='To_store_experience'>
        <img
          className='headerImg'
          src={StoreImg}
          alt=''
        // 暂时隐藏到点列表图片的跳转 
        // onClick={() => {
        //   window.location.href = storeBannerUrl
        // }}
        />
        {this.storeList(this.state.storeData)}
        {
          this.state.len === 10 ? <div className='loadMore' onClick={this.loadPage}>点击加载更多</div> :
            <div className='loadMore'>无更多内容</div>
        }
        {this.StoreFooter()}
        <DesignerPopup
          visible={roomVisible}
          title='立即申请免费上门量房'
          subTitle='2'
          cancelClick={() => this.setState({ roomVisible: false })}
          saveInfo={this.roomSaveInfo.bind(this)}
          btnTxt={['免费量房']}
          type='room'
        />
        <DesignerPopup
          visible={visible1}
          title='预约我的专属设计师'
          subTitle='在线预约 尊享优先排期特权'
          bottomdata={['免费量房', '户型解析', '软装咨询']}
          cancelClick={() => this.setState({ visible1: false })}
          saveInfo={this.saveInfo.bind(this)}
          btnTxt={['立即预约']}
        />
        <DesignerPopup
          visible={visible}
          title='预约TA'
          type={'ta'}

          subTitle='在线预约 尊享优先排期特权'
          bottomdata={['上门量房', '户型解析', '动线设计', '软装设计']}
          cancelClick={() => this.setState({ visible: false })}
          saveInfo={this.saveInfo.bind(this)}
          btnTxt={['立即预约']}
        />
      </div>
    )

    return (
      <div className='container'>
        <MyNavBar />
        {changeArea}
    
      </div>
    )
  }
}
export default To_store_experience
