import React, { Component } from 'react'
import { xin } from '../../image/commentsDetail/index'
import moment from "moment";
import { withRouter } from 'react-router-dom'
import './tab.css'
import {GetQuery, locationHref} from "../../utils/index"; //路由配置

class tab2 extends Component {
	state = {
		ownersVoiceList: [],
		len: 10,
		page: 1,
	}
	componentDidMount() {
		this.getData()
	}
	getData(){
		this.get(`${this.api.getownersVoice}?page=${this.state.page}&limit=${10}&${GetQuery()}`, '').then(
			(res) => {
				this.setState({
					len: res.data.length,
					ownersVoiceList: this.state.page === 1 ? res.data : this.state.ownersVoiceList.concat(res.data),
				})
			}
		)
	}
	goDetails(id) {
		// this.props.history.push({
		// 	pathname: '/detailsOwnersVoice',
		// 	id: id
		// })
		locationHref('/detailsOwnersVoice/'+id)
	}
	loadPage = () => {
		this.setState({
			page: this.state.page + 1
		}, () => {
			this.getData()
		})
	}
	renderowenr() {
		let { ownersVoiceList } = this.state;
		return (
			<div className="tab2_content">
				{
					ownersVoiceList && ownersVoiceList.map((item, key) => {
						let i = item.logTime
						let statusPassTime = moment((i)).format('YYYY年MM月DD日');
						return (
							<div key={key} className="neighbor_case_content"
								key={item.id} onClick={this.goDetails.bind(this, item.id)}
							>
								<div className="neighbor_case_content_leftt">
									<img className="touxiang" src={item.icon} alt="" />
								</div>
								<div className="neighbor_case_content_rightt">
									<div className="neighbor_case_content_r">
										<div className="name_" key={key}>{item.username}</div>
										<div className="b_ll">{statusPassTime}</div>
										<span className="pingfenb">
											<span className="pingfen_fen">评分：</span>
											<span className="pingfen_xin" item={item.score}>
												{
													Array.from({ length: item.score }, (_, i) => i).map((a, index) => {
														return (
															<img src={xin} alt='' />
														)
													})
												}
											</span>
										</span>
									</div>
									<div className="neighbor_case_content_right_b">
									</div>
									<div className="neighbor_case_content_right_c">
										<span className="xaingjiee">
											<div><span>设计：</span><span key={key}>{item.designScore}.0</span></div>
											<div><span>服务：</span><span>{item.serviceScore}.0</span></div>
											<div><span>施工：</span><span>{item.buildScore}.0</span></div>
										</span>
									</div>
									<div className="neighbor_case_content_right_dd">
										{item.content}
									</div>
								</div>
							</div>
						)
					})
				}
			</div>
		)
	}
	render() {
		return (
			<div>
				{this.renderowenr()}
				{
					this.state.len === 10 ? <div className='loadMore' onClick={this.loadPage}>点击加载更多</div> :
						<div className='loadMore'>无更多内容</div>
				}
			</div>
		)
	}
}
export default withRouter(tab2)
