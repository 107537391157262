import axios from "axios";
import {getRootUrl} from "./http";
import {GetQuery} from "../utils/index";

let baseURL = getRootUrl();
//轮播接口
export const getSwiperList = () => {
    return axios.get(baseURL + `/api/websiteCarousel/findAll?${GetQuery()}&type=0`);
};
//广告位接口
// export const getwebsitePoster = () => {
//     return axios.get(baseURL + "/api/websitePoster/findAll");
// };
//业主之声接口
export const getownerList = () => {
    return axios.get(baseURL + "/api/ownersVoice/getList");
};

export const getStyleTest = () => {
    return axios.get(baseURL + `/api/websiteInvestigation/findAll?${GetQuery()}`);//风格测试
};

export const getStyleResult = () => {
    return axios.get(baseURL + "/api/websiteInvestigation/findAllAnswer");//风格测试结果
};

export const getStoreList = () => {
    return axios.get(baseURL + `/api/websiteStoreAdvertising/findStoreAll?${GetQuery()}`);//门店列表
};

export const getDesignerCaseList = () => {
    return axios.get(baseURL + "/api/website/getDesignerCaseList");//案例列表
};

//查询门店宣传图 接口
export const websiteStoreAdvertising = () => {
    return axios.get(baseURL + "/api/websiteStoreAdvertising/findAll");
};
//查询门店列表接口
export const websiteStore = () => {
    return axios.get(baseURL + `/api/websiteStoreAdvertising/findStoreAll?${GetQuery()}`);
};

//查询公司动态列表
export const websiteNews = (query) => {
    return axios.get(baseURL + `/api/websiteNews/findAll?${GetQuery()}&${query}`);
};
//查询业主详情内推荐列表
export const websiteData = () => {
    return axios.get(baseURL + `/api/websiteData/findAllByTypeId?${GetQuery()}`);
};

// 公司简介 /websiteCompany/ findAll
export const websiteCompanyInfo = () => {
    return axios.get(baseURL + `/api/websiteCompany/findAll?${GetQuery()}`);
};
// 咨询电话
export const websiteCompanyTel = () => {
    return axios.get(baseURL + `/api/serviceTelPhone/findTel?${GetQuery()}`);
};
// 装修流程
export const decorationProcess = () => {
    return axios.get(baseURL + `/api/decorationProcess/getList`);
};
// 首页栏目管理
export const tabfindAll = () => {
    return axios.get(baseURL + `/api/websiteTab/findAll?${GetQuery()}&type=0`);
};
// 合作商管理
export const websiteBusinessfindAll = () => {
    return axios.get(baseURL + `/api/websiteBusiness/findAll`);
};

// 联系我们
export const websiteOwer = () => {
    return axios.get(baseURL + `/api/websiteOwer/findAll?${GetQuery()}`);
};
// 产品分类列表
export const websiteProduceCategory = () => {
    return axios.get(baseURL + `/api/websiteProduceCategory/getList?${GetQuery()}`);
};
export const websiteIndex = () => {
    return axios.get(baseURL + `/api/websiteIndex/findAll?${GetQuery()}`);
};
// 心选广告位
export const websitePosterChoose = () => {
    return axios.get(baseURL + `/api/websitePosterChoose/findAll?${GetQuery()}&type=0`);
};
export const selectAllCity = () => {
    return axios.get(baseURL + `/api/selectAllCity`);
};
//移动端活动专区列表
export const webSiteAdvAdmin = () => {
    return axios.get(baseURL + `/api/webSiteAdvAdmin/findAll?${GetQuery()}`);
};
