//根据路由路径加载相应的视图组件
import React from "react";
//引路由内置组价
import {Route, Switch} from "react-router-dom";
//引入ScrollToTop组件
import ScrollToTop from '../components/scrollToTop/index'
import {cityDataPinyin} from "../utils/cityData";

const RouterView = (props) => {


        //重定向
        let reds = props.routes.filter((item) => item.redirect);
        //没有重定向
        let coms = props.routes.filter((item) => item.component);

        //判断url 是否存在城市
        let path = window.location.pathname;
        let cityPinyin = path.split('/')[1];
        let filterRes = cityDataPinyin.filter(function (c) {
            return c.pinyin.indexOf(cityPinyin) !== -1;
        })

        if (cityPinyin === '') {
            let pinyin = ''
            let cityData = localStorage.getItem("LocalCity");
            cityData = JSON.parse(cityData)

            //缓存存在城市数据
            if (cityData) {
                //根据定位数据修改url
                let result = cityDataPinyin.filter(function (c) {
                    return cityData.city.indexOf(c.name) !== -1;
                })
                pinyin = result ? result[0].pinyin : 'Beijing';
            }else {
                //缓存不存在
                pinyin = 'Beijing'
            }

            cityPinyin = pinyin

            window.location.href = window.location.origin + "/" + pinyin + path
            return
        } else {
            if (filterRes.length > 0) { //存在城市拼音  修改当前定位数据
                const city = {
                    provinceId: filterRes[0].pid,
                    cityId: filterRes[0].id,
                    city: filterRes[0].name,
                    loading: 1,
                }
                // //设置script 统计代码
                // setBdTongj(city.cityId, city.provinceId);
                localStorage.setItem('LocalCity', JSON.stringify(city))
            } else { //不存在城市拼音
                let cityData = localStorage.getItem("LocalCity");
                cityData = JSON.parse(cityData)
                if (cityData) {
                    //根据定位数据修改url
                    let result = cityDataPinyin.filter(function (c) {
                        return cityData.city.indexOf(c.name) !== -1;
                    })
                    let pinyin = result ? result[0].pinyin : 'Beijing';
                    window.location.href = window.location.origin + "/" + pinyin + path
                    return
                }
            }
        }

        return (
            <ScrollToTop>
                <Switch>
                    {/* 没有重定向 */}
                    {coms.map((item, index) => {
                        //render component 加载路由视图组件
                        return (
                            <Route
                                key={index}
                                path={'/' + cityPinyin + item.path}
                                component={(routerProps) => {
                                    if (item.children) {
                                        //存在路由嵌套  //采用递归
                                        return (
                                            <item.component
                                                {...routerProps}
                                                routes={item.children}
                                            />
                                        );
                                    } else {
                                        //不存在路由嵌套
                                        return <item.component {...routerProps} />;
                                    }
                                    // }
                                }}
                            ></Route>
                        );
                    })}

                    {/* 路由重定向  */}
                    {/*{reds.map((item, index) => {*/}
                    {/*    return <Redirect to={item.redirect} key={index}></Redirect>;*/}
                    {/*})}*/}
                </Switch>
            </ScrollToTop>
        );
    }
;

export default RouterView;
