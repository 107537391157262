function GetQuery() {
    let LocalCity = localStorage.getItem("LocalCity") ? JSON.parse(localStorage.getItem("LocalCity")) : {}
    return `provinceId=${LocalCity.provinceId}&cityId=${LocalCity.cityId}`;
    // return `provinceId=2&cityId=36`;
}

function GetQueryObj() {
    let LocalCity = localStorage.getItem("LocalCity") ? JSON.parse(localStorage.getItem("LocalCity")) : {}
    return {
        provinceId: LocalCity.provinceId,
        cityId: LocalCity.cityId,
        city: LocalCity.city,
    }
}

function GetTel() {
    let arr = localStorage.getItem("tel") ? JSON.parse(localStorage.getItem("tel")) : ['400-686-1997']
    return arr[0]
}

function GetAddr() {
    let arr = localStorage.getItem("addr") && JSON.parse(localStorage.getItem("addr")).length > 0 ? JSON.parse(localStorage.getItem("addr")) : [{
        address: '北京市朝阳区十八里店乡周家庄8号居然靓屋灯饰城'
    }]
    return arr[0].address
}

export {
    GetQuery,
    GetTel,
    GetAddr,
    GetQueryObj
}

export function locationHref(param) {
    window.location.href = window.location.origin + param
}

/**
 * 跳转网址 不包含地区
 * @param param
 */
export const editUrl = (param) => {
    let pathname = window.location.pathname;
    pathname = pathname.split('/')[1] + '/' + pathname.split('/')[2]
    window.history.replaceState(null, null, window.location.origin + "/" + pathname + "/" + param)
}