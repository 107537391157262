import React, { Component } from 'react'
import bg_top from '../../../image/aboutJuran/bg_top.png'
import bg_six from '../../../image/aboutJuran/bg_six.png'
import bg_reward from '../../../image/aboutJuran/bg_reward.png'
import zshu from '../../../image/aboutJuran/zshu.png'
import MyNavBar from '../../../components/myNavBar/index'
import phone from '../../../image/phone.png'
import nian2020 from '../../../image/aboutJuran/2020.png'
import nian2019 from '../../../image/aboutJuran/2019.png'
import nian2018 from '../../../image/aboutJuran/2018.png'
import nian2016 from '../../../image/aboutJuran/2016.png'
import nian2014 from '../../../image/aboutJuran/2014.png'
import nian2013 from '../../../image/aboutJuran/2013.png'
import nian2012 from '../../../image/aboutJuran/2012.png'
import nian2011 from '../../../image/aboutJuran/2011.png'
import nian2007 from '../../../image/aboutJuran/2007.png'
import Hb from '../../../image/video/Hb.png'
import bofang from '../../../image/video/bofang.png'
import '../../../css/aboutJuran.css'
import { GetQuery, GetTel, GetAddr } from "../../../utils/index"; //路由配置
import { websiteCompanyInfo } from '../../../request/index'

export default class aboutJuran extends Component {
    state={
        info:{}
    }
    componentWillMount() {
        window.onbeforeunload = function () {
            document.documentElement.scrollTop = 0;  //ie下
            document.body.scrollTop = 0;  //非ie
            document.documentElement.scrollTop = document.body.scrollTop = 0;
        }
        this.getwebsiteCompanyInfo()
    }
    componentDidMount() {
        //关于居然tdk内容
        var meta = document.getElementsByTagName('meta')
        document.title = '居然装饰怎么样_居然之家装饰怎么样_居然乐屋装修好不好-居然装饰'
        addMate('a', 'title', '居然装饰怎么样_居然之家装饰怎么样_居然乐屋装修好不好-居然装饰')
        addMate(
            'b',
            'keywords',
            '居然装饰怎么样,居然装饰好不好,居然之家装饰怎么样,居然乐屋好不好,居然装饰公司怎么样'
        )
        addMate(
            'c',
            'description',
            '居然装饰公司怎么样？居然之家装饰怎么样？居然装饰是居然之家自营的装修公司，居然装饰本着F“让家装变得快乐简单”的企业使命打造的数字化，智能化家装服务平台。'
        )

        function addMate(id, name, content) {
            let metaData = document.getElementsByTagName('head')[0].children
            for (let i = 0; i < metaData.length; i++) {
                if (metaData[i].localName === 'meta' && metaData[i].id === id) {
                    const mate = document.getElementById(id)
                    document.getElementsByTagName('head')[0].removeChild(mate)
                }
            }
            let meta = document.createElement('meta')
            meta.content = content
            meta.name = name
            meta.id = id
            document.getElementsByTagName('head')[0].appendChild(meta)
        }
    }
    getwebsiteCompanyInfo() {
        websiteCompanyInfo().then((res) => {
            this.setState({
                info: res.data.data
            })
        })
    }
    video() {
        let videoplay = this.refs.videoPalse //视频
        let videoPalse = this.refs.videoplay //video播放标签
        if (videoplay.paused) {
            //点击视频的时候如果暂停状态，则让其播放
            videoplay.play()
            videoPalse.style.display = 'none'
        } else {
            videoplay.pause()
            videoPalse.style.display = 'block'
        }
    }
    //底部栏
    StoreFooter() {
        return (
            <div className='StoreFooter'>
                <div className='consulting'>
                    <span>装修咨询</span>
                    <a href={`tel:${GetTel()}`} className='phoneNum'>{GetTel()}</a>
                    <img className='phone_icon' src={phone} alt='' />
                </div>
                <div className='address'>
                    总部地址：{GetAddr()}
        </div>
            </div>
        )
    }
    render() {
        const { info}=this.state
        return (
            <div className="container">
                <MyNavBar />
                <div className="container-title">
                    <img className="container-title" src={bg_top} alt="" />
                </div>
                <div className="container_content">
                    <div className="container_content_CompanyProfile">
                        <div className="Company">公司简介</div>
                        <div className="Profile">
                            <div dangerouslySetInnerHTML={{
                                __html: info.infos
                            }}>
                            </div>
                            {/* <div className="Profile_t">
                                居然装饰首推预交底、清单式报价，并将零增项、零延期、环保承诺写入合同。始终践行品质装修，让家装和家居服务快乐简单。
                            </div> */}
                        </div>
                    </div>
                    <div id='content_videos'>
                        <video
                            poster={Hb}
                            ref='videoPalse'
                            className='videos'
                            controls='controls'
                        >
                            <source id='videos' src={'http://shigongapp.oss-cn-beijing.aliyuncs.com/appPack/202107071418.mp4'}></source>
                        </video>
                        <div className='video-img'>
                            <img
                                id='plays'
                                ref='videoplay'
                                onClick={() => {
                                    this.video()
                                }}
                                src={bofang}
                                alt=''
                            />
                        </div>
                    </div>
                    <div className="sixUnified">
                        <div className="xUnified">六项统一</div>
                        <div className="sixs">
                            <img className="sixs" src={bg_six} alt="" />
                        </div>
                    </div>
                    <div className="developmentCourse">
                        <div className="development">发展历程</div>
                        <div className="Course">
                            <div className="Course_content">
                                <div className="Course_content_img">
                                    <img className="Course_content_img" src={nian2007} alt="" />
                                </div>
                                <div className="Course_content_text">
                                    <div>2007年，</div>
                                    乐屋装饰品牌诞生，第一家-金源店开业，同步推出“预交底”“零增项”；
                                </div>
                            </div>
                            <div className="Course_content">
                                <div className="Course_content_img">
                                    <img className="Course_content_img" src={nian2011} alt="" />
                                </div>
                                <div className="Course_content_text">
                                    <div>2011年，</div>
                                    居然乐屋快捷家装6S店成立，荣获“京城百姓信赖的品牌家装公司”；
                                </div>
                            </div>
                            <div className="Course_content">
                                <div className="Course_content_img">
                                    <img className="Course_content_img" src={nian2012} alt="" />
                                </div>
                                <div className="Course_content_text">
                                    <div>2012年，</div>
                                    获得建筑装饰装修工程设计与施工二级资质；
                                </div>
                            </div>
                            <div className="Course_content">
                                <div className="Course_content_img">
                                    <img className="Course_content_img" src={nian2013} alt="" />
                                </div>
                                <div className="Course_content_text">
                                    <div>2013年， </div>
                                    行业首推“无延期”，杜绝客户装修延期困扰；
                                </div>
                            </div>
                            <div className="Course_content">
                                <div className="Course_content_img">
                                    <img className="Course_content_img" src={nian2014} alt="" />
                                </div>
                                <div className="Course_content_text">
                                    <div>2014年， </div>
                                    行业首推“清单式报价”颠覆报价混乱局面，顶层设计中心重磅成立；
                                </div>
                            </div>
                            <div className="Course_content">
                                <div className="Course_content_img">
                                    <img className="Course_content_img" src={nian2016} alt="" />
                                </div>
                                <div className="Course_content_text">
                                    <div>2016年， </div>
                                    行业首推“施工服务大包”，连续九年荣膺“中国品牌500强”；
                                </div>
                            </div>
                            <div className="Course_content">
                                <div className="Course_content_img">
                                    <img className="Course_content_img" src={nian2018} alt="" />
                                </div>
                                <div className="Course_content_text">
                                    <div>2018年， </div>
                                    行业首推“施工服务大包”，连续九年荣膺“中国品牌500强”；
                                </div>
                            </div>
                            <div className="Course_content">
                                <div className="Course_content_img">
                                    <img className="Course_content_img" src={nian2019} alt="" />
                                </div>
                                <div className="Course_content_text">
                                    <div>2019年， </div>
                                    装饰与超市完全融合，装饰公司定义2019年为“口碑服务年”；
                                </div>
                            </div>
                            <div className="Course_content">
                                <div className="Course_content_img">
                                    <img className="Course_content_img" src={nian2020} alt="" />
                                </div>
                                <div className="Course_content_text">
                                    <div>2020年， </div>
                                    好工程，创口碑；
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ConstructionQualification">
                        <div className="Construction">施工资质</div>
                    </div>
                </div>
                <div className="Qualification">
                    <img className="Qualification" src={zshu} alt="" />
                </div>
                <div className="bg_reward">
                    <img className="bg_reward" src={bg_reward} alt="" />
                </div>
                <div className="StoreFooter">
                    {this.StoreFooter()}
                </div>
             
            </div>
        )
    }
}
