import React, {Component} from 'react'
import {Modal, Input} from 'antd'
import './index.scss'
import gbtn from '../../image/gbtn_close.png'
import iconClock from '../../image/iconClock.png'
import {Toast} from 'antd-mobile'
import {connect} from 'react-redux'
import {minusAction} from '../../store/actions'
import Countdown from 'react-countdown'

const renderer = ({days, hours, minutes}) => {
  return (
    <span>
      <span className='storage_Bottom_span'>{days}</span>天
      <span className='storage_Bottom_span'> {hours}</span>
      小时
      <span className='storage_Bottom_span'> {minutes}</span>分
    </span>
  )
}

class storageDialog extends Component {
  state = {
    name: '',
    area: '',
    tel: ''
  }
  saveInfo() {
    let {name, area, tel} = this.state
    let obj = {
      name: name,
      area: area,
      tel: tel
    }
    if (tel === '') {
      this.setState({
        telShow: true
      })
    } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(tel)) {
      Toast.info('手机号码格式不正确~', 1)
    } else {
      this.props.saveInfo(obj)
      this.setState({
        name: '',
        area: '',
        tel: ''
      })
      this.props.minus()
    }
  }

  inputChange(type, e) {
    if (type === 1) {
      this.setState({
        name: e.target.value
      })
    } else if (type === 2) {
      this.setState({
        area: e.target.value
      })
    } else {
      this.setState({
        tel: e.target.value
      })
    }
  }
  componentWillMount() {
  
  }
  // componentDidMount() {
  //   //倒计时
  //   const end = Date.parse(new Date('2051/11/29 24:00'))
  //   this.countFun(end)
  // }

  // componentWillUnmount() {
  //   // this.setState = (state, callback) => {
  //   //   return
  //   // }
  //   clearInterval(this.timer);
  
  // }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }



  render() {
    let {telShow} = this.state
    const { title, visible, btnTxt, cancelClick, content, subTitles} = this.props
    return (
      <div>
        <Modal
          centered
          visible={visible}
          footer={null}
          onCancel={cancelClick}
          closable={false}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '.48rem'
            }}
          >
            <span>{content}</span>
          </div>
          <div
            className='storage_test-title '
            style={{
              display: 'flex',
              left: '50%',
              transform: 'translateX(-50%)',
              position: 'fixed'
            }}
          >
            <div
              style={{
                marginTop: '1.5rem',
                width: '8.533333rem',
                textAlign: 'center'
              }}
            >
              <div className='storage_title_'>{title}</div>

              <div className='storage_title_f'>
                {
                  subTitles ? subTitles:<div>
                    今日仅限{' '}
                    <span
                      style={{
                        color: '#0166B3',
                        margin: '0 .106667rem 0 .106667rem'
                      }}
                    >
                      {this.props.num}
                    </span>
                    个名额
                  </div>
                }
            
              </div>
              <p>
                <Input
                  className='storage_input'
                  placeholder='您的姓名'
                  style={{width: '2.666667rem'}}
                  value={this.state.name}
                  onChange={this.inputChange.bind(this, 1)}
                  ref={(myInput) => (this.myInput = myInput)}
                />
              </p>

              <p>
                <Input
                  className='storage_input'
                  placeholder='手机号码'
                  style={{width: '2.666667rem'}}
                  value={this.state.tel}
                  onChange={this.inputChange.bind(this, 3)}
                  ref={(myInput) => (this.myInput = myInput)}
                  type='number'
                />
              </p>
              <p
                style={{opacity: telShow === true ? 1 : 0}}
                className='errorTel'
              >
                您的手机号不能为空哦
              </p>
              <div
                className='storage_button'
                id='button_'
                onClick={this.saveInfo.bind(this)}
              >
                {btnTxt}
              </div>

              <div className='storage_Bottom'>
                <img className='iconClock' src={iconClock} alt='' />
                <p className='storage_Bottom_txt'>
                  剩余时间：
                  <Countdown
                    date={Date.now() + 200000000}
                    renderer={renderer}
                  />
                </p>
              </div>
            </div>
          </div>
          <p className='storage_dw' onClick={cancelClick}>
            <img src={gbtn} alt='' />
          </p>
        </Modal>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    num: state.counterReducer.num
  }
}

function mapDispatchToProps(dispatch) {
  return {
    minus: () => {
      dispatch(minusAction())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(storageDialog)
