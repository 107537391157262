import React, { Component } from 'react'
import MyNavBar from '../../../components/myNavBar'
import './index.scss'
import seenEye from '../../../image/seenEye.png'
import filter_icon_more from '../../../image/filter_icon_more.png'
import {editUrl, GetQuery, locationHref} from "../../../utils/index";
import PageComponent from "../../../utils/page.jsx";

export default class CaseList extends Component {
  state = {
    cityData: [
      {
        value: '110101',
        label: '东城区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110102',
        label: '西城区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110105',
        label: '朝阳区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110106',
        label: '丰台区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110107',
        label: '石景山区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110108',
        label: '海淀区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110109',
        label: '门头沟区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110111',
        label: '房山区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110112',
        label: '通州区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110113',
        label: '顺义区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110114',
        label: '昌平区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110115',
        label: '大兴区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110116',
        label: '怀柔区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110117',
        label: '平谷区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110118',
        label: '密云区',
        cityCode: '110100',
        provinceCode: '110000'
      },
      {
        value: '110119',
        label: '延庆区',
        cityCode: '110100',
        provinceCode: '110000'
      }
    ],
    areaData: [
      {
        id: 1,
        min: 0,
        max: 60
      },
      {
        id: 2,
        min: 60,
        max: 80
      },
      {
        id: 3,
        min: 80,
        max: 120
      },
      {
        id: 4,
        min: 120,
        max: 200
      },
      {
        id: 5,
        min: 200,
        max: 400
      },
      {
        id: 6,
        min: 400,
        max: 0
      }
    ],
    houseData: [
      {
        id: 1,
        label: '开工'
      },
      {
        id: 2,
        label: '前期'
      },
      {
        id: 3,
        label: '中期'
      },
      {
        id: 4,
        label: '后期'
      },
      {
        id: 5,
        label: '竣'
      }
    ],
    cityFilterShow: false,
    areaFilterShow: false,
    styleFilterShow: false,
    houseFilterShow: false,
    cityLabel: '区域',
    areaLabel: '面积',
    styleLabel: '风格',
    houseLabel: '户型',
    caseListData: [],
    filterCaseListData: [],
    DecorateStyle: [],
    search: {
      houseType: '',
      style: '',
      acreageStart: '',
      acreageEnd: '',
    },
    titleName: null,
    page: 1,
    len: 10,
  }
  componentDidMount() {
    this.getDesignerCaseList()
    // 户型下拉列表
    this.get(this.api.getHouseType, '').then((res) => {
      if (res.code === 0) {
        this.setState({
          houseData: res.data
        })
      }
    })

    // 装修风格
    this.get(this.api.getDecorateStyle, '').then((res) => {
      if (res.code === 0) {
        this.setState({
          DecorateStyle: res.data
        })
      }
    })

    //修找灵感tdk内容
    document.title = '装修案例效果图_装修效果图图片大全_装修设计效果图-居然装饰'
    addMate('a', 'title', '装修案例效果图_装修效果图图片大全_装修设计效果图-居然装饰')
    addMate(
      'b',
      'keywords',
      '装修案例,装修效果图,装修设计效果图,装修效果图图片大全'
    )
    addMate(
      'c',
      'description',
      '居然装饰装修效果图图片大全、囊括新中式、法式轻奢、美式极简、北欧经典等多样装修案例装修风格效果.家装效果图、室内装修效果图等,还有客厅、卧室、厨房、卫生间、电视背景墙等各类装修效果图大全。'
    )

    function addMate(id, name, content) {
      let metaData = document.getElementsByTagName('head')[0].children
      for (let i = 0; i < metaData.length; i++) {
        if (metaData[i].localName === 'meta' && metaData[i].id === id) {
          const mate = document.getElementById(id)
          document.getElementsByTagName('head')[0].removeChild(mate)
        }
      }
      let meta = document.createElement('meta')
      meta.content = content
      meta.name = name
      meta.id = id
      document.getElementsByTagName('head')[0].appendChild(meta)
    }
  }
  loadPage = () => {
    this.setState({
      page: this.state.page + 1
    }, () => {
      this.getDesignerCaseList()
    })
  }
  getDesignerCaseList() {
    const { search, page, caseListData } = this.state
    let str = ''
    for (const i in search) {
      if (search[i]) {
        str = str + `&${i}=${search[i]}`
      }
    }
    this.get(`${this.api.getDesignerCaseList}?page=${page}&limit=${10}&${str}&${GetQuery()}`, '').then(
      (res) => {
        if (res.data.code === 0) {
          this.setState({
            len: res.data.data.length,
            caseListData: page === 1 ? res.data.data : caseListData.concat(res.data.data),
            filterCaseListData: page === 1 ? res.data.data : this.state.filterCaseListData.concat(res.data.data)
          })
        }
      }
    )
  }

  goDetail(id) {
    locationHref('/detailsCase/' + id)
  }

  openFilter(type) {
    let {
      cityFilterShow,
      areaFilterShow,
      styleFilterShow,
      houseFilterShow
    } = this.state
    if (type === 1) {
      if (houseFilterShow === true) {
        this.setState({
          houseFilterShow: false
        })
      } else {
        this.setState({
          houseFilterShow: true,
          areaFilterShow: false,
          cityFilterShow: false,
          styleFilterShow: false
        })
      }
    } else if (type === 2) {
      if (cityFilterShow === true) {
        this.setState({
          cityFilterShow: false
        })
      } else {
        this.setState({
          cityFilterShow: true,
          areaFilterShow: false,
          styleFilterShow: false,
          houseFilterShow: false
        })
      }
    } else if (type === 3) {
      if (areaFilterShow === true) {
        this.setState({
          areaFilterShow: false
        })
      } else {
        this.setState({
          areaFilterShow: true,
          cityFilterShow: false,
          styleFilterShow: false,
          houseFilterShow: false
        })
      }
    } else {
      if (styleFilterShow === true) {
        this.setState({
          styleFilterShow: false
        })
      } else {
        this.setState({
          styleFilterShow: true,
          areaFilterShow: false,
          cityFilterShow: false,
          houseFilterShow: false
        })
      }
    }
  }

  onaclickName(name) {
    this.setState({ titleName: name })
    document.title = name + '装修效果图_' + name + '装修案例-居然装饰'
  }
  componentWillUnmount() {
    this.setState({ titleName: '' })
  }
  filterValue(label, type, id) {
    const { search } = this.state
    this.openFilter(type)
    if (type === 1) {
      this.setState({
        houseLabel: label
      })
      if (label === '全部户型') {
        search['houseType'] = ''

        editUrl("/listCase/all")
        document.title = '全部户型装修效果图_全部户型装修案例-居然装饰'
      } else {
        search['houseType'] = id
        editUrl("/listCase/" + id)
        // let list = []
        // this.state.caseListData.filter((item) => {
        //   if (label === item.houseType) {
        //     list.push(item)
        //   }
        // })

      }
    } else if (type === 2) {
      //区域
      // this.setState({
      //   cityLabel: label
      // })
      // if (label === '全部区域') {
      //   this.getDesignerCaseList()
      //   window.history.replaceState(null, null, "/listCase/all");
      //   document.title = '全部区域装修效果图_全部区域装修案例-居然装饰'
      // } else {
      //   window.history.replaceState(null, null, "/listCase/" + id);

      // }
    } else if (type === 3) {
      //面积
      let _this = this
      if (label === '不限') {
        search['acreageStart'] = ''
        search['acreageEnd'] = ''

        editUrl("/listCase/all")
        document.title = '全部面积装修效果图_全部面积装修案例-居然装饰'
        this.setState({
          areaLabel: `不限`
        })
      } else {
        editUrl("/listCase/" + id)
        search['acreageStart'] = label.min === 0 ? 1 : label.min
        search['acreageEnd'] = label.max === 0 ? 1000000 : label.max


        let list = []
        this.state.caseListData.forEach((item) => {
          if (label.min === 0 && item.acreage < label.max) {
            list.push(item)
            _this.setState({
              areaLabel: `${label.max}m²以下`
            })
          } else if (label.max === 0 && item.acreage > label.min) {
            list.push(item)
            _this.setState({
              areaLabel: `${label.min}m²以上`
            })
          } else if (item.acreage < label.max && item.acreage > label.min) {
            list.push(item)
            _this.setState({
              areaLabel: `${label.min}~${label.max}m²`
            })
          } else {
            if (label.min === 0) {
              _this.setState({
                areaLabel: `${label.max}m²以下`
              })
            } else if (label.max === 0) {
              _this.setState({
                areaLabel: `${label.min}m²以上`
              })
            } else {
              _this.setState({
                areaLabel: `${label.min}~${label.max}m²`
              })
            }

          }
        })

      }
    } else {
      // 4.风格
      this.setState({
        styleLabel: label
      })
      if (label === '不限') {
        editUrl("/listCase/all")
        document.title = '全部风格装修效果图_全部风格装修案例-居然装饰'
        search['style'] = ''
      } else {
        editUrl("/listCase/" + id)
        search['style'] = id

      }
    }
    this.setState({
      search: search,
      page:1,
      caseListData:[],
      filterCaseListData:[]
    }, () => {
      this.getDesignerCaseList()
    }
    )
  }

  RenderAreaLabel(item) {
    let changeArea
    if (item.min === 0) {
      changeArea = (
        <span className='filterWrap_list_label'>{item.max}m²以下</span>
      )
    } else if (item.max === 0) {
      changeArea = (
        <span className='filterWrap_list_label'>{item.min}m²以上</span>
      )
    } else {
      changeArea = (
        <span className='filterWrap_list_label'>
          {item.min}~{item.max}m²
        </span>
      )
    }
    return changeArea
  }
  RenderFilter() {
    let {
      cityFilterShow,
      areaFilterShow,
      styleFilterShow,
      houseFilterShow,
      cityData,
      areaData,
      houseData,
      DecorateStyle,
      cityLabel,
      areaLabel,
      houseLabel,
      styleLabel
    } = this.state
    return (
      <div className='filterWrap'>
        <ul className='filter_header'>
          <li
            className='filterWrap_title'
            onClick={this.openFilter.bind(this, 1)}
          >
            <span
              className='filterWrap_name'
              style={{
                color: houseFilterShow === true ? '#005BAB' : '#000000',
                fontWeight: houseFilterShow === true ? '600' : '400'
              }}
            >
              {houseLabel}
            </span>
            <img
              className='filter_icon_more'
              src={filter_icon_more}
              alt=''
            />
          </li>
          <li
            className='filterWrap_title'
            onClick={this.openFilter.bind(this, 4)}
          >
            <span
              className='filterWrap_name'
              style={{
                color: styleFilterShow === true ? '#005BAB' : '#000000',
                fontWeight: styleFilterShow === true ? '600' : '400'
              }}
            >
              {styleLabel}
            </span>
            <img
              className='filter_icon_more'
              src={filter_icon_more}
              alt=''
            />
          </li>
          <li
            className='filterWrap_title'
            onClick={this.openFilter.bind(this, 3)}
          >
            <span
              className='filterWrap_name'
              style={{
                color: areaFilterShow === true ? '#005BAB' : '#000000',
                fontWeight: areaFilterShow === true ? '600' : '400'
              }}
            >
              {areaLabel}
            </span>
            <img
              className='filter_icon_more'
              src={filter_icon_more}
              alt=''
            />
          </li>
          {/* <li
            className='filterWrap_title'
            onClick={this.openFilter.bind(this, 2)}
          >
            <span
              className='filterWrap_name'
              style={{
                color: cityFilterShow === true ? '#005BAB' : '#000000',
                fontWeight: cityFilterShow === true ? '600' : '400'
              }}
            >
              {cityLabel}
            </span>
            <img
              className='filter_icon_more'
              src={filter_icon_more}
              alt=''
            />
          </li> */}
        </ul>
        <ul
          className='filterWrap_list'
          style={{
            display:
              cityFilterShow === false &&
                areaFilterShow === false &&
                styleFilterShow === false &&
                houseFilterShow === false
                ? 'none'
                : 'block'
          }}
        >
          <li
            className='filterWrap_list_wrap'
            // onClick={this.onaclickName.bind(this,'全部户型')}
            style={{ opacity: houseFilterShow === true ? '1' : '0' }}
          >
            <span
              className='default_item'
              onClick={this.filterValue.bind(this, '全部户型', 1)}
            >
              全部户型
            </span>
            {houseData.map((item) => {
              return (
                <div
                  className='filterWrap_list_item'
                  key={item.name}
                  onClick={this.filterValue.bind(this, item.name, 1, item.id)}
                >
                  <span
                    onClick={this.onaclickName.bind(this, item.name)}
                    className='filterWrap_list_label'>{item.name}</span>
                </div>
              )
            })}
          </li>
          {/*风格*/}
          <li
            className='filterWrap_list_wrap'
            // onClick={this.onaclickName.bind(this,'不限')}
            style={{ opacity: styleFilterShow === true ? '1' : '0' }}
          >
            <span
              className='default_item'
              onClick={this.filterValue.bind(this, '不限', 4)}
            >
              不限
            </span>
            {DecorateStyle.map((item) => {
              return (
                <div
                  className='filterWrap_list_item'
                  key={item.name}
                  onClick={this.filterValue.bind(this, item.name, 4, item.id)}
                >
                  <span
                    onClick={this.onaclickName.bind(this, item.name)}
                    className='filterWrap_list_label'>{item.name}</span>
                </div>
              )
            })}
          </li>
          <li
            className='filterWrap_list_wrap'
            style={{ opacity: areaFilterShow === true ? '1' : '0' }}
          //  onClick={this.onaclickName.bind(this,'不限')}
          >
            <span
              className='default_item'
              onClick={this.filterValue.bind(this, '不限', 3)}
            >
              不限
            </span>
            {areaData.map((item) => {
              return (
                <div
                  className='filterWrap_list_item'
                  key={item.id}
                  onClick={this.filterValue.bind(this, item, 3, item.id)}
                >

                  {this.RenderAreaLabel(item)}
                </div>
              )
            })}
          </li>
          {/* <li
            className='filterWrap_list_wrap'
            style={{ opacity: cityFilterShow === true ? '1' : '0' }}
          // onClick={this.onaclickName.bind(this,'全部区域')}
          >
            <span
              className='default_item'
              onClick={this.filterValue.bind(this, '全部区域', 2)}
            >
              全部区域
            </span>

          </li> */}
        </ul>
      </div>
    )
  }

  RenderCaseList() {
    let { filterCaseListData } = this.state
    if (filterCaseListData !== undefined) {
      return (
        <div className='CaseListWrap'>
          {filterCaseListData.map((item) => {
            return (
              <div
                style={{ height: '5.8rem' }}
                className='CaseList_item'
                key={item.id}
                onClick={this.goDetail.bind(this, item.id)}
              >
                <img className='CaseList_img' src={item.cover} alt='' />
                <p className='CaseList_name'>{item.cityName}·{item.communityName}</p>
                <div className='CaseList_info'>
                  <span className='CaseList_house'>
                    {item.houseType}&nbsp; | &nbsp;{item.styleName}&nbsp; | &nbsp;{item.acreage}㎡
                  </span>
                  <div className='whoSeenIt'>
                    <img className='seenEye' src={seenEye} alt='' />
                    <span className='peopleNum'>
                      {item.browseVolumes}人看过
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  }

  render() {
    let changeArea = (
      <div className='CaseList'>
        {this.RenderFilter()}
        {this.RenderCaseList()}
        {
          this.state.len === 10 ? <div className='loadMore' onClick={this.loadPage}>点击加载更多</div> :
            <div className='loadMore'>无更多内容</div>
        }
      </div>
    )
    return (
      <div className='container'>
        <MyNavBar />
        {changeArea}
      </div>
    )
  }
}
