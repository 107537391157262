import React, {Component} from 'react'
import './index.scss'
import {Carousel, WingBlank} from 'antd-mobile'
import DesignerPopup from '../../../components/designerPopup/index'
import {Toast} from 'antd-mobile'
import MyNavBar from '../../../components/myNavBar/index'
import {
  button1,
  button2,
  button3,
  button5,
  floorImg1,
  floorImg2,
  floorImg3,
  floorImg4,
  floorImg5,
  floorImg6,
  floorImg7,
  floorImg8,
  floorImg9,
  floorImg10,
  floorImg11,
  floorImg12,
  floorImg13,
  floorImg14,
  floorImg19,
  floorImg20,
  floorImg21,
  floorImg22,
  floorImg23,
  floorImg24,
  floorImg25,
  floorImg26,
  floorImg27,
  askFunction
} from '../../../image/TopDesign/index.js'
import { GetQuery, GetTel } from "../../../utils/index";

export default class TheTopDesign extends Component {
  state = {
    data: ['1', '2', '3'],
    name: '',
    area: '',
    tel: '',
    designVisible: false,
    environmentVisible: false
  }

  open53() {
    var _53 = window.$53.createApi()
    _53.push('cmd', 'mtalk')
    _53.query()
  }
  saveInfo(obj) {
    let source = '定制私人装修方案'
    let sourceUrl = escape(document.location.href);//'page/TheTopDesign'
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          designVisible: false
        })
        Toast.success('已提交信息', 1)
      }
    })
  }
  environmentSaveInfo(obj) {
    let source = '获取您家环境报告'
    let sourceUrl =  escape(document.location.href);//'page/TheTopDesign'
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          environmentVisible: false
        })
        Toast.success('已提交信息', 1)
      }
    })
  }

  inputChange(type, e) {
    if (type === 'name') {
      this.setState({
        name: e.target.value
      })
    } else if (type === 'area') {
      this.setState({
        area: e.target.value
      })
    } else {
      this.setState({
        tel: e.target.value
      })
    }
  }

  programmeSaveInfo() {
    let source = '一键定制专属装修方案'
    let sourceUrl =  escape(document.location.href);//'page/TheTopDesign'
    let {name, tel, area} = this.state
    if (tel === '') {
      Toast.info('未填写手机号码呀~', 1)
    } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(tel)) {
      Toast.info('手机号码格式不正确~', 1)
    } else {
      this.post(
        `${this.api.getCustomerCollect}?username=${name}&phone=${tel}&housingArea=${area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
        ''
      ).then((res) => {
        if ((res.msg = '保存成功')) {
          this.setState({
            name: '',
            tel: '',
            area: ''
          })
          Toast.success('预约成功', 1)
        }
      })
    }
  }

  RenderBanner() {
    return (
      <WingBlank>
        <Carousel autoplay={true} infinite dots={false} className='my-carousel'>
          {this.state.data.map((val) => (
            <img
              onClick={() => {
                this.setState({designVisible: true})
              }}
              className='floorImg1'
              src={floorImg1}
              key={val}
              style={{width: '100vw', height: '9.04rem', verticalAlign: 'top'}}
            />
          ))}
        </Carousel>
      </WingBlank>
    )
  }

  render() {
    let {designVisible, environmentVisible, name, area, tel} = this.state
    let changeArea
    changeArea = (
      <div className='TheTopDesign'>
        <div className='BannerBox'>{this.RenderBanner()}</div>
        <img className='floorImg2' src={floorImg2} alt='' />
        <img className='floorImg3' src={floorImg3} alt='' />
        <img className='floorImg4' src={floorImg4} alt='' />
        <img
          className='button1'
          src={button1}
          alt=''
          onClick={() => {
            this.setState({environmentVisible: true})
          }}
        />
        <img className='floorImg5' src={floorImg5} alt='' />
        <img className='floorImg6' src={floorImg6} alt='' />
        <img className='floorImg7' src={floorImg7} alt='' />
        <img className='floorImg8' src={floorImg8} alt='' />
        <img className='floorImg9' src={floorImg9} alt='' />
        <img className='floorImg10' src={floorImg10} alt='' />
        <a href={`tel:${GetTel()}`}>
          <img className='button2' src={button2} alt='' />
        </a>
        <img className='floorImg11' src={floorImg11} alt='' />
        <img className='floorImg12' src={floorImg12} alt='' />
        <div className='floor13'>
          <img className='floorImg13' src={floorImg13} alt='' />
          {/*链接53客服*/}
          
          <img  className='button4' src={askFunction} alt="" onClick={() => this.open53()}/>
        </div>
        <div className='floor14'>
          <img className='floorImg19' src={floorImg19} alt='' />
          <img className='floorImg14' src={floorImg14} alt='' />
        </div>
        <img className='floorImg20' src={floorImg20} alt='' />
        <div className='floor21'>
          <img className='floorImg22' src={floorImg22} alt='' />
          <img className='floorImg21' src={floorImg21} alt='' />
        </div>
        <div className='FivefunctionArea'>
          <img className='floorImg23' src={floorImg23} alt='' />
        </div>

        <img className='floorImg24' src={floorImg24} alt='' />
        {/*链接53客服*/}
        <img
          className='button5'
          src={button5}
          alt=''
          onClick={() => this.open53()}
        />

        <img className='floorImg25' src={floorImg25} alt='' />
        <img className='floorImg26' src={floorImg26} alt='' />
        <img className='floorImg27' src={floorImg27} alt='' />
        <div className='designInput'>
          <div className='designInput_top'>
            <input
              className='inputName'
              placeholder='您的姓名：'
              type='text'
              onChange={this.inputChange.bind(this, 'name')}
              value={name}
              ref={(myInput) => (this.myInput = myInput)}
            />
            <input
              className='inputArea'
              placeholder='您的户型面积：'
              type='number'
              onChange={this.inputChange.bind(this, 'area')}
              value={area}
              ref={(myInput) => (this.myInput = myInput)}
            />
          </div>
          <span className='square'>
            m<sup>2</sup>
          </span>
          <input
            className='inputPhone'
            placeholder='您的联系方式：'
            type='number'
            onChange={this.inputChange.bind(this, 'tel')}
            value={tel}
            ref={(myInput) => (this.myInput = myInput)}
          />
        </div>
        <img
          className='button3'
          src={button3}
          alt=''
          onClick={this.programmeSaveInfo.bind(this)}
        />
        <div className='copyrightWrap'>
          <p>
            北京居然数装科技有限公司版权所有 &copy;2000-2021 All Rights
            Reserved. 京ICP备140084
          </p>
        </div>
        <DesignerPopup
          visible={designVisible}
          title='定制私人装修方案'
          subTitle='在线预约 尊享优先排期特权'
          bottomdata={['免费量房', '户型解析', '软装咨询']}
          cancelClick={() => this.setState({designVisible: false})}
          saveInfo={this.saveInfo.bind(this)}
          btnTxt={['立即预约']}
        />
        <DesignerPopup
          visible={environmentVisible}
          title='获取我家环境质量报告'
          subTitle='在线预约 尊享优先排期特权'
          bottomdata={['免费量房', '户型解析', '软装咨询']}
          cancelClick={() => this.setState({environmentVisible: false})}
          saveInfo={this.environmentSaveInfo.bind(this)}
          btnTxt={['立即获取']}
        />
      </div>
    )
    return (
      <div className='container'>
        <MyNavBar />
        {changeArea}
      </div>
    )
  }
}
