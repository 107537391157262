import React, {Component} from 'react'
import MyNavBar from '../../../components/myNavBar/index'
import itemA from '../../../image/itemA.png'
import Retest from '../../../image/Retest.png'
import '../../../css/Styles_result.scss'
import {createBrowserHistory} from 'history'
import {Toast} from 'antd-mobile'
import {GetQuery, locationHref} from "../../../utils/index";

const history = createBrowserHistory()

class Styles_result extends Component {
  state = {
    data: ['1', '2', '3'],
    type: '',
    id: '',
    Styles_result_Data: [],
    visible: false,
    caseListData: [],
    tel: ''
  }
  componentWillMount() {
    let ids = this.props.location.id ? this.props.location.id : this.props.match.params.id;
    this.setState({
      id: ids
    })
    this.chooseType(ids)
  }

  componentDidMount() {
    this.getDesignerCaseList()
  }
  componentWillReceiveProps() {}

  componentWillUnmount() {}

  componentDidShow() {}

  componentDidHide() {}

  chooseType(id) {
    this.get(`${this.api.getStyleResult}?id=${id}`, '').then((res) => {
      if (res.code === 0) {
        this.setState({
          Styles_result_Data: res.data[0]
        })
      }
    })
  }

  getDesignerCaseList() {
    this.get(`${this.api.getDesignerCaseList}?page=${1}&limit=${10}&${GetQuery()}&isRecommend=1`, '').then(
      (res) => {
        if (res.data.code === 0) {
          this.setState({
            caseListData: res.data.data
          })
        }
      }
    )
  }

  saveInfo(obj) {
    let source = '预约TA'
    let sourceUrl = escape(document.location.href);//'page/Styles_result'
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          visible: false
        })
        Toast.success('已提交信息', 1)
      }
    })
  }
  goDetail(id) {
    locationHref('/detailsCase/' + id)
  }
  inputChange(e) {
    this.setState({
      tel: e.target.value
    })
  }
  designerSaveInfo() {
    let source = '获取设计方案'
    let sourceUrl = escape(document.location.href);//'page/Styles_result'
    let {tel} = this.state
    if (tel!== '') {
      this.post(
        `${this.api.getCustomerCollect}?phone=${tel}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
        ''
      ).then((res) => {
        if (res.code === 0) {
          this.setState({
            visible: false,
            tel:''
          })
          Toast.success('已提交信息', 1)
        }
      })
    }else{
      Toast.info('手机号码为空~', 1)
    }
  }

  //  案例
  RecommendedCases() {
    let {caseListData} = this.state
    if (caseListData !== undefined) {
      return (
        <div className='RecommendedCase_wrap'>
          {caseListData.map((item) => (
            <div className='RecommendedCase_list' key={item.id}   
            onClick={this.goDetail.bind(this,item.id)}>
              <img className='caseImg' src={item.cover} alt='' />
              <div className='RecommendedCase_info'>
                <span className='Case_title'>{item.title}</span>
                <span className='Case_area'>{item.acreage}m²</span>
              </div>
              <div className='label_wrap'>
                <span className='label'>{item.styleName}</span>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }

  //   设计师
  Designer(data) {
    return data.map((item, index) => {
      return (
        <div className='Designer' key={index}>
          <div className='Designer_info'>
            <img className='Designer_img' src={itemA} alt='' />
            <div className='Designer_desc'>
              <div className='Designer_title'>
                <span className='Designer_name'>张三</span>
                <span className='Designer_identity'>首席设计师</span>
              </div>
              <div
                className='subscribe'
                onClick={() => this.setState({visible: true})}
              >
                预约TA
              </div>
              <p className='Designer_other'>从业年限：15年</p>
              <p className='Designer_other'>
                擅长风格：中式、欧式、新中式、美式、...
              </p>
              <p className='Designer_other'>接单范围：套餐、别墅</p>
            </div>
          </div>
          <div className='Designer_detail'>
            <p className='Designer_detail_text'>
              毕业于中央美院视觉传达系，曾从事园林设计，主导过多个国际大型项目的设计与美院视觉传达系，曾从事事事…
            </p>
            <span className='Designer_more'>查看更多</span>
          </div>
          <div className='Designer_works_wrap'>
            {this.state.data.map((item, index) => (
              <div className='Designer_works_wrap_list' key={index}>
                <img className='Designer_works' src={itemA} alt='' />
              </div>
            ))}
          </div>
        </div>
      )
    })
  }

  render() {
    let { Styles_result_Data, tel, caseListData} = this.state
    let changeArea = (
      <div className='Styles_result'>
        {/*头部 */}
        <div className='Styles_header'>
          <span className='Styles_result_name'>{Styles_result_Data.name}</span>
          <img
            className='Retest'
            src={Retest}
            alt=''
            onClick={() => {
              history.goBack()
            }}
          />
          <img
            className='Styles_header_img'
            src={Styles_result_Data.imgUrl}
            alt=''
          />
        </div>

        <div className='Styles_result_content'>
          <p className='result_desc'>{Styles_result_Data.infos}</p>
          {/*获取设计方案 */}
          <div className='design_scheme'>
            <span className='design_scheme_title'>定制你的专属设计方案</span>
            <input
              className='design_scheme_ipt'
              type='number'
              placeholder='请输入您的手机号'
              onChange={this.inputChange.bind(this)}
              value={tel}
            />
            <span className='design_scheme_notes'>您的信息会被严格保密</span>
            <div
              className='design_scheme_btn'
              onClick={this.designerSaveInfo.bind(this)}
            >
              获取设计方案
            </div>
          </div>

          {/*推荐的案例 */}
          <div className='RecommendedCases'>
            {
              (caseListData && caseListData.length > 0)&& <span className='RecommendedCases_title'>为您推荐相关案例</span>

            }
            {this.RecommendedCases()}
          </div>
          {/* 关联的设计师
          <p className='Connected_designers'>关联的设计师</p>
          {this.Designer(this.state.data)}
          <DesignerPopup
            visible={visible}
            title='预约TA'
            subTitle='在线预约 尊享优先排期特权'
            bottomdata={['上门量房', '户型解析', '动线设计', '软装设计']}
            cancelClick={() => this.setState({visible: false})}
            btnTxt={['立即预约']}
            saveInfo={this.saveInfo.bind(this)}
          /> */}
        </div>
      </div>
    )

    return (
      <div className='container'>
        <MyNavBar />
        {changeArea}
      </div>
    )
  }
}
export default Styles_result
