export function minusAction() {
  return {
    type: 'minus'
  }
}

export function giftAction() {
  return {
    type: 'giftDialog'
  }
}



