import React, { Component } from 'react'
import '../../../css/commentsDetail.css'
import MyNavBar from '../../../components/myNavBar/index'
import Tab1 from '../../../components/commentsDetail_tab/tab1.js'
import Tab2 from '../../../components/commentsDetail_tab/tab2.js'
import {
    yzzs
} from '../../../image/commentsDetail/index'

export default class commentsDetail extends Component {
    componentDidMount() {
        //业主之声tdk内容
        var meta = document.getElementsByTagName('meta')
        document.title = '装修口碑哪家好_哪家装修公司比较靠谱_居然装修口碑怎么样-居然装饰'
        addMate('a', 'title', '装修口碑哪家好_哪家装修公司比较靠谱_居然装修口碑怎么样-居然装饰')
        addMate(
            'b',
            'keywords',
            '装修口碑,哪家装修公司比较靠谱,居然装修口碑怎么样'
        )
        addMate(
            'c',
            'description',
            '居然装饰始终践行品质装修，让让家装和家居服务快乐简单，找装修口碑哪家好，哪家装修公司比较靠谱，找好口碑装修公司居然装饰。居然装修口碑怎么样？居然装饰有完整的装修团队，装修设计师，装修施工团队，装修售后服务团队，找居然，装修无忧。'
        )

        function addMate(id, name, content) {
            let metaData = document.getElementsByTagName('head')[0].children
            for (let i = 0; i < metaData.length; i++) {
                if (metaData[i].localName === 'meta' && metaData[i].id === id) {
                    const mate = document.getElementById(id)
                    document.getElementsByTagName('head')[0].removeChild(mate)
                }
            }
            let meta = document.createElement('meta')
            meta.content = content
            meta.name = name
            meta.id = id
            document.getElementsByTagName('head')[0].appendChild(meta)
        }
    }
    state = {
        flag: true,
    }
    setFlag = (type) => {
        this.setState({
            flag: type,
        });
    }
    render() {
        return (
            <div className="commentsDetail_container">
                <MyNavBar />
                <div className="commentsDetail_container_title">
                    <img className='yzzs' src={yzzs} alt='' />
                </div>
                <div className="commentsDetail_container_top">
                    <div className="top_radius">
                        <div className={this.state.flag ? 'btn btn-choose' : 'btn'}
                            onClick={() => this.setFlag(true)}>业主评价</div>
                        <div className={!this.state.flag ? 'btn1 btn-choose' : 'btn1'}
                            onClick={() => this.setFlag(false)}>业主锦旗</div>
                    </div>
                </div>
                <div className="commentsDetail_container_content">
                    {this.state.flag == true ? <Tab2 /> : <Tab1 />}
                </div>
            </div>
        )
    }
}
