import React, { Component } from 'react'
import './index.scss'
import 'antd-mobile/dist/antd-mobile.css'
import progress from '../../image/progress.png'

class MyStep extends Component {
  render() {
    const { step, stepData } = this.props
    return (
      <div className='MyStep'>
        {stepData.map((item, index) => {
          let i = ++index
          return (
            <div className='stepList' key={item.name}>
              {
                index !== stepData.length && <div
                  className='stepLine'
                  style={{ background: i >= step ? '#F2F2F2' : '#005BAB' }}
                ></div>
              }
              
              <div
                className='stepNum'
                style={{
                  background: i > step ? '#F2F2F2' : '#005BAB',
                  color: i > step ? '#bbbbbb' : '#ffffff',
                  borderColor: i > step ? '#f2f2f2' : '#005BAB'
                }}
              >
                {i}
              </div>
              <img
                className='stepImg'
                src={progress}
                style={{ display: i > step ? 'none' : 'block' }}
              />
              <span className='stepTitle'>{item.name}</span>
            </div>
          )
        })}
      </div>
    )
  }
}

export default MyStep
