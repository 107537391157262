import React, {Component} from 'react'
import './index.scss'
import MyNavBar from '../../../components/myNavBar/index'
import testStyles from '../../../image/testStyles.png'
import bannerMiddle from '../../../image/banner_middle.png'
import bg_ZXQ from '../../../image/bg_ZXQ.png'
import bg_ZXZ from '../../../image/bg_ZXZ.png'
import bg_ZXH from '../../../image/bg_ZXH.png'
import bg_ZXDQ from '../../../image/bg_ZXDQ.png'
import DesignerPopup from '../../../components/designerPopup/index'
import {GetQuery, locationHref} from "../../../utils/index";

import {Toast} from 'antd-mobile'

export default class decorateGan extends Component {
  state = {
    moduleData: [
      {
        id: 6,
        name: '装修前',
        pic: bg_ZXQ,
        labels: ['收房预算', '设计合同']
      },
      {
        id: 7,
        name: '装修中',
        pic: bg_ZXZ,
        labels: ['拆改木工', '防水泥瓦', '油漆水电']
      },
      {
        id: 8,
        name: '装修后',
        pic: bg_ZXH,
        labels: ['竣工验收', '软装搭配']
      },
      {
        id: 5,
        name: '装修大全',
        pic: bg_ZXDQ,
        labels: ['百科', '问答', '日记']
      }
    ],
    priceVisible: false,
  }
  godecorateList(id) {
    if (id === 5) {
      locationHref('/decorateGan/DecorateDaqo/' + id+'/tab1')
    } else if(id===6) {
      locationHref('/decorateGan/decorationList/' + id+'/tab9')
    }else if(id===7) {
      locationHref('/decorateGan/decorationList/' + id+'/tab10')
    }else if(id===8) {
      locationHref('/decorateGan/decorationList/' + id+'/tab13')
    }
  }
  componentDidMount() {
    //关于居然tdk内容
    var meta = document.getElementsByTagName('meta')
    document.title = '装修攻略_装修知识大全_装修指南-居然之家装饰'
    addMate('a', 'title', '装修攻略_装修知识大全_装修指南-居然之家装饰')
    addMate(
      'b',
      'keywords',
      '装修攻略,装修知识大全,装修指南'
    )
    addMate(
      'c',
      'description',
      '装修攻略栏目为大家提供各种装修知识和装修指南，让您对装修更加了解，让装修更加容易；居然装饰装修知识指南，感谢你的关注。'
    )

    function addMate(id, name, content) {
      let metaData = document.getElementsByTagName('head')[0].children
      for (let i = 0; i < metaData.length; i++) {
        if (metaData[i].localName === 'meta' && metaData[i].id === id) {
          const mate = document.getElementById(id)
          document.getElementsByTagName('head')[0].removeChild(mate)
        }
      }
      let meta = document.createElement('meta')
      meta.content = content
      meta.name = name
      meta.id = id
      document.getElementsByTagName('head')[0].appendChild(meta)
    }
  }
  priceSaveInfo(obj) {
    let source = '获取报价'
    let sourceUrl = escape(document.location.href);//'/kuaiwu'
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          priceVisible: false
        })
        Toast.success('已提交信息', 1)
      }
    })
  }


  goStyleTest() {
    locationHref('/styleTesting')
  }

  RenderDecorationModule() {
    let {moduleData} = this.state
    return (
      <div className='decorationModuleWrap'>
        {moduleData.map((item) => {
          return (
            <div
              className='decorationModuleList'
              key={item.id}
              style={{backgroundImage: `url(${item.pic})`}}
              onClick={this.godecorateList.bind(this, item.id)}
            >
              <div className='decorationModule_left'>{item.name}</div>
              <div className='decorationModule_right'>
                {item.labels.map((item1, index) => {
                  return (
                    <div key={index} className='decorationModule_label'>
                      {item1}
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  // RenderList() {
  //   return (
  //     <div className='moduleListContent'>
  //       <div className='moduleListContent_left'>
  //         <span className='moduleListContent_title'>
  //           家装高频词汇，小白你却听不懂？
  //         </span>
  //         <div className='moduleListContent_desc'>
  //           <span className='moduleListContent_desc_txt'>
  //             在刚刚接触装修这个大坑的时候，简直就是一头雾水。特别是其貌不扬的工长、工人蹦出来一些词儿听不懂的时候，一下子心里就没底了。龙骨是啥？清包是…
  //           </span>
  //           <span className='moduleListContent_desc_more'>更多</span>
  //         </div>
  //         <div className='moduleListContent_bottom'>
  //           <span className='decoration_time'>2020-12-31</span>
  //           <div className='decoration_views'>
  //             <img className='view_number' src={seenEye} alt='' />
  //             2300
  //           </div>
  //         </div>
  //       </div>
  //       <div className='moduleListContent_right'>
  //         {/* <img className='moduleListContent_img' src={beijing2} alt='' /> */}
  //       </div>
  //     </div>
  //   )
  // }

  // RenderDecorationModuleList(txt) {
  //   return (
  //     <div className='decorationModuleList'>
  //       <div className='moduleList_Header'>
  //         <span className='moduleList_Header_tit'>{txt}</span>
  //         <span className='moduleList_Header_desc'>前期准备与设计方案</span>
  //         <div className='moduleList_Header_more'>
  //           <span className='seeMore'>查看更多</span>
  //           <img className='icon_more' src={iconMore} alt='' />
  //         </div>
  //       </div>
  //       <div className='moduleListContentWrap'>
  //         {this.RenderList()}
  //         {this.RenderList()}
  //         {this.RenderList()}
  //       </div>
  //     </div>
  //   )
  // }

  render() {
    let {
      priceVisible,
    } = this.state
    let changeArea
    changeArea = (
      <div className='decorateGan'>
        <div className='bannerTops' onClick={this.goStyleTest.bind(this)} style={{marginTop:0}}>
          <img className='bannerTop_img' src={testStyles} alt='' />
        </div>
        {this.RenderDecorationModule()}
        <div className='bannerTop'    
        onClick={() => {this.setState({priceVisible: true})
          }}>
          <img className='bannerTop_img' src={bannerMiddle} alt='' />
          <span className='preciseSolution'>已为1302234人提供精准方案</span>
        </div>
        {/* {this.RenderDecorationModuleList('装修前')}
        {this.RenderDecorationModuleList('装修中')}
        {this.RenderDecorationModuleList('装修后')} */}
      </div>
    )
    return (
      <div className='container'>
        <MyNavBar />
        {changeArea}
        <DesignerPopup
        visible={priceVisible}
        title='装修要花多少钱?'
        subTitle='1688'
        cancelClick={() => this.setState({priceVisible: false})}
        saveInfo={this.priceSaveInfo.bind(this)}
        btnTxt={['获取报价']}
        type='price'
        price={98234}
      />
      </div>
    )
  }
}
