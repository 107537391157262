import React, { Component } from "react";
import MyNavBar from "../../../components/myNavBar";
import "./index.scss";
import openArrow from "../../../image/openArrow.png";
import closeArrow from "../../../image/closeArrow.png";
import DesignerPopup from "../../../components/designerPopup/index";
import { Toast } from "antd-mobile";
import {editUrl, GetQuery, locationHref} from '../../../utils/index'

export default class CaseList extends Component {
    state = {
        LeverList: [],
        CitySelect: [],
        DesignerData: [],
        DecorateStyle: [],
        setMealData: [],
        priceData: [],
        cityFilterShow: false,
        styleFilterShow: false,
        setMealFilterShow: false,
        priceFilterShow: false,
        cityLabel: "北京",
        styleLabel: "风格",
        setMealLabel: "套餐",
        priceLabel: "级别",
        designVisible: false,
        RangeList: [],
        cityId: "",
        leavelID: "",
        len:10,
        page:1,
        query: {
            setMeal: '',
            style: '',
            cityId: '',
            levelId: '',
        }
    };

    componentWillMount() {
        // 设计师列表seo优化内容
        document.title = this.state.DesignerData.seotitle;
        document
            .createElement("title")
            .setAttribute("content", this.state.DesignerData.seotitle);
        if (this.state.DesignerData.keyword) {
            document
                .createElement('meta')
                .setAttribute("content", this.state.DesignerData.keyword);
        } else {
        }
        if (this.state.DesignerData.describes) {
            document
                .createElement('meta')
                .setAttribute("content", this.state.DesignerData.describes);
        } else {
        }
    }

    componentDidMount() {
        this.getDecorateStyle(); // 风格
        this.getDesigner();
        this.getDesignerRangeList();
        this.getDesignerLeverList(); //设计师级别列表
        //tdk内容
        document.title =
            "装修设计师_室内装修设计师_室内装饰设计师_房屋装修设计师-居然装饰";
        var meta = document.getElementsByTagName("meta");
        addMate(
            "a",
            "title",
            "装修设计师_室内装修设计师_室内装饰设计师_房屋装修设计师-居然装饰"
        );
        addMate(
            "b",
            "keywords",
            "装修设计师,室内装修设计师,室内装饰设计师,房屋装修设计师,室内设计师"
        );
        addMate(
            "c",
            "description",
            "装修设计师栏目为您提供备受欢迎的装修设计师和经验丰富的家装设计师，室内家装设计师，房屋装修设计师，想找好的装修设计师来居然装饰，居然装饰装修设计师为您打造高品质家居住宅保驾护航。"
        );

        function addMate(id, name, content) {
            let metaData = document.getElementsByTagName("head")[0].children;
            for (let i = 0; i < metaData.length; i++) {
                if (metaData[i].localName === "meta" && metaData[i].id === id) {
                    const mate = document.getElementById(id);
                    document.getElementsByTagName("head")[0].removeChild(mate);
                }
            }
            let meta = document.createElement("meta");
            meta.content = content;
            meta.name = name;
            meta.id = id;
            document.getElementsByTagName("head")[0].appendChild(meta);
        }
    }

    goDetail(id) {
        locationHref("/designerInfo/" + id)
    }

    goStyleTest() {
        locationHref("/styleTesting")
    }

    goCaseDetail(id) {
        locationHref("/detailsCase/" + id)
    }

    getDecorateStyle() {
        // 装修风格
        this.get(this.api.getDecorateStyle, "").then((res) => {
            if (res.code === 0) {
                this.setState({
                    DecorateStyle: res.data,
                });
            }
        });
    }
    getDesignerLeverList() {
        // 装修风格
        this.get(this.api.getDesignerLeverList, "").then((res) => {
            if (res.code === 0) {
                this.setState({
                    LeverList: res.data,
                });
            }
        });
    }

    getCitySelect() {
        this.get(this.api.getCitySelect, "").then((res) => {
            if (res.code === 0) {
                this.setState({
                    CitySelect: res.data,
                });
            }
        });
    }
    getDesignerRangeList() {
        this.get(this.api.getDesignerRangeList, "").then((res) => {
            if (res.code === 0) {
                this.setState({
                    RangeList: res.data,
                });
            }
        });
    } 
    getDesigner() {
        const { query, DesignerData, filterDesignerData, page } = this.state;
        this.get(`${this.api.getDesigner}?page=${this.state.page}&limit=${10}&${GetQuery()}`, query).then((res) => {
            if (res.code === 0) {
                this.setState({
                    len: res.data.length,
                    DesignerData: page === 1 ? res.data : DesignerData.concat(res.data),
                    filterDesignerData: page === 1 ? res.data : filterDesignerData.concat(res.data),
                });
            }
        });
    }
    loadPage=()=>{
        this.setState({
            page: this.state.page + 1
        }, () => {
            this.getDesigner()
        })
    }
    saveInfo(obj) {
        let source = "预约TA";
        let sourceUrl = "/Designer";
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ""
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    designVisible: false,
                });
                Toast.success("已提交信息", 1);
            }
        });
    }
    onaclickName(name) {
        document.title = name + "设计师_" + name + "设计师-居然装饰";
    }
    openFilter(type) {
        let {
            cityFilterShow,
            setMealFilterShow,
            styleFilterShow,
            priceFilterShow,
        } = this.state;
        if (type === 1) {
            if (cityFilterShow === true) {
                this.setState({
                    cityFilterShow: false,
                });
            } else {
                this.setState({
                    cityFilterShow: true,
                    setMealFilterShow: false,
                    styleFilterShow: false,
                    priceFilterShow: false,
                });
            }
        } else if (type === 2) {
            if (styleFilterShow === true) {
                this.setState({
                    styleFilterShow: false,
                });
            } else {
                this.setState({
                    styleFilterShow: true,
                    setMealFilterShow: false,
                    cityFilterShow: false,
                    priceFilterShow: false,
                });
            }
        } else if (type === 3) {
            if (setMealFilterShow === true) {
                this.setState({
                    setMealFilterShow: false,
                });
            } else {
                this.setState({
                    setMealFilterShow: true,
                    cityFilterShow: false,
                    styleFilterShow: false,
                    priceFilterShow: false,
                });
            }
        } else {
            if (priceFilterShow === true) {
                this.setState({
                    priceFilterShow: false,
                });
            } else {
                this.setState({
                    priceFilterShow: true,
                    setMealFilterShow: false,
                    cityFilterShow: false,
                    styleFilterShow: false,
                });
            }
        }
    }

    filterValue(label, type, id) {
        this.openFilter(type);
        const { query } = this.state
        if (type === 1) {
            this.setState({
                cityLabel: label,
                cityId: id,
            });
            if (label === "全部区域") {
                query.cityId = ''
                document.title = "全部区域设计师_全部区域设计师-居然装饰";
                editUrl("/designer/all")
            } else {
                query.cityId = id
                editUrl("/designer/" + id)
            }
        } else if (type === 2) {
            //风格
            this.setState({
                styleLabel: label,
            });
            if (label === "不限") {
                document.title = "全部风格设计师_全部风格设计师-居然装饰";
                editUrl("/designer/all")
                query.style = ''

            } else {
                editUrl("/designer/" + id)
                query.style = label
            }
        } else if (type === 3) {
            //套餐
            this.setState({
                setMealLabel: label,
            });
            if (label === "不限") {
                query.setMeal = ''
                editUrl("/designer/all")
                document.title = "全部套餐设计师_全部套餐设计师-居然装饰";
            } else {
                query.setMeal = label
                editUrl( "/designer/" + id)
            }
        } else {
            // 4.级别
            this.setState({
                priceLabel: label,
            });
            if (label === "不限") {
                query.levelId = ''
                editUrl("/designer/all")
                document.title = "全部价格设计师_全部价格设计师-居然装饰";
            } else {
                editUrl("/designer/" + id)
                query.levelId = id

            }
        }
        this.setState({
            query: query
        }, () => this.getDesigner())
    }

    RenderFilter() {
        let {
            cityFilterShow,
            setMealFilterShow,
            styleFilterShow,
            priceFilterShow,
            CitySelect,
            LeverList,
            DecorateStyle,
            cityLabel,
            setMealLabel,
            priceLabel,
            RangeList,
            styleLabel,
        } = this.state;
        return (
            <div className="filterWrap">
                <ul className="filter_header">
                    {/* <li
                        className="filterWrap_title"
                        onClick={this.openFilter.bind(this, 1)}
                    >
                        <span
                            className="filterWrap_name"
                            style={{
                                color: cityFilterShow === true ? "#005BAB" : "#000000",
                                fontWeight: cityFilterShow === true ? "600" : "400",
                            }}
                        >
                            {cityLabel}
                        </span>
                        <img
                            className="downArrowIcon"
                            src={cityFilterShow === true ? openArrow : closeArrow}
                            alt=""
                        />
                    </li> */}
                    <li
                        className="filterWrap_title"
                        onClick={this.openFilter.bind(this, 2)}
                    >
                        <span
                            className="filterWrap_name"
                            style={{
                                color: styleFilterShow === true ? "#005BAB" : "#000000",
                                fontWeight: styleFilterShow === true ? "600" : "400",
                            }}
                        >
                            {styleLabel}
                        </span>
                        <img
                            className="downArrowIcon"
                            src={styleFilterShow === true ? openArrow : closeArrow}
                            alt=""
                        />
                    </li>
                    <li
                        className="filterWrap_title"
                        onClick={this.openFilter.bind(this, 3)}
                    >
                        <span
                            className="filterWrap_name"
                            style={{
                                color: setMealFilterShow === true ? "#005BAB" : "#000000",
                                fontWeight: setMealFilterShow === true ? "600" : "400",
                            }}
                        >
                            {setMealLabel}
                        </span>
                        <img
                            className="downArrowIcon"
                            src={setMealFilterShow === true ? openArrow : closeArrow}
                            alt=""
                        />
                    </li>

                    <li
                        className="filterWrap_title"
                        onClick={this.openFilter.bind(this, 4)}
                    >
                        <span
                            className="filterWrap_name"
                            style={{
                                color: priceFilterShow === true ? "#005BAB" : "#000000",
                                fontWeight: priceFilterShow === true ? "600" : "400",
                            }}
                        >
                            {priceLabel}
                        </span>
                        <img
                            className="downArrowIcon"
                            src={priceFilterShow === true ? openArrow : closeArrow}
                            alt=""
                        />
                    </li>
                </ul>
                <ul
                    className="filterWrap_list"
                    style={{
                        display:
                            cityFilterShow === false &&
                                setMealFilterShow === false &&
                                styleFilterShow === false &&
                                priceFilterShow === false
                                ? "none"
                                : "block",
                    }}
                >
                    {/* <li
                        className="filterWrap_list_wrap"
                        style={{ opacity: cityFilterShow === true ? "1" : "0" }}
                    >
                        <span
                            className="default_item"
                            onClick={this.filterValue.bind(this, "全部区域", 1)}
                        >
                            全部区域
                        </span>
                        {CitySelect.map((item) => {
                            return (
                                <div
                                    className="filterWrap_list_item"
                                    key={item.name}
                                    onClick={this.filterValue.bind(this, item.name, 1, item.id)}
                                >
                                    <span
                                        className="filterWrap_list_label"
                                        onClick={this.onaclickName.bind(this, item.name)}
                                    >
                                        {item.name}
                                    </span>
                                </div>
                            );
                        })}
                    </li> */}

                    {/*风格*/}
                    <li
                        className="filterWrap_list_wrap"
                        style={{ opacity: styleFilterShow === true ? "1" : "0" }}
                    >
                        <span
                            className="default_item"
                            onClick={this.filterValue.bind(this, "不限", 2)}
                        >
                            不限
                        </span>
                        {DecorateStyle.map((item) => {
                            return (
                                <div
                                    className="filterWrap_list_item"
                                    key={item.id}
                                    onClick={this.filterValue.bind(this, item.name, 2, item.id)}
                                >
                                    <span
                                        onClick={this.onaclickName.bind(this, item.name)}
                                        className="filterWrap_list_label"
                                    >
                                        {item.name}
                                    </span>
                                </div>
                            );
                        })}
                    </li>
                    <li
                        className="filterWrap_list_wrap"
                        style={{ opacity: setMealFilterShow === true ? "1" : "0" }}
                    >
                        <span
                            className="default_item"
                            onClick={this.filterValue.bind(this, "不限", 3)}
                        >
                            不限
                        </span>
                        {RangeList.map((item) => {
                            return (
                                <div
                                    className="filterWrap_list_item"
                                    key={item.id}
                                    onClick={this.filterValue.bind(this, item.name, 3, item.id)}
                                >
                                    <span
                                        className="filterWrap_list_label"
                                        onClick={this.onaclickName.bind(this, item.name)}
                                    >
                                        {item.name}
                                    </span>
                                </div>
                            );
                        })}
                    </li>
                    <li
                        className="filterWrap_list_wrap"
                        style={{ opacity: priceFilterShow === true ? "1" : "0" }}
                    >
                        <span
                            className="default_item"
                            onClick={this.filterValue.bind(this, "不限", 4)}
                        >
                            不限
                        </span>
                        {LeverList.map((item) => {
                            return (
                                <div
                                    className="filterWrap_list_item"
                                    key={item.id}
                                    onClick={this.filterValue.bind(this, item.name, 4, item.id)}
                                >
                                    <span
                                        className="filterWrap_list_label"
                                        onClick={this.onaclickName.bind(this, item.name)}
                                    >
                                        {item.name}
                                    </span>
                                </div>
                            );
                        })}
                    </li>
                </ul>
            </div>
        );
    }

    RenderDesignerListBottomImgs(item) {
        if (item.caseList !== undefined && item.caseList !== []) {
            return (
                <div className="DesignerList_bottom_imgs">
                    {item.caseList.map((item1) => {
                        return (
                            <div
                                className="DesignerList_bottom_imgBox"
                                key={item1.caseId}
                            // onClick={this.goCaseDetail.bind(this, item1.caseId)}
                            >
                                <a href={"/detailsCase/" + item.id}>
                                    <img
                                        className="DesignerList_bottom_img"
                                        src={item1.caseImgUrl}
                                        alt=""
                                    />
                                </a>
                            </div>
                        );
                    })}
                </div>
            );
        }
    }

    RenderDesignerList() {
        let { filterDesignerData } = this.state;
        if (filterDesignerData && filterDesignerData.length !== 0) {
            return (
                <ul className="DesignerListWrap">
                    {filterDesignerData.map((item) => {
                        return (
                            <li className="DesignerList" key={item.id}>
                                <a href={"/designerInfo/" + item.id}>

                                    <div
                                        className="DesignerList_top"
                                    // onClick={this.goDetail.bind(this, item.id)}
                                    >
                                        <div className="DesignerList_top_L">
                                            <img
                                                className="designer_avator"
                                                src={item.headImgUrl}
                                                alt=""
                                            />
                                        </div>
                                        <div className="DesignerList_top_R">
                                            <div style={{ display: 'flex', width: '100%', height: '.693333rem', lineHeight: '.693333rem', alignItems: 'flex-end' }}>
                                                <div className='DesignerList_name_identity'>
                                                    <span className="DesignerList_name">{item.name}</span>
                                                    <span
                                                        className="DesignerList_identity"
                                                        style={{ color: "#0166B3" }}
                                                    >
                                                        {item.leaver}
                                                    </span>
                                                </div>
                                                <div
                                                    className="designer_subscribe"
                                                    onClick={() => {
                                                        this.setState({ designVisible: true });
                                                    }}
                                                >
                                                    预约TA
                                                </div>
                                            </div>

                                            <span className="experience">
                                                从业年限：{item.jobYear}年
                                            </span>
                                            <p className="designer_style">擅长风格：{item.style}</p>
                                            <p className="designer_scope">接单范围：{item.scope}</p>
                                        </div>
                                    </div>

                                </a>
                                <a href={"/designerInfo/" + item.id}>
                                    <div
                                        className="DesignerList_bottom"
                                    // onClick={this.goDetail.bind(this, item.id)}
                                    >
                                        <span className="DesignerList_bottom_desc" dangerouslySetInnerHTML={{
                                            __html: item.infos
                                        }}></span>
                                    </div>
                                </a>

                                {this.RenderDesignerListBottomImgs(item)}
                            </li>
                        );
                    })}
                </ul>
            );
        }
    }

    render() {
        let { designVisible } = this.state;
        let changeArea = (
            <div className="Designersss">
                {/* <div className="bannerTop" onClick={this.goStyleTest.bind(this)}>
                    <img className="bannerTop_img" src={testStyles} alt="" />
                </div> */}
                {this.RenderFilter()}
                {this.RenderDesignerList()}
                <DesignerPopup
                    visible={designVisible}
                    title="预约TA"
                    subTitle="在线预约 尊享优先排期特权"
                    bottomdata={["上门量房", "户型解析", "动线设计", "软装设计"]}
                    cancelClick={() => this.setState({ designVisible: false })}
                    saveInfo={this.saveInfo.bind(this)}
                    btnTxt={["立即预约"]}
                />
            </div>
        );
        return (
            <div className="container">
                <MyNavBar />
                {changeArea}
                {
                    this.state.len === 10 ? <div className='loadMore' onClick={this.loadPage}>点击加载更多</div> :
                        <div className='loadMore'>无更多内容</div>
                }
            </div>
        );
    }
}
