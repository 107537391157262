import React, { Component } from 'react'
import MyNavBar from '../../../components/myNavBar/index'
import phone from '../../../image/phone.png' 
import news from '../../../image/banner_middle1.png'
import '../../../css/to_news.scss' 
import { websiteNews } from '../../../request/index'
import seenEye from '../../../image/seenEye.png'
import {GetQuery, GetTel, GetAddr, locationHref} from "../../../utils/index";

class To_news extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    newsData: [],  
  }

  componentWillMount() {}

  componentDidMount() { 
    websiteNews('page=1&limit=10').then((res) => {
      this.setState({
        newsData: res.data.data
      }) 
    })
    //关于居然tdk内容
    var meta = document.getElementsByTagName('meta')
    document.title = '居然装饰怎么样_居然之家装饰怎么样_居然乐屋装修好不好-居然装饰'
    addMate('a', 'title', '居然装饰怎么样_居然之家装饰怎么样_居然乐屋装修好不好-居然装饰')
    addMate(
        'b',
        'keywords',
        '居然装饰怎么样,居然装饰好不好,居然之家装饰怎么样,居然乐屋好不好,居然装饰公司怎么样'
    )
    addMate(
        'c',
        'description',
        '居然装饰公司怎么样？居然之家装饰怎么样？居然装饰是居然之家自营的装修公司，居然装饰本着F“让家装变得快乐简单”的企业使命打造的数字化，智能化家装服务平台。'
    )

    function addMate(id, name, content) {
        let metaData = document.getElementsByTagName('head')[0].children
        for (let i = 0; i < metaData.length; i++) {
            if (metaData[i].localName === 'meta' && metaData[i].id === id) {
                const mate = document.getElementById(id)
                document.getElementsByTagName('head')[0].removeChild(mate)
            }
        }
        let meta = document.createElement('meta')
        meta.content = content
        meta.name = name
        meta.id = id
        document.getElementsByTagName('head')[0].appendChild(meta)
    }
  }
  choose(id) {
      locationHref('/detailsNews/'+id)
  }
 

  componentWillReceiveProps() {}

  componentWillUnmount() {}

  componentDidShow() {}

  componentDidHide() {}

  // 新闻列表区域
  newsList(data) { 
    if(data !== undefined){
      return data.map((item) => {
        return (
          <div className='newsList'    onClick={this.choose.bind(this,item.id)}  key={item.id}>
            <div className='newsList_top'>
             
              <div className='news_info'>
                <p  className='news_title'>{item.title}</p>
                <div className='infos'>
                  {item.introduction} 
                </div>
                <div className='CaseList_info'>
                  <span className='CaseList_house'>
                  {item.time} 
                  </span>
                  <div className='whoSeenIt'>
                    <img className='seenEye' src={seenEye} alt='' />
                    <span className='peopleNum'>
                      {item.counts}
                    </span>
                  </div>
                </div>
              </div>
              <img className='imgUrl' src={item.imgUrl} alt='' />
            </div>
          
          </div>
        )
      })
    }
   
  }
  //底部栏
  Footer() {
    return (
      <div className='Footer'>
        <div className='consulting'>
          <span>装修咨询</span>
          <a href={`tel:${GetTel()}`} className='phoneNum'>{GetTel()}</a>
          <img className='phone_icon' src={phone} alt='' />
        </div>
        <div className='address'>
          总部地址：{GetAddr()}
        </div>
      </div>
    )
  }
  render() { 
    let changeArea = (
      <div className='To_store_experience'>
        <img
          className='headerImg'
          src={news}
          alt='' 
        />
        <div className="newListWrap">
        {this.newsList(this.state.newsData)}</div>
        {this.Footer()}
      </div>
    )
    return (
      <div className='container'>
        <MyNavBar />
        {changeArea}
      </div>
    )
  }
}
export default To_news
