import React, {Component} from 'react'
import MyNavBar from '../../../components/myNavBar/index'
import './index.scss'
import Recruiting from '../../../image/Recruiting.jpg'
import {GetQuery, locationHref} from "../../../utils/index";

export default class Decorate_Advocate_material extends Component {

    state = {
        data: []
    }

    componentWillMount() {
        
        this.get(`/api/websiteProduce/getList?categoryId=6`).then((res) => {
            if (res.code === 0) {
                this.setState({
                    data: res.data
                })
            }
        })
    }

    //跳转到产品详情页
    goProduceInfo(id) {
        locationHref('/kuaiwu/'+id)
    }
    //跳转到招募平台
    onRecruiting(){
        window.location.href = 'http://baoming.jrzs.cn/'
    }

    render() {
        let changeArea = (
            <div className='Install_innings'>
               <div className='Install_innings_header'>
          <p className='Install_innings_title'>章鱼买手</p>
            </div>
                <div className='Install_innings_content'>
                    <ul className='Install_innings_list'>
                        {this.state.data && this.state.data.map((item) => {
                            return (
                                <li className='Install_innings_item' key={item.id}>
                                {item.coverUrl?<img className='innings_bg' src={item.coverUrl} alt='' />:<div className='innings_bg'></div>}
                                    <div className='innings_info' onClick={this.goProduceInfo.bind(this, item.id)}>
                                        <span className='innings_title'>{item.title}</span>
                                        <span className='innings_desc'>{item.subtitle}</span>
                                        <span className='innings_detail'>查看详情 > </span>
                                    </div>
                                </li>
                            )
                        })}
                        <div className="Install_innings_item3" onClick={()=>{this.onRecruiting()}}>
                            <img className='innings_bg3' src={Recruiting} alt='' />
                        </div>
                    </ul>
                </div>
            </div>
        )
        return (
            <div className='container' >
                <MyNavBar/>
                {changeArea}
            </div>
        )
    }
}
