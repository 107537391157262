import React, {Component} from "react";
import {BrowserRouter} from "react-router-dom";
import "./css/found.scss";
import DesignerPopup from "./components/designerPopup/index";
import envelopes from "./image/envelopes.png";
import kefu from "./image/kefu.png";
import {Toast} from "antd-mobile";
//引入
import RouterView from "./router/routerView"; //根据路由加载相应的路由试图组件
import config from "./router/config"; //路由配置
import {GetQuery} from "./utils/index"; //路由配置


class App extends Component {
    state = {
        roomVisible: false,
    };

    open53() {
        var _53 = window.$53.createApi();
        _53.push("cmd", "mtalk");
        _53.query();
    }

    roomSaveInfo(obj) {
        let source = "免费量房";
        let sourceUrl = escape(document.location.href);// "/home";
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ""
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    roomVisible: false,
                });
                Toast.success("已提交信息", 1);
            }
        });
    }

    RenderHoverButton() {
        return (
            <div className="hoverButton">
                <img
                    src={envelopes}
                    alt=""
                    onClick={() => this.setState({roomVisible: true})}
                />
                <span className="envelopes_label">1</span>
                <img src={kefu} alt="" onClick={() => this.open53()}/>
            </div>
        );
    }

    render() {
        const {roomVisible} = this.state;
        return (
            <div className="app">
                {/* <ScrollToTop> */}
                <BrowserRouter>
                    {/* 展示路视图组件  */}
                    <RouterView routes={config}></RouterView>
                </BrowserRouter>
                {/* </ScrollToTop> */}
                {this.RenderHoverButton()}
                <DesignerPopup
                    visible={roomVisible}
                    title="立即申请免费量房"
                    subTitle="2"
                    cancelClick={() => this.setState({roomVisible: false})}
                    saveInfo={this.roomSaveInfo.bind(this)}
                    btnTxt={["免费量房"]}
                    type="room"
                />
            </div>
        );
    }
}

export default App;
