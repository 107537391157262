import React, { Component } from 'react'
import hand from '../../../image/hand.png'
import '../../../css/Styles_tests.css'
import MyNavBar from '../../../components/myNavBar/index'
import topBanner from '../../../image/topBanner.png'
import { getStyleTest } from '../../../request/index'
import {locationHref} from "../../../utils";

class Styles_tests extends Component {
  state = {
    topic_num: 0,
    styleTestData: []
  }

  componentDidMount() {
    getStyleTest().then((res) => {
      if (res.data.code === 0) {
        this.setState({
          styleTestData: res.data.data
        })
      }
    })
        //修找灵感tdk内容
        var meta = document.getElementsByTagName('meta')
        document.title='装修案例效果图_装修效果图图片大全_装修设计效果图-居然装饰'
        addMate('a', 'title', '装修案例效果图_装修效果图图片大全_装修设计效果图-居然装饰')
        addMate(
          'b',
          'keywords',
          '装修案例,装修效果图,装修设计效果图,装修效果图图片大全'
        )
        addMate(
          'c',
          'description',
          '居然装饰装修效果图图片大全、囊括新中式、法式轻奢、美式极简、北欧经典等多样装修案例装修风格效果.家装效果图、室内装修效果图等,还有客厅、卧室、厨房、卫生间、电视背景墙等各类装修效果图大全。'
        )
    
        function addMate(id, name, content) {
          let metaData = document.getElementsByTagName('head')[0].children
          for (let i = 0; i < metaData.length; i++) {
            if (metaData[i].localName === 'meta' && metaData[i].id === id) {
              const mate = document.getElementById(id)
              document.getElementsByTagName('head')[0].removeChild(mate)
            }
          }
          let meta = document.createElement('meta')
          meta.content = content
          meta.name = name
          meta.id = id
          document.getElementsByTagName('head')[0].appendChild(meta)
        }
  }

  chooseTopic(type, nextId) {
    let { styleTestData } = this.state
    if (type === 1) {
      // 题目页
      styleTestData.map((item, index) => {
        if (nextId === item.nums) {
          this.setState({
            topic_num: index
          })
        }
      })
    } else {
        locationHref('/resultSyleTest/'+nextId)
    }
  }

  topicContent() {
    let { styleTestData, topic_num } = this.state
    let topicContentData = []
    topicContentData.push(styleTestData[topic_num])
    if (topicContentData[0] !== undefined) {
      const length = topicContentData[0].optionList.length
      return topicContentData.map((item) => {
        return (
          <div className='Style_main' key={item.id}>
            <div className='Style_topic'>
              <div className='Style_topic_num'>
                <span>{item.nums}</span>
              </div>
              <p>{item.name}</p>
            </div>
            <div className='Style_topic_content'>
              {item.optionList.map((itemData) => {
                return (
                  <div
                    className='Style_topic_content_item'
                    key={itemData.id}
                    onClick={this.chooseTopic.bind(
                      this,
                      itemData.planType,
                      itemData.planNextId
                    )}
                  >
                    <img
                      className='Style_topic_img'
                      style={{ width: length === 2 ? '6rem' : '3rem' }}
                      src={itemData.planImg}
                      alt=''
                    />
                    <div className=''>
                      <span className='type'>{itemData.planName}</span>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='Style_footer'>
              <img src={hand} alt='' />
            </div>
          </div>
        )
      })
    }
  }

  render() {
    let changeArea = (
      <div className='Style_tests'>
        <div className='Style_banner'>
          <img src={topBanner} alt='' />
        </div>
        <div className=''>
          {this.topicContent()}
          <div className='footers'></div>
        
        </div>
      </div>
    )

    return (
      <div className='container'>
        <MyNavBar />
        {changeArea}
      </div>
    )
  }
}
export default Styles_tests
