import React, {Component} from 'react'
import MyNavBar from '../../../components/myNavBar'
import MyStep from '../../../components/myStep'
import locationIcon from '../../../image/locationIcon.png'
import downloadApp from '../../../image/downloadApp.png'
import DesignerPopup from '../../../components/designerPopup/index'
import bg_manag1 from '../../../image/bg_manag1.png'
import bg_manag2 from '../../../image/bg_manag2.png'
import {Toast} from 'antd-mobile'
import {GetQuery, locationHref} from "../../../utils/index";
import { timestampToDateTime } from "../../../utils/time";

import './index.scss'

export default class TheConstructionSiteLive extends Component {
  state = {
    nowVisible: false,
    WorkSiteListData: [],
    workInfoData: [],
    workStatusData: []
  }

  componentDidMount() {
    let id = this.props.match.params.id
    this.getWorkInfo(id)
    this.getWorkSiteList()
    this.getWorkStatusList()
  }
  getWorkInfo(id) {
    this.get(`${this.api.getWorkInfo}?id=${id}`, '').then((res) => {
      if (res.code == 0) {
        res.data.planSTime = timestampToDateTime(res.data.planSTime, 'yyyy-MM-dd')
        this.setState({
          workInfoData: res.data
        })
      }
    })
  }

  getWorkSiteList() {
    this.get(`${this.api.getWorkSiteList}?page=${1}&limit=${10}`, '').then(
      (res) => {
        if (res.code === 0) {
          this.setState({
            WorkSiteListData: res.data.data
          })
        }
      }
    )
  }
  getWorkStatusList() {
    this.get(`${this.api.getWorkStatusList}`, '').then((res) => {
      if (res.code === 0) {
        this.setState({
          workStatusData: res.data
        })
      }
    })
  }

  goDesignerDetail(id) {
    locationHref('/designerInfo/'+id)
  }

  nowSaveInfo(obj) {
    let source = '预约参观'
    let sourceUrl = escape(document.location.href);//'page/TheConstructionSiteLive'
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          nowVisible: false
        })
        Toast.success('已提交信息', 1)
      }
    })
  }

  goCaseDetail(id) {
    locationHref('/detailsConstructionSite/'+id)
    this.getWorkInfo(id)
  }
  
  RenderSiteInfo() {
    let {workInfoData} = this.state
    if (workInfoData !== undefined) {
      return (
        <div className=''>
          <div className='SiteLive_headimg_box'>
            <img
              className='SiteLive_headimg'
              src={workInfoData.websiteCover}
              alt=''
            />
          </div>
          <div className='SiteLiveInfo'>
            <div className='SiteLiveHeader'>
              <span className='siteLive_name'>{workInfoData.websiteTitle}</span>
              <div className='siteLiveAddress'>
                <img className='addressIcon' src={locationIcon} alt='' />
                <span className='siteLive_address'>
                  {workInfoData.provinceName}·{workInfoData.cityName}
                  {workInfoData.countiesName}
                </span>
              </div>
            </div>
            <p className='site_houseInfo'>
              {workInfoData.floorage}m²&nbsp; | &nbsp;{workInfoData.htName}
              &nbsp; | &nbsp;
              {workInfoData.styleName}
            </p>
            <div style={{ display: 'flex', justifyContent:'space-between'}}>
              <p className='site_startTime'>开工时间：{workInfoData.planSTime}</p>
              <div
                className='subScribevisit'
                onClick={() => {
                  this.setState({ nowVisible: true })
                }}
              >
                预约参观
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  RenderManagementTeam() {
    let {workInfoData} = this.state
    if (
      workInfoData != undefined &&
      workInfoData.length !== 0 &&
      workInfoData.gz &&
      workInfoData.sjs
    ) {
      return (
        <div className='managementTeam'>
          <div className='sectionTitles'>项目管理团队</div>
          <ul className='managementTeamList'>
            <li className='managementTeamItems'>
              <img
                className='identityPhoto'
                src={workInfoData.sjs.icon?bg_manag1:bg_manag1}
                alt=''
              />
              <span className='managementTeamName'>
                {workInfoData.sjs.name}
              </span>
              <span className='positionalTitles'>设计师</span>
              <span className='workExperience'>
                工作经验：{workInfoData.sjs.workingYears}年
              </span>
              <div
                className='managementTeamBtn'
                onClick={this.goDesignerDetail.bind(this, workInfoData.sjs.id)}
              >
                查看详细资料
              </div>
            </li>
            <li className='managementTeamItems'>
              <img
                className='identityPhoto'
                src={workInfoData.gz.icon?bg_manag2:bg_manag2}
                alt=''
              />
              <span className='managementTeamName'>{workInfoData.gz.name}</span>
              <span className='positionalTitles'>工长</span>
              <span className='workExperience'>
                工作经验：{workInfoData.gz.workingYears}年
              </span>
              <div
                className='managementTeamBtn'
                onClick={() => {
                  this.setState({nowVisible: true})
                }}
              >
                预约ta
              </div>
            </li>
          </ul>
        </div>
      )
    }
  }
  //   施工进度
  RenderConstructionProgress() {
    let {workInfoData, workStatusData} = this.state
    if (workInfoData !== undefined && workStatusData !== undefined) {
      return (
        <div className='ConstructionProgress'>
          <div className='sectionTitles'>施工进度</div>
          <div className='siteList_progress'>
            <MyStep step={workInfoData.status} stepData={workStatusData} />
          </div>
          {/* {Progress.map((item) => {
          return (
            <div className='constructionSchedule' key={item.id}>
              <div className='schedule_title'>
                <img className='schedule_title_img' src={schedule} alt='' />
                <span className='schedule_title_txt'>{item.title}</span>
              </div>
              <p className='schedule_desc'>{item.status}</p>
              <div className='Schedule_img_wrap'>
                {item.pics.map((item1, index) => {
                  return (
                    <img className='Schedule_img' key={index} src={zs899}></img>
                  )
                })}
              </div>
              <p className='schedule_time'>{item.time}</p>
              <p className='expandMore'>
                <span className='expandMore_txt'>
                  展开{item.moreData}条播报
                </span>
                <img className='downArrow' src={downArrow} alt='' />
              </p>
            </div>
          )
        })} 
      */}
        </div>
      )
    }
  }

  //  同小区案例
  RenderSameCommunityCase() {
    let {workInfoData,WorkSiteListData} = this.state
    if (WorkSiteListData.length !== 0) {
      return (
        <div className='sameCommunityCase'>
          <div className='sectionTitles'>同小区案例</div>
          <div className='sameCommunityCaseWrap'>
            {WorkSiteListData.map((item) => {
              if (workInfoData.countiesName == item.countiesName) {
                return (
                  <div
                    className='sameCommunityCaseList'
                    key={item.id}
                    onClick={this.goCaseDetail.bind(this, item.id)}
                  >
                    <img
                      className='sameCommunityCaseImg'
                      src={item.websiteCover}
                      alt=''
                    />
                    <span className='sameCommunityCaseTitle'>
                      {item.websiteTitle}
                    </span>
                    <span className='sameCommunityCaseDesc'>
                      {item.floorage}m²&nbsp; | &nbsp;{item.htName}&nbsp; |
                      &nbsp;
                      {item.styleName}
                    </span>
                  </div>
                )
              }
            })}
          </div>
        </div>
      )
    }
  }

  RenderHotChargingCase() {
    let {WorkSiteListData} = this.state
    if (WorkSiteListData !== undefined) {
      return (
        <div className='sameCommunityCase'>
          <div className='sectionTitles'>热装案例</div>
          <div className='sameCommunityCaseWrap'>
            {WorkSiteListData.map((item) => {
              return (
                <div
                  className='sameCommunityCaseList'
                  key={item.id}
                  onClick={this.goCaseDetail.bind(this, item.id)}
                >
                  <img
                    className='sameCommunityCaseImg'
                    src={item.websiteCover}
                    alt=''
                  />
                  <span className='sameCommunityCaseTitle'>
                    {item.websiteTitle}
                  </span>
                  <span className='sameCommunityCaseDesc'>
                    {item.floorage}m²&nbsp; | &nbsp;{item.htName}&nbsp; | &nbsp;
                    {item.styleName}
                  </span>
                </div>
              )
            })}
          </div>
        </div>
      )
    }
  }

  render() {
    let {nowVisible} = this.state
    let changeArea = (
      <div className='TheConstructionSiteLive'>
        {this.RenderSiteInfo()}
        <div className='Placeholder_boxT'></div>
        {this.RenderManagementTeam()}
        <div className='Placeholder_boxT'></div>
        {this.RenderConstructionProgress()}
        <div className='Placeholder_boxT'></div>
        {this.RenderSameCommunityCase()}
        <div className='Placeholder_boxT'></div>
        {this.RenderHotChargingCase()}
        <div className='downloadApp'>
          <img className='downloadImg' src={downloadApp} alt='' />
        </div>
        <DesignerPopup
          visible={nowVisible}
          title='预约TA'
          type={'ta'}
          subTitle='在线预约 尊享优先排期特权'
          bottomdata={['上门量房', '户型解析', '动线设计', '软装设计']}
          cancelClick={() => this.setState({nowVisible: false})}
          saveInfo={this.nowSaveInfo.bind(this)}
          btnTxt={['立即预约']}
        />
      </div>
    )
    return (
      <div className='container'>
        <MyNavBar />
        {changeArea}
      </div>
    )
  }
}
