import React, {Component} from 'react'
import {Modal, Input} from 'antd'
import './index.scss'
import gbtn from '../../image/gbtn_close.png'
import iconClock from '../../image/iconClock.png'
import DesignAppointment from '../../image/bg_form_DesignAppointment.png'
import designBg from '../../image/designBg.png'
import priceBg from '../../image/pricebg.png'
import bg_form_ta from '../../image/bg_form_ta.png'
import roomBg from '../../image/bg_form_FreeMeasure.png'
import {Toast} from 'antd-mobile'
import {connect} from 'react-redux'
import {minusAction} from '../../store/actions'
import TextArea from 'antd/lib/input/TextArea'

class DesignerPopup extends Component {
  state = {
    name: '',
    area: '',
    tel: '',
    question: '',
    telShow: false
  }

  componentWillMount() {}
  componentDidMount() {
    this.state.timer = setInterval(() => {
      this.setState({
        price: Math.floor(Math.random() * (100000 - 10000)) + 10000
      })
    }, 100)
  }

  saveInfo() {
    let {name, area, tel, question} = this.state
    let obj = {
      name: name,
      area: area,
      tel: tel,
      question: question
    }
    if (tel === '') {
      this.setState({
        telShow: true
      })
    } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(tel)) {
      Toast.info('手机号码格式不正确~', 1)
    } else {
      this.props.saveInfo(obj)
      this.setState({
        name: '',
        area: '',
        tel: '',
        question: ''
      })
      this.props.minus()
    }
  }

  saveQuestion() {
    let {question} = this.state
    let obj = {
      question: question
    }
    if (question === '') {
      Toast.info('内容为空哟~', 1)
    } else {
      this.props.saveInfo(obj)
      this.setState({
        question: ''
      })
    }
  }

  inputChange(type, e) {
    if (type === 1) {
      this.setState({
        name: e.target.value
      })
    } else if (type === 2) {
      this.setState({
        area: e.target.value
      })
    } else if (type === 3) {
      this.setState({
        tel: e.target.value
      })
    } else {
      this.setState({
        question: e.target.value
      })
    }
  }

  componentWillUnmount() {
    if (this.state.timer !== null) {
      clearInterval(this.state.timer)
    }
  }

  RenderSubTitle() {
    const {type, subTitle} = this.props
    let {price} = this.state
    let changeArea
    if (type === 'like') {
      changeArea = (
        <div className='design_title_f'>
          您将获得居然之家提供的
          <span style={{color: '#0166B3'}}>精美礼品</span>一份
        </div>
      )
    } else if (type === 'design') {
      changeArea = (
        <div className='design_title_f'>
          目前已有 <span style={{color: '#0166B3'}}> {subTitle} </span>
          人获取全屋设计方案
        </div>
      )
    } else if (type === 'room') {
      changeArea = (
        <div className='design_title_f'>
          今天仅剩 <span style={{color: '#0166B3'}}> {this.props.num} </span>
          个名额
        </div>
      )
    } else if (type === 'ask') {
      changeArea = (
        <div className='design_title_f'>
          仅剩 <span style={{color: '#0166B3'}}> {this.props.num} </span>
          个名额
        </div>
      )
    } else if (type === 'price') {
      changeArea = (
        <div className='price_sub_title'>
          <div className='price_sub_title_m'>
            <span className='price_sub_title_left'>预估金额</span>
            <div className='price_sub_title_right'>
              <span>{price}</span>元
            </div>
          </div>
          <div className='design_title_f'>
            目前已有 <span style={{color: '#0166B3'}}> {subTitle} </span>
            人获取装修报价
          </div>
        </div>
      )
    } else if (type === 'activitie') {
      changeArea = (
        <div className='design_title_f'>
          居然之家 <span style={{color: '#0166B3'}}> {subTitle} </span>
          天内的活动会以短信方式通知您
        </div>
      )
    } else if (type === 'tel') {
      changeArea = (
        <div className='design_title_f'>
          居然之家 <span style={{ color: '#0166B3' }}> {subTitle} </span>
          天内的活动会以短信方式通知您
        </div>
      )
    } else {
      changeArea = <div className='design_title_f'>{subTitle}</div>
    }
    return changeArea
  }

  RenderBottom() {
    const {bottomdata, type} = this.props
    let changeArea
    if (type === 'ask' || type === 'price' || type === 'room' || type === 'design') {
      changeArea = ''
    } else if (Array.isArray(bottomdata)) {
      if(changeArea){
          changeArea = (
          <div className='design_bottom_list'>
            {bottomdata!=''&&bottomdata.map((item, index) => {
              return (
                <span className='design_bottomSpan' key={index}>
                  {item}
                </span>
              )
            })}
          </div>
        )
      }
    } else {
      changeArea = (
        <div className='storage_Bottom'>
          <img className='iconClock' src={iconClock} alt='' />
          <p className='storage_Bottom_txt'>
            请尽快到店领取，
            <span className='storage_Bottom_span'> {bottomdata}</span>天后将过期
          </p>
        </div>
      )
    }

    return changeArea
  }

  RenderInput() {
    let {telShow} = this.state
    const {title, btnTxt, type} = this.props
    let changeArea = null
    if (type == 'question') {
      changeArea = (
        <div className='QuestionBox'>
          <div className='Question_title'>{title}</div>
          <TextArea
            placeholder='衷心期待您的回答'
            showCount
            maxLength={100}
            className='QuestionTextArea'
            onChange={this.inputChange.bind(this, 4)}
            value={this.state.question}
          ></TextArea>
          <div
            className='design_button'
            id='button_'
            onClick={this.saveQuestion.bind(this)}
          >
            {btnTxt}
          </div>
        </div>
      )
    } else if (type == 'tel') {
      changeArea = (
        <div
          style={{
            width: '8.533333rem',
            textAlign: 'center',
            marginTop: '-.426667rem'
          }}
        >
          <div className='design_title_'>{title}</div>
          {this.RenderSubTitle()}

          <p>
            <Input
              ref={(myInput) => (this.myInput = myInput)}
              className='design_input'
              placeholder='您的姓名'
              value={this.state.name}
              onChange={this.inputChange.bind(this, 1)}
            />
          </p>
          
          <p>
            <Input
              ref={(myInput) => (this.myInput = myInput)}
              className='design_input'
              placeholder='手机号码'
              value={this.state.tel}
              onChange={this.inputChange.bind(this, 3)}
              type='number'
            />
          </p>
          <p
            style={{ display: telShow === true ? 'block' : 'none' }}
            className='errorTel'
          >
            您的手机号不能为空哦
          </p>
          <div
          style={{marginTop:'1rem'}}
            className='design_button'
            id='button_'
            onClick={this.saveInfo.bind(this)}
          >
            {btnTxt}
          </div>
          {this.RenderBottom()}
        </div>
      )
    }else if (type == 'design') {
      changeArea = (
        <div
          style={{
            width: '8.533333rem',
            textAlign: 'center',
            marginTop: '8.026667rem'
          }}
        >
          <p>
            <Input
              ref={(myInput) => (this.myInput = myInput)}
              className='design_input'
              placeholder='请输入您的电话：'
              value={this.state.tel}
              onChange={this.inputChange.bind(this, 3)}
              type='number'
            />
          </p>
          <p
            style={{display: telShow === true ? 'block' : 'none'}}
            className='errorTel'
          >
            您的手机号不能为空哦
          </p>
          <div
            className='design_button'
            id='button_'
            onClick={this.saveInfo.bind(this)}
          >
            {btnTxt}
          </div>
          {this.RenderBottom()}
        </div>
      )
    }else {
      changeArea = (
        <div
          style={{
            width: '8.533333rem',
            textAlign: 'center',
            marginTop: '-.426667rem'
          }}
        >
          <div className='design_title_'>{title}</div>
          {this.RenderSubTitle()}

          <p>
            <Input
              ref={(myInput) => (this.myInput = myInput)}
              className='design_input'
              placeholder='您的姓名'
              value={this.state.name}
              onChange={this.inputChange.bind(this, 1)}
            />
          </p>

          <p>
            <Input
              ref={(myInput) => (this.myInput = myInput)}
              className='design_input'
              placeholder={
                type === 'download' ? '您的网盘账号' : '您的房屋面积'
              }
              value={this.state.area}
              type='number'
              onChange={this.inputChange.bind(this, 2)}
            />
          </p>
          <span
            className='square'
            style={{opacity: type === 'download' ? '0' : '1'}}
          >
            m<sup>2</sup>
          </span>
          <p>
            <Input
              ref={(myInput) => (this.myInput = myInput)}
              className='design_input'
              placeholder='手机号码'
              value={this.state.tel}
              onChange={this.inputChange.bind(this, 3)}
              type='number'
            />
          </p>
          <p
            style={{display: telShow === true ? 'block' : 'none'}}
            className='errorTel'
          >
            您的手机号不能为空哦
          </p>
          <div
            className='design_button'
            id='button_'
            onClick={this.saveInfo.bind(this)}
          >
            {btnTxt}
          </div>
          {this.RenderBottom()}
        </div>
      )
    }
    return changeArea
  }
  render() {
    const {visible, cancelClick, content, type} = this.props
    return (
      <div>
        <Modal
          centered
          visible={visible}
          footer={null}
          onCancel={cancelClick}
          closable={false}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '.48rem'
            }}
          >
            <span>{content}</span>
          </div>

          <div
            className='design_test-title'
            style={{
              display: 'flex',
              left: '52%',
              transform: 'translateX(-50%) translateY(10%)',
              position: 'fixed',
              height: type === 'price' ? '12.4rem' : '11.11068977rem',
              backgroundSize: '100% 100%',
              background:
                type === 'design'
                  ? `url('${designBg}') center center /cover`
                  : type === 'price'
                  ? `url('${priceBg}') center center /cover`
                  : type === 'room'
                  ? `url('${roomBg}') center center /cover`
                  : type === 'ta'
                  ? `url('${bg_form_ta}') center center /cover`
                  : type === 'tel'
                  ? `url('${bg_form_ta}') center center /cover`
                  : `url('${DesignAppointment}') center center /cover`
            }}
          >
            {this.RenderInput()}
          </div>
          <p
            className='design_dw'
            onClick={cancelClick}
            style={{top: type === 'price' ? '12.5rem' : '12.3rem'}}
          >
            <img src={gbtn} alt='' />
          </p>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    num: state.counterReducer.num
  }
}

function mapDispatchToProps(dispatch) {
  return {
    minus: () => {
      dispatch(minusAction())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DesignerPopup)
