import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import MyNavBar from '../../../components/myNavBar/index'
import liulian from '../../../image/liulan.png'
import '../../../iconfont/iconfont.css'
import './index.scss'
import bannerMiddle from '../../../image/banner_middle.png'
import DesignerPopup from '../../../components/designerPopup/index'
import seenEye from '../../../image/seenEye.png'
import iconMore from '../../../image/iconMore.png'
import {GetQuery, locationHref} from "../../../utils/index";

import {Toast} from 'antd-mobile'

class questionDetail extends Component {
  state = {
    websiteDetailInfo: [],
    flag: true,
    websiteDatas: [],
    priceVisible: false,
    ReplayVisible: false,
    reversionList: [],
    reversionData: [],
    questionId: null,
    like: null,
    likeid: null
  }
  componentWillMount() {
    let ids = this.props.match.params.id
    this.setState({
      questionId: ids
    })
    this.getwebsiteDetailInfo(ids)
    this.websiteData()
    this.addCount(ids)
    this.getReversionList(ids)
      //干货分类seo优化内容
      document.title=this.state.websiteDatas.seotitle
      document.createElement('title').setAttribute('content',this.state.websiteDatas.seotitle)
      if(this.state.websiteDatas.keyword){
        document.createElement('meta').setAttribute('content',this.state.websiteDatas.keyword);
      }else{}
      if(this.state.websiteDatas.describes){
        document.createElement('meta').setAttribute('content',this.state.websiteDatas.describes);
      }else{}
  }

  addCount(ids) {
    this.get(`${this.api.addCount}?id=${ids}`, '').then((res) => {
      if (res.code === 0) {
      }
    })
  }

  million(value) {
    //过万处理
    let num
    if (value > 9999) {
      //大于9999显示x.xx万
      num = Math.floor(value / 1000) / 10 + 'W'
    } else if (value < 9999 && value > -9999) {
      num = value
    } else if (value < -9999) {
      //小于-9999显示-x.xx万
      num = -(Math.floor(Math.abs(value) / 1000) / 10) + 'W'
    }
    return num
  }

  priceSaveInfo(obj) {
    let source = '获取报价'
    let sourceUrl = escape(document.location.href);//'/decorateGan/decorateDetail'
    this.post(
      `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          priceVisible: false
        })
        Toast.success('已提交信息', 1)
      }
    })
  }

  ReplaySaveInfo(obj) {
    let {questionId} = this.state
    this.post(
      `${this.api.addReply}?questionId=${questionId}&reply=${obj.question}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState({
          ReplayVisible: false
        })
        Toast.success('已提交信息', 1)
      }
    })
  }

  websiteData() {
    this.get(
      `${this.api.websiteData}?typeId=${9}&page=${1}&limit=${3}&${GetQuery()}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        document.title =res.data[0].seotitle
        this.setState(
          {
            websiteDatas: res.data
          },
          () => {
          }
        )
      }
    })
  }

  getReversionList(id) {
    let {reversionData} = this.state

    this.get(
      `${this.api.reversionList}?pid=${id}&page=${1}&limit=${10}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState(
          {
            reversionList: res.data,
            reversionData: res.data
          },
          () => {
          }
        )
        reversionData.map((item) => {
          item.like = false
          item.dislike = false
          item.dianzanED = false
        })
      }
    })
  }

  getNewlikeNumber(id) {
    this.get(
      `${this.api.reversionList}?pid=${id}&page=${1}&limit=${10}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        this.setState(
          {
            reversionData: res.data
          },
          () => {}
        )
      }
    })
  }

  reversionCount(id, typeId) {
    let {reversionData} = this.state
    let typeIdss = null

    reversionData.map((item) => {
      if (item.id == id) {
        item.dianzanED = true
        if (typeId == 1) {
          item.like = !item.like
          if (item.like) {
            item.dislike = false
            typeIdss = 1
            item.supportCount++
          } else {
            typeIdss = 2
            item.supportCount--
          }
        } else {
          item.dislike = !item.dislike
          if (item.dislike) {
            item.like = false
            typeIdss = 3
            item.oppositionCount++
          } else {
            typeIdss = 4
            item.oppositionCount--
          }
        }
      }
    })

    this.setState({
      like: id,
      likeid: typeId
    })
    //typeId 1 增加点赞 2 减少点赞 3 增加不喜欢 4 减少不喜欢
    this.get(
      `${this.api.reversionCount}?reId=${id}&typeId=${typeIdss}`,
      ''
    ).then((res) => {
      if (res.code === 0) {
        //   this.setState(
        //     {
        //       reversionList: res.data
        //     },
        //     () => {
        //     }
        //   )
      }
    })
  }

  getwebsiteDetailInfo = (id) => {
    this.get(`${this.api.findByQuestionId}?id=${id}`, '').then((res) => {
      this.setState(
        {
          websiteDetailInfo: res.data
        },
        () => {
        }
      )
    })
  }
  iconclick = (type) => {
    this.setState({
      flag: type
    })
  }

  goQuestionDetail(id) {
    this.getwebsiteDetailInfo(id)
    this.getReversionList(id)
  }

  goDecorateDaqo() {
    locationHref('/decorateGan/DecorateDaqo/' + 5)
  }

  RenderMoreQuestion() {
    return (
      <div className='moreQuestion_box'>
        <div className='moreQuestion_header'>
          <span className='moreQuestion_title'>更多问答</span>
          <div
            className='seeMore_question'
            onClick={this.goDecorateDaqo.bind(this)}
          >
            <span className='seeMore_text'>查看更多</span>
            <img className='rightICon' src={iconMore} alt='' />
          </div>
        </div>
        {this.RenderQuestion()}
      </div>
    )
  }
  RenderQuestionList() {
    let {reversionData, like, likeid} = this.state
    return (
      <div className='Question_list_box'>
        {reversionData &&
          reversionData.map((item) => {
            return (
              <div className='Question_list' key={item.id}>
                <div className='Question_list_header'>
                  <img className='question_avator' src={bannerMiddle} />
                  <span className='question_name'>{item.createUser}</span>
                  <span className='question_time'>{item.createTime}</span>
                </div>
                <p className='Question_Desc'>{item.infos}</p>
                <div className='giveThumbsUp'>
                  <div
                    className='giveThumbsUp_left'
                    onClick={this.reversionCount.bind(this, item.id, 1)}
                  >
                    <span
                      style={{color: item.like ? '#108ee9' : ''}}
                      className={
                        'iconfont icon-dianzan question_dianzan '
                      }
                    />

                    <span
                      className={
                       'giveThumbsUp_text'
                      }
                      style={{color: item.like ? '#108ee9' : ''}}
                    >
                      点赞 {this.million(item.supportCount)}
                    </span>
                  </div>
                  <div
                    className='giveThumbsUp_right'
                    onClick={this.reversionCount.bind(this, item.id, 3)}
                  >
                    <span
                      className={
                        item.dianzanED
                          ? 'iconfont icon-chaping question_dianzan question_dianzan_no'
                          : 'iconfont icon-chaping question_dianzan '
                      }
                      style={{color: item.dislike ? '#000' : '#bbb'}}
                    />

                    <span
                      className={
                        item.dianzanED
                          ? 'giveThumbsUp_text  giveThumbsUp_text_no'
                          : 'giveThumbsUp_text'
                      }
                      style={{color: item.dislike ? '#000' : '#bbb'}}
                    >
                      不喜欢 {this.million(item.oppositionCount)}
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    )
  }

  RenderQuestion() {
    let {websiteDatas} = this.state
    return websiteDatas.map((item) => {
      return (
        <div className='question_detail_list_Wrap' key={item.id}>
          <p
            className='questionList_title'
            onClick={this.goQuestionDetail.bind(this, item.id)}
          >
            {item.title}
          </p>
          <div
            className='questionList_desc_wrap'
            onClick={this.goQuestionDetail.bind(this, item.id)}
          >
            <span className='questionList_desc'>{item.intro}</span>
          </div>
          <div className='questionList_Bottom'>
            <span
              className='question_time'
              onClick={this.goQuestionDetail.bind(this, item.id)}
            >
              {item.time}
            </span>
            <span
              className='question_number'
              onClick={this.goQuestionDetail.bind(this, item.id)}
            >
              已有
              <span
                style={{color: '#0166B3'}}
                onClick={this.goQuestionDetail.bind(this, item.id)}
              >
                {this.million(item.questionCounts)}
              </span>
              个回答
            </span>
            <div
              className='question_view'
              onClick={this.goQuestionDetail.bind(this, item.id)}
            >
              <img className='view_number' src={seenEye} alt='' />
              {this.million(item.counts)}
            </div>
            <div
              className='want_answer_btn'
              onClick={() => {
                this.setState({ReplayVisible: true, questionId: item.id})
              }}
            >
              我要解答
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    let {
      websiteDetailInfo,
      priceVisible,
      ReplayVisible,
      questionId
    } = this.state

    return (
      <>
        <MyNavBar />
        <div className='decorateDetail' style={{marginnTop:'-1.2rem'}}>
          <div className='decorateDetailBanner'>
            <div
              className='questionTop'
              onClick={() => {
                this.setState({priceVisible: true})
              }}
            >
              <img className='bannerTop_img' src={bannerMiddle} alt='' />
              <span className='preciseSolution'>已为1302234人提供精准方案</span>
            </div>
          </div>
          <div className='questionDetailContent'>
            <div className='Detailtitle'>{websiteDetailInfo.title}</div>
            <div dangerouslySetInnerHTML={
               { __html: websiteDetailInfo.infos
            }} ></div>
            <div className='question_DetailTimes'>
              <div className='question_DetailTime'>
                发布时间：{websiteDetailInfo.time}
              </div>
              <div className='question_DetailLiulan'>
                <img className='liulian' src={liulian} alt='' />
                {websiteDetailInfo.counts}
              </div>
              <div
                className='question_IWantAnswer'
                onClick={() => {
                  this.setState({ReplayVisible: true, questionId: questionId})
                }}
              >
                我要回答
              </div>
            </div>
            {this.RenderQuestionList()}
          </div>
          {this.RenderMoreQuestion()}
          <DesignerPopup
            visible={priceVisible}
            title='装修要花多少钱?'
            subTitle='1688'
            cancelClick={() => this.setState({priceVisible: false})}
            saveInfo={this.priceSaveInfo.bind(this)}
            btnTxt={['获取报价']}
            type='price'
            price={98234}
          />
          <DesignerPopup
            visible={ReplayVisible}
            title='您的回答'
            cancelClick={() => this.setState({ReplayVisible: false})}
            saveInfo={this.ReplaySaveInfo.bind(this)}
            type='question'
            btnTxt={['提交']}
          />
        </div>
      </>
    )
  }
}
export default withRouter(questionDetail)
