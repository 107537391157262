import React, { Component } from 'react'
import MyNavBar from '../../../components/myNavBar/index'
import {withRouter} from 'react-router-dom'
import {xin} from '../../../image/commentsDetail/index'
import moment from "moment";
import './index.css'
 class ownerDetail extends Component {
    state={
        OwnerListInfo:[],
        imgList:[]
    }
    componentWillMount() {
        this.getOwnerDetailInfo(this.props.location.id)
    }
    getOwnerDetailInfo=(id) =>{
        this.get(`${this.api.getownersVoiceInfo}?id=${id}`, '').then((res) => {
            this.setState({
            OwnerListInfo: res.data,
            imgList:res.data.imgUrl.split(",")
          },()=>{
            })
        })
    }

    renderownerDetail=()=>{
        let i=this.state.OwnerListInfo.logTime
        let statusPassTime = moment((i)).format('YYYY年MM月DD日'); 
        let {OwnerListInfo,imgList}=this.state;
	return(
        <div className="containers">
            <div className="containers_contents">
                <div className="containers_top">
                    <div className="top_ln">
                    <img  className="touxiang" src={OwnerListInfo.icon} alt="" />
                </div>
                <div className="neighbor_case_content_rightt">
                    <div className="neighbor_case_content_rs">
                        <div className="name_">{OwnerListInfo.username}</div>
                        <div className="b_ll">{statusPassTime}</div>
                        <span className="pingfenb">
                            <span className="pingfen_fen">评分：</span>
                            <span className="pingfen_xin">
                                    {
                                        Array.from({ length: OwnerListInfo.score }, (_, i) => i).map((a, index) => {
                                            return (
                                                <img src={xin} alt='' />
                                            )
                                        })
                                    }
                                </span>
                            </span>
                    </div>
                    <div className="neighbor_case_content_right_b">
                    </div>
                        <span className="content_sg">
                           <span>设计:</span><span>{OwnerListInfo.designScore}.0</span>
                           <span>服务:</span><span>{OwnerListInfo.serviceScore}.0</span>
                           <span>施工:</span><span>{OwnerListInfo.buildScore}.0</span>
                        </span>
                </div>
                </div>
                <div className="contents">
                    <div className="content_count">
                         {OwnerListInfo.content}
                    </div>
                    <div className='neighbor_case_content_right_e'>
                    <span className='tupian_s'>
                      {
                        imgList.map((a, index) => {
                          return (
                            <div >
                              <img
                                className='tupians'
                                src={a}
                                key={index}
                                alt=''
                              />
                            </div>
                          )
                        })
                      }
                    </span>
                  </div>
                </div>
            </div> 
        </div>
        )
    }
      
    render() {
        return (
            <div>
                <MyNavBar />
                {this.renderownerDetail()}
            </div>
        )
    }
}
export default withRouter(ownerDetail)