import React, { Component } from 'react'
import MyNavBar from '../../../components/myNavBar/index'
import phone from '../../../image/phone.png' 
import news from '../../../image/banner_middle.png'
import '../../../css/to_news.scss' 
import {GetQuery, GetTel, GetAddr, locationHref} from "../../../utils/index";

class To_news extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    LongTailWords: [],  
  }

  componentWillMount() {
    let id=this.props.match.params.id
    this.getWebsiteLongTailWord(id)
  }

  componentDidMount() { 
 
  }
  getWebsiteLongTailWord=(id)=>{
      this.get(
        `${this.api.websiteLongTailWord}?page=${1}&limit=${10}&name=${id}&${GetQuery()}`,
        ''
    ).then((res) => {
        if (res.code === 0) {
            this.setState({
              LongTailWords: res.data
            },()=>{
            })
        }
    })
  }
  choose(id) {
    locationHref('/detailsNews/'+id)
  }
 

  componentWillReceiveProps() {}

  componentWillUnmount() {}

  componentDidShow() {}

  componentDidHide() {}

  newsList(data) { 
    if(data !== undefined){
      return data.map((item,key) => {
        return (
          <div className='newsList'         
          // onClick={this.choose.bind(this,item.id)}  key={item.id}
          >
            <div className='newsList_topp'>
            {item.longword}
          </div>
          </div>
        )
      })
    }
   
  }
  //底部栏
  Footer() {
    return (
      <div className='Footer'>
        <div className='consulting'>
          <span>装修咨询</span>
          <a href={`tel:${GetTel()}`} className='phoneNum'>{GetTel()}</a>
          <img className='phone_icon' src={phone} alt='' />
        </div>
        <div className='address'>
          总部地址：{GetAddr()}
        </div>
      </div>
    )
  }
  render() { 
    let changeArea = (
      <div className='To_store_experience'>
        <img
          className='headerImgg'
          style={{width:'100%',height:'100%'}}
          src={news}
          alt='' 
        />
        <div className="newListWrap">
        {this.newsList(this.state.LongTailWords)}
        </div>
        {this.Footer()}
      </div>
    )
    return (
      <div className='container'>
        <MyNavBar />
        {changeArea}
      </div>
    )
  }
}
export default To_news
