import React, {Component} from 'react'
import MyNavBar from '../../../components/myNavBar/index'
import DesignerPopup from '../../../components/designerPopup/index'
import './index.scss'
import seenEye from '../../../image/seenEye.png'
import like from '../../../image/like.png'
import dislike from '../../../image/dislike.png'
import {Toast} from 'antd-mobile'
import {GetQuery, locationHref} from "../../../utils/index";

export default class neighborCase extends Component {
    state = {
        designerCaseInfo: {},
        dislikeVisible: false,
        likeVisible: false,
        nowVisible: false,
        downloadVisible: false,
        id: '',
        caseListData: []
    }

    componentWillMount() {
        let arr = this.props.location.pathname.split("/")

        this.setState({
            id: arr[arr.length-1]
        })
        // 案例详情seo优化内容
        document.createElement('title').setAttribute('content', this.state.designerCaseInfo.seoTitle)
        if (this.state.designerCaseInfo.keyword) {
            document.createElement('meta')
                .setAttribute('content', this.state.designerCaseInfo.keyword);
        } else {
        }
        if (this.state.designerCaseInfo.describes) {
            document.createElement('meta')
                .setAttribute('content', this.state.designerCaseInfo.describes);
        } else {
        }
    }

    componentDidMount() {
        this.getCaseInfo(this.props.location.id)
        this.getDesignerCaseList()
    }
    componentWillReceiveProps(newProps) {
        const ids = this.props.location.id;
        this.getCaseInfo(ids)
        window.onbeforeunload = function () {
          document.documentElement.scrollTop = 0;  //ie下
          document.body.scrollTop = 0;  //非ie
        }
    
      }
    
    // goDesignerDetail() {
    //     this.props.history.push({
    //         pathname: '/designerInfo/'+id
    //     })
    // }

    goDetail(id) {
        this.getCaseInfo(id)
        //回到顶部
        document.getElementsByClassName('app_main')[0].scrollTop = 0;
    }

    dislikeSaveInfo(obj) {
        let source = '不喜欢'
        let sourceUrl = escape(document.location.href);//'page/neighborCase'
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    dislikeVisible: false
                })
                Toast.success('已提交信息', 1)
            }
        })
    }

    getDesignerCaseList() {
        this.get(`${this.api.getDesignerCaseList}?page=${1}&limit=${10}&${GetQuery()}&isRecommend=0`, '').then(
            (res) => {
                if (res.data.code === 0) {
                    this.setState({
                        caseListData: res.data.data
                    })
                }
            }
        )
    }

    likeSaveInfo(obj) {
        let source = '喜欢'
        let sourceUrl = escape(document.location.href);//'page/neighborCase'
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    likeVisible: false
                })
                Toast.success('已提交信息', 1)
            }
        })
    }

    nowSaveInfo(obj) {
        let source = '预约TA'
        let sourceUrl = escape(document.location.href);//'page/neighborCase'
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    nowVisible: false
                })
                Toast.success('已提交信息', 1)
            }
        })
    }
    //跳转长尾词列表
    goLongTailWord(id) {
        locationHref('/longTailWords/'+id)
      }
    downloadSaveInfo(obj) {
        let source = '一键获取全套案例'
        let sourceUrl = escape(document.location.href);//'page/neighborCase'
        this.post(
            `${this.api.getCustomerCollect}?username=${obj.name}&phone=${obj.tel}&housingArea=${obj.area}&source=${source}&sourceUrl=${sourceUrl}&${GetQuery()}`,
            ''
        ).then((res) => {
            if (res.code === 0) {
                this.setState({
                    downloadVisible: false
                })
                Toast.success('已提交信息', 1)
            }
        })
    }

    getCaseInfo(id) {
        this.setState({
            id: id
        })
        this.get(`${this.api.getDesignerCaseInfo}?id=${this.state.id}`, '').then((res) => {
            this.setState({
                designerCaseInfo: res.data
            })
        })
    }

    RenderNeighborCaseInfo() {
        let {designerCaseInfo} = this.state
        return (
            <div className='neighborCaseInfo'>
                <p className='communityName'>{designerCaseInfo.title}</p>
                <ul className='communityList'>
                    
        <li className='communityItem'>
            <span className='communityList_title'>装修类别</span>
            <span className='communityList_desc'>新房装修</span>
          </li>
                    <li className='communityItem'>
                        <span className='communityList_title'>装修风格</span>
                        <span className='communityList_desc'>
              {designerCaseInfo.styleName}
            </span>
                    </li>
                    <li className='communityItem'>
                        <span className='communityList_title'>面积</span>
                        <span className='communityList_desc'>
              {designerCaseInfo.acreage}㎡
            </span>
                    </li>
                    <li className='communityItem'>
                        <span className='communityList_title'>户型</span>
                        <span className='communityList_desc'>
              {designerCaseInfo.houseType}
            </span>
                    </li>
                    <li className='communityItem'>
                        <span className='communityList_title'>设计师</span>
                        <span className='communityList_desc'>
              {designerCaseInfo.username}
            </span>
                    </li>
                </ul>
                <div className='communityBtns'>
                    {/* <div
            className='communityBtn'
            onClick={this.goDesignerDetail.bind(this)}
          >
            查看
          </div> */}
                    <div
                        className='communityBtn'
                        style={{background: '#0672D5', marginLeft: '10px'}}
                        onClick={() => {
                            this.setState({nowVisible: true})
                        }}
                    >
                        预约TA
                    </div>
                </div>
            </div>
        )
    }

    RenderGuessLike() {
        let {caseListData} = this.state
        if (caseListData !== undefined) {
            return (
                <div className='guessLike'>
                    <p className='section_title'>猜你喜欢</p>
                    <div className='guessLikeWrap'>
                        {caseListData.map((item) => {
                            return (
                                <div
                                    className='guessLikeList'
                                    key={item.id}
                                    onClick={this.goDetail.bind(this, item.id)}
                                >
                                    <img src={item.cover} className='guessLikeImg' alt=''/>
                                    <div className='guessLikeList_seen'>
                                        <img src={seenEye} className='seenEye_img' alt=''/>
                                        <span className='seenPeople'>
                      {item.browseVolumes ? item.browseVolumes : 16}人看过
                    </span>
                                    </div>
                                    <div className='guessLike_info'>
                    <span className='guessLike_label label'>
                      {item.styleName}
                    </span>
                                        <span className='guessLike_area'>{item.acreage}㎡ </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        }
    }

    RenderBottomBtns() {
        return (
            <div className='BottomBtns'>
                <div
                    className='hate'
                    onClick={() => this.setState({dislikeVisible: true})}
                >
                    <img className='hate_img' src={dislike} alt=''/>
                    <span>不喜欢</span>
                </div>
                <div
                    className='like'
                    onClick={() => this.setState({likeVisible: true})}
                >
                    <img className='like_img' src={like} alt=''/>
                    <span>喜欢</span>
                </div>
                <div
                    className='getAllCases'
                    onClick={() => this.setState({downloadVisible: true})}
                >
                    一键获取全套案例
                </div>
            </div>
        )
    }

    render() {
        let data = [
            {
                id: 1,
                name: '会员招募',
                desc: 'XXXXXXXXX'
            },
            {
                id: 2,
                name: '299套餐',
                desc: '现代简约主材包'
            },
            {
                id: 3,
                name: '699套餐',
                desc: '出现这些特征 卫生间需要“大革命”了'
            }
        ]

        let {
            designerCaseInfo,
            dislikeVisible,
            likeVisible,
            nowVisible,
            downloadVisible
        } = this.state
        let labesList=this.state.designerCaseInfo.labelsList;

        let changeArea = (
            <div className='neighborCase'>
                <div className='neighborCase_banner'>
                    <img
                        className='neighborCase_bannerImg'
                        src={designerCaseInfo.cover}
                        alt=''
                    />
                </div>
                {this.RenderNeighborCaseInfo()}
                {/*<div className='analysisHouseType'>
          <p className='sectionTitle'>户型解析</p>
          <img className='analysisHouse_img' src={beijing2} alt='' />
        </div>*/}

                <div className='caseIntroduction'>
                    <p className='sectionTitle'>案例介绍</p>
                    <div
                        className='richTextWrap'
                        dangerouslySetInnerHTML={{
                            __html: designerCaseInfo.content
                        }}
                    ></div>
                </div>
                <div className="DetailContentSeoTitle">
                {
                labesList&&labesList.map((item,index)=>{
                    return(
                    <div id="seoTitle" key={index} onClick={this.goLongTailWord.bind(this,item.id)}>
                        {item.words}
                    </div>
                    )
                })
                }
                </div>
                {this.RenderGuessLike()}
                {this.RenderBottomBtns()}
                <DesignerPopup
                    visible={dislikeVisible}
                    title='感谢您的建议~'
                    subTitle='您将获得居然之家提供的精美礼品一份'
                    bottomdata='三'
                    cancelClick={() => this.setState({dislikeVisible: false})}
                    saveInfo={this.dislikeSaveInfo.bind(this)}
                    type='like'
                    btnTxt={['到店领取']}
                />
                <DesignerPopup
                    visible={likeVisible}
                    title='感谢您的鼓励！'
                    subTitle='您将获得居然之家提供的精美礼品一份'
                    bottomdata='三'
                    cancelClick={() => this.setState({likeVisible: false})}
                    saveInfo={this.likeSaveInfo.bind(this)}
                    type='like'
                    btnTxt={['到店领取']}
                />
                <DesignerPopup
                    visible={nowVisible}
                    title='预约TA'
                    type={'ta'}
                    subTitle='在线预约 尊享优先排期特权'
                    bottomdata={['上门量房', '户型解析', '动线设计', '软装设计']}
                    cancelClick={() => this.setState({nowVisible: false})}
                    saveInfo={this.nowSaveInfo.bind(this)}
                    btnTxt={['立即预约']}
                />
                <DesignerPopup
                    visible={downloadVisible}
                    title='请留下您的百度网盘账号'
                    subTitle='全套案例图将发送给您'
                    bottomdata={['']}
                    cancelClick={() => this.setState({downloadVisible: false})}
                    saveInfo={this.downloadSaveInfo.bind(this)}
                    btnTxt={['提交']}
                    type='download'
                />
            </div>
        )
        return (
            <div className='container'>
                <MyNavBar/>
                {changeArea}
            </div>
        )
    }
}
